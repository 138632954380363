import { transactionConstants} from '../constants';

 const  transaction = (state = {transactions: []}, action)=>{
  switch (action.type) {
    case transactionConstants.transaction.EDIT_TRANSACTION:
      return { 
              ...state, 
              loading: true,
              selectedTransaction: action.transaction
            };
    case transactionConstants.transaction.CANCEL_EDIT_TRANSACTION:
            const {selectedTransaction, ...rem} = state;
            return rem;

    case transactionConstants.transaction.CREATE_TRANSACTION_REQUEST:
      return { ...state, adding: true}

    case transactionConstants.transaction.CREATE_TRANSACTION_REQUEST_SUCCESS:
      return { 
              ...state, success:true,
              adding: false, selectedTransaction: action.transaction,
              transactions: [...state.transactions, action.transaction]
      }

    case transactionConstants.transaction.CREATE_TRANSACTION_REQUEST_FAILURE:
      return {...state, adding: false, success:false, error: action.error}
    
    case transactionConstants.transaction.GET_TRANSACTION:
      return { ...state, loading: true}

    case transactionConstants.transaction.GET_TRANSACTION_SUCCESS:
      return { 
              ...state, 
              success:true,
              loading: false,
              transactions: [...action.transaction]
      }
    case transactionConstants.transaction.GET_TRANSACTION_FAILURE:
      return {...state, loading: false, success:false, error: action.error}
      
    case transactionConstants.transaction.UPDATE_TRANSACTION:
      return { ...state, updating: true}

    case transactionConstants.transaction.UPDATE_TRANSACTION_SUCCESS:
      return { 
              ...state, 
              success:true,
              updating: false,
              transactions: [...state.transactions.filter(transaction => transaction.id != action.transaction.id), action.transaction]
      }
    case transactionConstants.transaction.UPDATE_TRANSACTION_FAILURE:
      return {...state, updating: false, success:false, error: action.error}
    

    
    case transactionConstants.transaction.SUBMIT_RESPONSE:
      return { ...state, updating: true}

    case transactionConstants.transaction.SUBMIT_RESPONSE_SUCCESS:
      return { 
              ...state, 
              success:true,
              updating: false,
              transactions: [...state.transactions.filter(transaction => transaction.id != action.response.id), action.response]
      }
    case transactionConstants.transaction.SUBMIT_RESPONSE_FAILURE:
      return { ...state, canceling: false, success: false, updating:false, error: action.error}
    
    case transactionConstants.transaction.CANCEL_TRANSACTION:
      return { ...state, canceling: true, canceling2:true}

    case transactionConstants.transaction.CANCEL_TRANSACTION_SUCCESS:
      return { 
              ...state, 
              success:true,
              canceling: false,
        canceling2: false,
              transactions: [...state.transactions.filter(transaction => transaction.id != action.transaction.id), action.transaction]
      }
      
    case transactionConstants.transaction.CANCEL_TRANSACTION_FAILURE:
      return { ...state, canceling: false, canceling2:false, success:false, error: action.error}

    case transactionConstants.transaction.MAKE_PAYMENT:
      return { ...state, paying: true}

    case transactionConstants.transaction.MAKE_PAYMENT_SUCCESS:
      return { 
              ...state, 
              success:true,
              paying: false,
              selectedTransaction: {...state.selectedTransaction, authorization: action.authorization}
      }
    case transactionConstants.transaction.MAKE_PAYMENT_FAILURE:
      return {...state, paying: false, success:false, error: action.error}
      
    default:
      return state
  }
}

export default transaction;