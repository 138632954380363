import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";


const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class ContactDetailsReport extends Component {
  state = {
    loading: false,

    data: [],
    dataOriginal: [],

    notCustom: true,
  }

  componentDidMount() {

    this.loadReport();
  }

  loadReport = () => {
    this.setState({loading: true})
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/reports/contact-details`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data: {data}}) => {
         console.log(data);
         this.setState({
           dataOriginal: data,
           data,
         })
       })
       .catch(err => {
         console.log(err.response);
       })
       .finally(() => this.setState({loading: false}))

  };


  // handleSelect = (e) => {
  //   const {value} = e.target;
  //   if (value === "Custom") {
  //     this.setState({notCustom: false})
  //   }
  //   this.filterPeriod(value);
  //   // const period = {period: value}
  //   this.setState({period: value});
  //   /* console.log(value); */
  //   /* console.log(this.state.period); */
  //   /* console.log(this.state.data); */
  // }
  //
  // filterPeriod = (value) => {
  //   const data = this.state.dataOriginal;
  //   /* console.log(data); */
  //
  //   if (value === "All") {
  //     /* console.log(data); */
  //     this.table_rows = data;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.state.dataOriginal]});
  //   }
  //
  //   if (value === "This Week") {
  //     var ordersThisWeek = [];
  //
  //     for (var i = 0; i < data.length; i++) {
  //       var now = moment();
  //       var input = moment(data[i].created_at);
  //       var isThisWeek = (now.isoWeek() === input.isoWeek())
  //
  //       if (isThisWeek) {
  //         ordersThisWeek.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersThisWeek;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     /* console.log(this.table_rows); */
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "This Month") {
  //     var ordersThisMonth = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //       //var now = moment();
  //       let input = moment(data[i].created_at);
  //       var isThisMonth = input.isSame(new Date(), 'month');
  //
  //       if (isThisMonth) {
  //         ordersThisMonth.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersThisMonth;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "60 Days Ago") {
  //     var ordersSixtyDaysAgo = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //
  //       var sixty_days_ago = moment().subtract(60, 'days');
  //
  //       var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);
  //
  //       if (isBetweenSixtyDays) {
  //         ordersSixtyDaysAgo.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersSixtyDaysAgo;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "90 Days Ago") {
  //     var ordersNinetyDaysAgo = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //
  //       var ninety_days_ago = moment().subtract(90, 'days');
  //
  //       var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);
  //
  //       if (isBetweenNinetyDays) {
  //         ordersNinetyDaysAgo.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersNinetyDaysAgo;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "Custom") {
  //     this.table_rows = data;
  //     this.to_date = null;
  //     this.from_date = null;
  //
  //   }
  // }
  //
  // filterDates = (data) => {
  //   var ordersBetweenTheTwoDates = [];
  //
  //   for (var i = 0; i < data.length; i++) {
  //
  //     var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
  //     /* console.log(isBetweenDates); */
  //     if (isBetweenDates) {
  //       ordersBetweenTheTwoDates.push(data[i]);
  //     }
  //   }
  //
  //   this.table_rows = ordersBetweenTheTwoDates;
  //   //this.notCustom = true;
  //   this.page = 1;
  //   //this.to_date = null;
  //   //this.from_date = null;
  //   /* console.log(this.table_rows); */
  //   return this.table_rows;
  // }
  // // componentDidMount= ()=>{
  // //     'i got called';
  // //     this.props.view("seller",this.props.history);
  // // }
  // handleDate = (e) => {
  //   const {name, value} = e.target;
  //   this.setState({[name]: value});
  //
  // }


  handleFilter = e => {
    const {target: {value}} = e;
    const {dataOriginal: data} = this.state;

    const includes = property => property ? property.toLowerCase().includes(value.toLowerCase()) : false

    if(value === "" || value === null || value === undefined){
      this.setState({data: this.state.dataOriginal});
      return;
    }


    const filtered = [];

    for(var i = 0; i < data.length; i++){
      const {
        role,
        business_name,
        business_address,
        business_phone,
        business_email,
        contact_name,
        contact_phone,
        contact_email,
        entity,
        otp,
        status,
      } = data[i];
      if(
            includes(role) ||
            includes(business_name) ||
            includes(business_address) ||
            includes(business_phone) ||
            includes(business_email) ||
            includes(contact_name) ||
            includes(contact_phone) ||
            includes(contact_email) ||
            includes(entity) ||
            includes(otp) ||
            includes(status)
      ){
        filtered.push(data[i]);
      }
    }

    this.setState({data: filtered})
  }

  render() {

    const columns = [
      {
        Header: props => <span><b>Role</b></span>,
        id: "role",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.role,
        Cell: ({original: props}) => {
          return <span>{props.role}</span>;
        }
      },
      {
        Header: props => <span><b>Business Name</b></span>,
        id: "business_name",
        filterable: false,
        accessor: d => d.business_name,
        Cell: ({original: props}) => {
          return <span>{props.business_name || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Business Address</b></span>,
        id: "business_address",
        filterable: false,
        accessor: d => d.business_address,
        Cell: ({original: props}) => {
          return <span>{props.business_address || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Business Phone</b></span>,
        id: "business_phone",
        filterable: false,
        accessor: d => d.business_phone,
        Cell: ({original: props}) => {
          return <span>{props.business_phone || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Business Email</b></span>,
        id: "business_email",
        filterable: false,
        accessor: d => d.business_email,
        Cell: ({original: props}) => {
          return <span>{props.business_email || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Contact Name</b></span>,
        id: "contact_name",
        filterable: false,
        accessor: d => d.contact_name,
        Cell: ({original: props}) => {
          return <span>{props.contact_name || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Contact Phone</b></span>,
        id: "contact_phone",
        filterable: false,
        accessor: d => d.contact_phone,
        Cell: ({original: props}) => {
          return <span>{props.contact_phone || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Contact Email</b></span>,
        id: "contact_email",
        filterable: false,
        accessor: d => d.contact_email,
        Cell: ({original: props}) => {
          return <span>{props.contact_email || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Entity</b></span>,
        id: "entity",
        filterable: false,
        accessor: d => d.entity,
        Cell: ({original: props}) => {
          return <span>{props.entity || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>OTP</b></span>,
        id: "otp",
        filterable: false,
        accessor: d => d.otp,
        Cell: ({original: props}) => {
          return <span>{props.otp || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Status</b></span>,
        id: "status",
        filterable: false,
        accessor: d => d.status,
        Cell: ({original: props}) => {
          return <span>{props.status || "N/A"}</span>;
        }
      },
    ];
    return (
       <div className={'content-wrapper'}>

         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">

               <div className="col-sm-12">
                 <h1 className="m-0 text-dark">Contact Details</h1>
               </div>
             </div>
           </div>
         </div>
         <section className="content">
           <div className="container-fluid">
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-md-4">
                         <div className="form-group"><label htmlFor="filters">Filter</label>
                           <input type="text"
                           name={'filters'}
                                  className={'form-control'}
                                  onChange={this.handleFilter}
                           />
                         </div>
                       </div>
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date From</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="from_date"*/}
                       {/*       value={this.state.from_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date To</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="to_date"*/}
                       {/*       value={this.state.to_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label>&nbsp;</label>
                           <div className="row">
                             <div className="col-12">
                               <button disabled={this.state.notCustom} onClick={() => {
                                 this.setState({data: [...this.filterDates(this.state.dataOriginal)]})
                               }} className="btn btn-info btn-block form-control">
                                 <center>Search</center>
                               </button>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <br/>
                     <div className="table-responsive">
                       <ReactTable
                          loading={this.state.loading}
                          defaultPageSize={5}
                          filterable
                          data={this.state.data}
                          columns={columns}/>

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default ContactDetailsReport;