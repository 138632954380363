import React, {Component} from 'react';
import ReactModal from 'react-responsive-modal';
import transactionHelpers from '../helpers/transaction';
import {toLocaleDisplay} from '../helpers/transaction';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { user } from '../actions/user';

const escrowFees=(props)=>{
    let payableEscrow=transactionHelpers.calculatePayableEscrow(props.data)/props.data.conversion_ratio;
    return payableEscrow;
}

const cardFees=(props)=>{
    let payableCardFees=props.data.currency==='USD'?transactionHelpers.calculateUsdCardFee(props.data, props.role):transactionHelpers.calculateCardFee(props.data, props.role);
    return payableCardFees;    
}
const netAmount=(props)=>{
    let productCost=props.data.amount_payed * props.data.quantity;
    let shippingCost=transactionHelpers.calculateShipping(props.data)[props.role]
    let netCost=(productCost+shippingCost)/props.data.conversion_ratio;
    return netCost;
}

const refundableAmount=(props)=>{

    let refundAmount=transactionHelpers.calculateTransactionRefundable(props.data);
    //console.log('function: refundableAmount, parameter:refundAmount, value:',refundAmount);
    let txFees=props.data.tx_fee_bearer === "seller" ? cardFees(props) : (50/props.data.conversion_ratio);
    txFees=txFees*props.data.conversion_ratio;
    //console.log('function: refundableAmount, parameter:txFees, value:',txFees);
    let netRefundAmount=(refundAmount-txFees)/props.data.conversion_ratio;
    //console.log('function: refundableAmount, parameter:netRefundAmount, value:',netRefundAmount);
    return netRefundAmount;
}
const logisticFees=(props)=>{
    let totalCost=netAmount(props)*props.data.conversion_ratio;
    let logisticCost=(props.terms.logistics_fee *totalCost)/100;
    if(logisticCost>5000){
        logisticCost=5000;
    }
    return (logisticCost/props.data.conversion_ratio);
}

const grandTotal=(props)=>{
    let payableAmount=transactionHelpers.calculateTransactionPayable(props.data);
    let txFees= props.data && props.data.tx_fee_bearer && props.data.tx_fee_bearer !== 'seller' ?cardFees(props): 0;
    txFees=txFees*props.data.conversion_ratio;
    let grandTotalAmount=(payableAmount+txFees)/props.data.conversion_ratio;
    return grandTotalAmount;
}
const grandTotalNGN=(props)=>{
    let grandTotalUsd=grandTotal(props);
    return(grandTotalUsd*props.data.conversion_ratio);
}

const ModalComponent = (props) => (

    <ReactModal
            open={props.open}
            onClose={props.onClose}
        >
        <div class="">
            <div class="row">
                <div class="col-10">
                    <img src="/dist/img/logo.3fc64c3.png" alt="AtaraPay Logo" style={{opacity: 1 ,width: "200px", paddingLeft: 15}} />
                </div>
            </div>
            <br/>
            <div className="container">
                <p>Your AtaraPay email is <strong>{props.user && props.user.email}</strong></p>
                <p>Your AtaraPay phone number is <strong>{props.user && props.user.phone_number}</strong></p>
                <p>Alternative Recipient Phone Number </p>
                <PhoneInput
                            country="NG"
                            placeholder="080X XXX XXXX"
                            value={props.data && formatPhoneNumber(props.seller_phone, 'National') || formatPhoneNumber(props.seller_phone, 'International')}
                            inputClassName={'text-alt'}
                            onChange={value => props.handlePhone(value)}
                        />
                <p>
                    <small>[Please enter this number if you will not be able to receive the item(s) <br />in person. Ensure to update the international code accordingly]</small>
                </p>
                <p>
                    You will be charged escrow fee of {toLocaleDisplay(escrowFees(props),props.data.currency)} {props.data && props.data.tx_fee_bearer && props.data.tx_fee_bearer !== 'seller' ? 'plus Switch fee of ' + toLocaleDisplay(cardFees(props),props.data.currency): ' '} and will be depositing {toLocaleDisplay(netAmount(props),props.data.currency)} plus {toLocaleDisplay(escrowFees(props),props.data.currency)} {props.data && props.data.tx_fee_bearer && props.data.tx_fee_bearer !== 'seller' ? 'plus ' + toLocaleDisplay(cardFees(props),props.data.currency) : ' '}  into escrow today. When you accept the item, we will credit the merchant with {toLocaleDisplay(netAmount(props),props.data.currency)}. If you reject the item, { props.terms && props.terms.refund_option===0 ?'you shall get full replacement for them.':'we will refund you '+toLocaleDisplay(refundableAmount(props),props.data.currency)+' within 24 hours.'}
                </p>
                <blockquote>
                   
                    Seller's Delivery Terms:
                    {props.terms && props.terms.refund_option===0? <ul><li className="text-danger">Seller supports full replacements. No refund allowed</li></ul>:
                    <div><p>A logistics fee of <span class={'text-danger'}><b>
                    {props.data.sellers && props.terms && toLocaleDisplay(logisticFees(props),props.data.currency)}</b></span> will be charged from your escrow deposit, if you;</p>
                    <ul>
                       
                        <li>Cancel after {props.data.sellers && props.terms && props.terms.SLA === "0.5" || props.data.sellers && props.terms && props.terms.SLA < 1.0 ? props.data.sellers && props.terms && props.terms.SLA * 60 +" minutes" : props.data.sellers && props.terms && props.terms.SLA + " hours" }</li>
                        {/* <li>Reject at no fault of Seller</li> */}
                        <li>Refund will be made less applicable fees</li>
                    </ul></div>
                }
                </blockquote>

                <p>After making successful payment, please complete your AtaraPay registration from the link sent to your email address.</p>
                <p>Please note that maximum delivery days of the item(s) is {Number(props.data.max_delivery_days)} days after which if the order is not accepted or rejected, your funds will be refunded less escrow fees.</p>
                <p>For details on our escrow service click <a rel="noopener noreferrer" target="_blank" href={'https://www.atarapay.com/why_escrow'}>here</a></p>
                <p className={'text-danger'}><small>Your funds are insured by a NAICOM-licensed insurance company.</small></p>
            </div>
            <div className="clearfix">
                <div className="float-right">
                    <button onClick={props.onClose} className="btn btn-md btn-danger"> Cancel</button>
                    &nbsp;&nbsp;
                    <button onClick={props.pay} className ="btn btn-md btn-info">
                        <i className ="fa fa-coins"></i>
                        Yes, Desposit { toLocaleDisplay(grandTotal(props),props.data.currency)}{props.data.currency==='USD'?' ('+  toLocaleDisplay(grandTotalNGN(props),'NGN')+')' :''}
                    </button>  
                </div>
            </div>
        </div>
    </ReactModal>
);
export default ModalComponent;



