import React, {Component} from 'react';
import supportSchema from '../validation/support';
import {supportActions, alertActions} from '../actions'
import {Link, withRouter} from 'react-router-dom';
import {renderToStaticMarkup} from 'react-dom/server';
import {jsx, css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';
import swal from '@sweetalert/with-react'
import $ from 'jquery';
import * as axios from "axios";
import {BASE_URL} from "../constants";
import Select from "react-select";

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

const intialState = {
  subject: '',
  description: '',
  attachment: '',

}

class Support extends Component {
  state = {
    fields: {
      subject: '',
      description: '',
      attachment: '',
      email: "",
      role: 'self',
    },
    usersL: [],
    users: [],
    customers: [],
    customersL: [],
    error: null,
    submitted: false
  }

  componentDidMount() {
    this.getUsers();
    this.getCustomers();
  }

  // constructor (props) {
  //     super(props)

  // }

  getUsers = () => {
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         this.setState({
           users: res.data.data,
           usersL: res.data.data.map(c => ({label: c.firstname + ' ' + c.lastname, value: `${c.id}|${c.email}`}))

         })
       })
       .catch(err => {
         swal({
           icon: 'error',
           title: 'Error',
           text: 'Error fetching sellers',
         })
       })
       .finally(() => {
         this.setState({
           loading: false,
         })
       })
  }

  getCustomers = () => {
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/customers`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         this.setState({
           customers: res.data.data,
           customersL: res.data.data.map(c => ({label: c.firstname + ' ' + c.lastname, value: `${c.id}|${c.email}`}))
         })
       })
       .catch(err => {
         swal({
           icon: 'error',
           title: 'Error',
           text: 'Error fetching customers',
         })
       })
       .finally(() => {
         this.setState({
           loading: false,
         })
       })
  }

  selectUser = (value) => {
    const parts = value.split('|');
    const email = parts[1]
    const id = parts[0]

    const fields = {...this.state.fields, email: email, user_id: id}

    this.setState({
      fields
    })
  }

  handleChange = (e) => {
    const {name, value} = e.target;
    const fields = {...this.state.fields, [name]: value}

    this.setState({fields});
  }

  handleFileChange = (e) => {
    const fields = {...this.state.fields, attachment: e.target.files[0]}
    console.log(fields);
    this.setState({fields})
  }

  handleSubmit = async (e) => {
    if(this.state.submitted){
      return;
    }

    this.setState({submitted: true, error: null});

    const support = this.state.fields;

    const validation = await supportSchema.validate(support).catch(
       error => {
         swal("Error", error.errors[0], "error");
         return this.setState({error: error.errors[0]})
       }
    )
    if (!validation) {
      return
    }

    const formData = new FormData();

    for(const i in this.state.fields){
      console.log(this.state.fields[i])
      formData.append(i, this.state.fields[i])
    }

    axios({
      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}admin/support_mail`,
      data: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         console.log(res.data);
         if(res.data.status == 'success'){
           swal({
             title: 'Email sent',
             text: 'Successfully sent email.',
           })

           this.attachmentRef.value = null;
           this.setState({
             fields: {
               subject: '',
               description: '',
               attachment: '',
               email: "",
               role: 'self',
             }
           })
         }
         else{
           swal({
             icon: 'error',
             title: 'Error',
             text: 'Couldn\t send email.',
           })
         }

       })
       .catch(err => {
         console.log(err.response)
         swal({
           icon: 'error',
           title: 'Error',
           text: 'Error sending mail',
         })
       })
       .finally(() => {
         this.setState({
           loading: false,
           submitted: false,
         })
       })
  }

  render() {
    const {fields, error} = this.state
    const {loading, alert, success, user} = this.props
    //console.log(success);

    return (
       alert ? <div class="content-wrapper" style={{minHeight: 103, padding: '2%'}}>
         <div class="content-header">
           <div class="container-fluid">
             <div class="row mb-2">
               <div class="col-sm-6">
                 <h1 class="m-0 text-dark">Support</h1>
               </div>
             </div>
           </div>
         </div>
         <br/>
         <section class="content">
           <div class="container-fluid">
             <div class="callout callout-info">
               <div class="row">
                 <div class="col-sm-12 col-md-12"><br/><br/>
                   {/* {error && <div class="alert animated bounceIn support-alert alert-danger"><a aria-label="close" class="close alert-close">×</a>
                        <span class="alert-message-content">{error}</span></div>} */}
                   <div>
                     <div class="form-group"><label>Subject *</label>
                       <input type="text" class="form-control" name="subject" onChange={this.handleChange.bind(this)}
                              value={fields.subject}/></div>
                     <br/>

                     <div class="form-group"><label>Create for:</label>
                       <select value={this.state.fields.role} type="text" class="form-control" name="user"
                               onChange={e => {
                                 const value = e.target.value;

                                 if(value === 'self') {
                                   this.setState({
                                     fields: {
                                       ...this.state.fields,
                                       role: value,
                                       user_id: user ? user.id : 0,
                                       email: user ? user.email : ''
                                     }
                                   })
                                 }
                                 else {
                                   this.setState({
                                     fields: {
                                       ...this.state.fields,
                                       role: value,
                                       user_id: 0,
                                       email: ''
                                     }
                                   })
                                 }
                               }}>
                         <option value={''} disabled>Choose</option>
                         <option value={'self'}>On Behalf of Self</option>
                         <option value={'user'}>Seller</option>
                         <option value={'customer'}>Buyer</option>
                       </select>
                     </div>
                     {
                       this.state.fields.role === 'user' &&

                       <div className="form-group"><label>User:</label>
                         <Select
                            options={this.state.usersL}
                            onChange={selected => this.selectUser(selected.value)}
                         />
                       </div>
                     }{
                     this.state.fields.role === 'customer' &&

                     <div class="form-group"><label>Customer:</label>
                       <Select
                          options={this.state.customersL}
                          onChange={selected => this.selectUser(selected.value)}
                       />
                     </div>
                   }
                     <div class="form-group"><label>Contact Email *</label>
                       <input type="text" class="form-control" name="email" onChange={this.handleChange.bind(this)}
                              value={fields.email}/></div>
                     <br/>
                     <div class="form-group"><label>Description *</label>
                       <textarea class="form-control" name="description" minLength="5"
                                 onChange={this.handleChange.bind(this)} value={fields.description}></textarea></div>
                     <br/>
                     <div class="form-group"><label>Attachment </label><br/>
                       <input type="file" name="attachment"
                              ref={ref => this.attachmentRef = ref}
                              onChange={this.handleFileChange.bind(this)}
                       /></div>
                     <br/>
                     <button class="btn btn-primary"
                             onClick={this.handleSubmit.bind(this)}
                             disabled={loading}>{loading ? 'Loading ...' : 'Send Email'}</button>
                     <br/><br/>
                   </div>

                 </div>
               </div>
             </div>
           </div>
         </section>
       </div> : <div className='sweet-loading'>
         <ClipLoader
            className={override}
            sizeUnit={"px"}
            size={70}
            color={'blue'}
            loading={loading}
         />
       </div>
    )
  }
}

export default withRouter(Support);