import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import COLORS from "../../theme/colors";
import Label from "../Label";
const Wrapper = styled.div`
  width: 20vw;
`;
const StyledSelect = styled.select`
  -moz-appearance: menulist-button;
  -ms-appearance: menulist-button;
  -webkit-appearance: menulist-button;
  appearance: menulist-button;
  width: 100%;
  height: 3rem;
  ${({ invalid }) => {
    return invalid
      ? css`
          border-color: ${COLORS.errorColor};
        `
      : css``;
  }}
`;
const StyledOption = styled.option``;
const SelectField = forwardRef(
  ({ items, label, labelDescription, meta, input }, ref) => {
    const { touched, error } = meta;
    const invalid = touched && !!error;
    return (
      <Wrapper ref={ref}>
        {label && (
          <Label description={labelDescription} hasError={invalid && error}>
            {label}
          </Label>
        )}
        <StyledSelect
          type='select'
          {...meta}
          {...input}
          ref={ref}
          invalid={invalid}
        >
          <StyledOption value=''>Select {label}</StyledOption>
          {items?.map((item, index) => {
            return (
              <StyledOption
                key={`${item.id}${index}`}
                id={item.id}
                value={item.id}
              >
                {item.label}
              </StyledOption>
            );
          })}
        </StyledSelect>
        {invalid && error && (
          <Label description={error} hasError={invalid && error} />
        )}
      </Wrapper>
    );
  }
);
SelectField.displayName = "SelectField";
SelectField.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  labelDescription: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};
export default SelectField;
