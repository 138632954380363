import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from 'react-responsive-modal';
import { SuccessNotification } from './ModalViews';
import ModalComponent from './ModalComponent';
import USSDModalComponent from './USSDModalComponent';
import * as moment from 'moment';
import {alertActions } from '../actions'
import swal from '@sweetalert/with-react';
import { formatPhoneNumber } from 'react-phone-number-input';

import { jsx, css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { transactionActions, orderActions} from '../actions';
import  transactionHelper from '../helpers/transaction';

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

const formatToSixDigits = (number)=>{
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;
    }
    return output;
}

const toLocaleDisplay = (quantity) => {
    if (quantity === undefined)
        quantity = 0;

    return quantity.toLocaleString(undefined, { style: 'currency', currency: 'NGN', currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

class Transactions extends Component{

    state = {
        filterable: false,
        notCustom: true,
        period: "All",
        assignModal: false,
        fullModal: false,
        respondModal: false,
        from_date: "",
        filter: "",
        to_date: "",
        data:[],
        order: "",
        response: "",
        fields:{
            order_id: 0,
            delivery_man_id: 0
        },
        transactions:[],
        successModal: false,
        successId: 0,
        reason: '',
        cancelOrder: false,
        showPayment: false,
        showUSSDPayment: false,
        deposit: false,
        alt: '',
        paymentOptions: false,
        ussdthankyou: false,
        selectedoption:"",
        activetransaction: "",
        current_date:'',
        validSec:300
    }

    componentDidMount(){
        
        this.props.getTransactions(localStorage.getItem("trust_user_type"), this.props.history);
            if (this.props.transactions) {
                this.setState({ data: [...this.props.transactions] });
            }
        const {status, id} = this.props.match.params;
       if(status === "success"){
            this.setState({successModal: true, successId: id});
        }
    }
   

    componentWillReceiveProps(props){
        
        if(props.alert && props.alert.message && props.alert.type==="alert-danger"){
            swal("",props.alert.message,"error").then(() => {
                props.dispatch(alertActions.alert.clear());
              });
        }else if(props.alert && props.alert.message && props.alert.type==="alert-success"){
            if (props.alert.message === 'response sent') {
                swal({ title: "Success", content: <div class="text-left"><p>Based on your approval, you can now proceed to make payment.</p><p>To make payment, please do following;</p><ol><li>Click on the <b>My P2P Transactions tab</b> on the left pane</li><li>Locate the transaction and click <b>Make Payment</b> to pay.</li></ol></div>, icon: "success" }).then(() => {
                    props.dispatch(alertActions.alert.clear());
                    props.getTransactions(props.role, props.history);
                    this.onCloseModal("respondModal");
                    this.onCloseModal('assignModal');
                    this.onCloseModal('cancelOrder');
                    this.onCloseModal('showPayment');
                    this.onCloseModal('showUSSDPayment');
                    this.onCloseModal('paymentOptions');
                    this.setState({ reason: '', order: "",USSDshowPayment:false  })
                    this.forceUpdate();

                });
                return;
            }

            swal("Success",props.alert.message,"success").then(() => {
                props.dispatch(alertActions.alert.clear());
                props.getTransactions(props.role, props.history);
                 this.onCloseModal("respondModal");
                this.onCloseModal('assignModal');
                this.onCloseModal('cancelOrder');
                this.onCloseModal('showPayment');
                this.onCloseModal('showUSSDPayment');
                this.onCloseModal('paymentOptions');
                this.setState({ reason: '', order: "" ,USSDshowPayment:false })
                this.forceUpdate();

            });
               
        }
        if(props.transactions.length>0){
              this.setState({data:[...props.transactions]});
              this.setState({orders:[...props.transactions]});
        }

    }

    handleSelect = (e)=>{
        const {value} = e.target;
        if(value === "Custom"){
            this.setState({notCustom:!this.state.notCustom})
        }
        this.filterPeriod(value);
        this.setState({period:value});
    }
    handleResponse = (e) => {
        const {name, value} = e.target;
        const response = {...this.state.response, [name]: value}
        this.setState({response})
    }

    handlePhone = (value) => {
        this.setState({ 'alt': value });
    } 
    submitResponse = () => {
        const response = {...this.state.response, order_id: this.state.order.id}
        this.props.respond(this.props.role, response, this.props.history);
    }

    filterPeriod = (value)=>{
      const data = this.state.transactions;  
      if(value == "All"){
        this.table_rows = data;
        this.notCustom = true;
        this.page = 1;
        this.to_date = null;
        this.from_date = null;
        this.setState({data:[...this.props.transactions]});
      }
      
      if(value == "This Week"){
          var ordersThisWeek = [];      
          for(var i = 0; i < data.length; i++){
              var now = moment();
              var input = moment(data[i].created_at);
            var isThisWeek = input.isSame(now, "week");
  
              if(isThisWeek){
                  ordersThisWeek.push(data[i]);
              }
          }
  
          this.table_rows = ordersThisWeek;
          this.notCustom = true;
          this.page = 1;
          this.to_date = null;
          this.from_date = null;
          this.setState({data:[...this.table_rows]});
      }
      
      if(value == "This Month"){
          var ordersThisMonth = [];      
          for(var i = 0; i < data.length; i++){
              //var now = moment();
              var input = moment(data[i].created_at);
              var isThisMonth = input.isSame(new Date(), 'month');
  
              if(isThisMonth){
                  ordersThisMonth.push(data[i]);
              }
          }
  
          this.table_rows = ordersThisMonth;
          this.notCustom = true;
          this.page = 1;
          this.to_date = null;
          this.from_date = null;
          this.setState({data:[...this.table_rows]});
      }
      
      if(value == "60 Days Ago"){
          var ordersSixtyDaysAgo = [];      
          for(var i = 0; i < data.length; i++){      
              var sixty_days_ago = moment().subtract(60, 'days');      
              var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);
  
              if(isBetweenSixtyDays){
                  ordersSixtyDaysAgo.push(data[i]);
              }
          }
  
          this.table_rows = ordersSixtyDaysAgo;
          this.notCustom = true;
          this.page = 1;
          this.to_date = null;
          this.from_date = null;
          this.setState({data:[...this.table_rows]});
      }
      
      if(value == "90 Days Ago"){
          var ordersNinetyDaysAgo = [];      
          for(var i = 0; i < data.length; i++){      
              var ninety_days_ago = moment().subtract(90, 'days');      
              var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);
  
              if(isBetweenNinetyDays){
                  ordersNinetyDaysAgo.push(data[i]);
              }
          }
  
          this.table_rows = ordersNinetyDaysAgo;
          this.notCustom = true;
          this.page = 1;
          this.to_date = null;
          this.from_date = null;
          this.setState({data:[...this.table_rows]});
      }
  
      if(value == "Custom"){
          this.table_rows = data;
          this.to_date = null;
          this.from_date = null;
      }
    }

    filterDates = (data)=>{
      var ordersBetweenTheTwoDates = [];
      for(var i = 0; i < data.length; i++){
          var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); 
          if(isBetweenDates){
              ordersBetweenTheTwoDates.push(data[i]);
          }
      }
      this.table_rows = ordersBetweenTheTwoDates;
      this.page = 1;
      return this.table_rows;
    }
    checkDeliveryTerm=(is_refundable,transaction_date,current_date,validSec)=>{
      console.log('is_refundable:',is_refundable);
      console.log ('transaction date :',transaction_date);
      console.log ('current_date  :',current_date);
      let date1=new Date(transaction_date.replace(/-/g, "/"));
      let date2=new Date(current_date.replace(/-/g, "/"));
      let SecDiff=(date2.getTime()-date1.getTime())/1000;      
      console.log('transaction_date Sec Diff',SecDiff);
      if(is_refundable===0){
        if(SecDiff>validSec)
          return false;
      }
      return true;
    }
    handleDate=(e)=>{
        const {name, value} = e.target;
        this.setState({[name]:value});
    }
    handleDispatch=(e)=>{
       const {name,value} = e.target;
       const fields = {...this.state.fields,delivery_man_id:value}
       this.setState({fields});
    }
    assignDispatcher=(e)=>{
        e.preventDefault();
        const {fields,order} = this.state;
        const data = {"order_id":order.id , "delivery_man_id":fields.delivery_man_id};
        this.props.assignDispatcher("seller",data,this.props.history);

        this.props.view("seller",this.props.history);
        this.forceUpdate();
        this.setState({data:[this.state.transactions]});
    }

    cancelTransaction = () => {
        this.props.cancelTransaction(localStorage.getItem("trust_user_type"), { order_id: this.state.order.id, comment: this.state.reason }, this.props.history)
    }
    submitPaymentoptions = () => {
      var selectedoption = this.state.selectedoption;
      var activetransaction=this.state.activetransaction
      if(selectedoption != ""){
        if(selectedoption == 'paystack'){
           this.onOpenModal('showPayment',activetransaction);
        }else{
          this.onOpenModal('USSDshowPayment',activetransaction);
        }           
      }
    }

    redirectPaymentoptions = (selectedoption,order) => {
         var selectedoption = selectedoption.toLowerCase();;
         this.setState({
                activetransaction: order
          });
         var activetransaction=order;
         if(selectedoption != "")
         {
            if(selectedoption == 'paystack')
            {
               this.onOpenModal('showPayment',activetransaction);
            }
            else
            {
              this.onOpenModal('USSDshowPayment',activetransaction);
            }
           
         }
    }


    onoptionChanged= (e) => {
      this.setState({selectedoption: e.currentTarget.value});
    }
    filterTable=(filter)=>{
        var data = this.state.transactions;
        var filteredRows = [];
        if(filter != ""){
          for(var i = 0; i < data.length; i++){        
            if(data[i].id === parseInt(filter) || 
                (data[i].customers && data[i].customers.lastname && data[i].customers.lastname.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].customers && data[i].customers.firstname && data[i].customers.firstname.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].sellers && data[i].sellers.business_name && data[i].sellers.business_name.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].sellers && data[i].sellers.users && data[i].sellers.users.firstname && data[i].sellers.users.firstname.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].sellers && data[i].sellers.users && data[i].sellers.users.lastname && data[i].sellers.users.lastname.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].transaction_date && data[i].transaction_date && data[i].transaction_date.includes(filter)) ||
                (data[i].delivery_men && data[i].delivery_men.firstname && data[i].delivery_men.firstname.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].delivery_men && data[i].delivery_men.lastname && data[i].delivery_men.lastname.toUpperCase().includes(filter.toUpperCase())) ||
                (data[i].status && data[i].status.title && data[i].status.title.toUpperCase().includes(filter.toUpperCase()))
            ){
                filteredRows.push(data[i]); 
            }
          }
          this.setState({data:[...filteredRows]});
        }else{
          this.setState({data:[...this.state.transactions]});
        }        
    }

    handleFilter = (e) => {
        const {value} = e.target;
        this.setState({filter:value},()=>this.filterTable(this.state.filter));
        // this.filterTable();
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }
    onOpenModal = (name,order) => {
        this.setState({ [name]: true, order:order});

        if(name === 'respondModal') {
            this.setState({respond: {
                response: "",
                comment: ""
            }})
        }

        if(name === 'paymentOptions')
        {
          this.setState({
                activetransaction: order
             });
        }
        
      };

    handleDepositView = () => {
        this.setState(prevState => (
            { deposit: !prevState.deposit }
        ))
    }

    toggleModal = (name) => {
        this.setState(
            { [name]: !(this.state[name]) }
        )
    }
    
    onCloseModal = (name) => {
      this.setState({ [name]: false });
      this.setState({order:"", response: ""});
    };
    confirmModals = () => {
      const phone = this.state.order.customers.phone_number
      const email = this.state.order.customers.email
      const orderId = formatToSixDigits(this.state.order.id)
      let data = { "order": orderId, "phone_number": phone, "email": email };
      this.props.completeOrder(data)
      this.props.dispatch(alertActions.alert.success(("Thank you. Buyer has been notified to accept or reject your delivery.")));
      this.onCloseModal("confirmModal")
    }
  
    checkAll = (ele) => {
      var checkboxes = document.getElementsByClassName('checkBox');
      if (ele.target.checked === true) {
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
              checkboxes[i].checked = true;
          }
        }
      } else {
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].type == 'checkbox') {
              checkboxes[i].checked = false;
          }
        }
      }
    }
      
    render(){
        const { transactions, loading, deliveryMen, alert, role, updating, canceling, adding, user} = this.props;
      const {filterable} = this.state
      let columns = [];
        
      if(localStorage.getItem("trust_user_type") == 'customer'){

          

        columns = [{
            Header: props => <span><b>Order ID</b></span>,
            id:"id",
            filterable,
            accessor:d=>d ,// String-based value accessors!
            Cell:props =>
                (<a href="#/app/transactions" class="text-left" onClick={()=>this.onOpenModal("fullModal",props.value)}>
                    {formatToSixDigits(props.value.id)}
                </a>),
             className: 'text-center-align' 
          }, 
          {
              Header: props => <span class="text-left"><b>Seller</b></span>,
            id: "seller",
            filterable,
            accessor: d => d.sellers,
            Cell: props => {
                let display = ''
                if(props.value != null){
                    if(props.value.business_name) {
                        display = props.value.business_name;
                    }else {
                        display = props.value.users == null ? '' : props.value.users.firstname;
                    }
                }
                return (
                    <span className='number'>{display}</span>
                )
            },
            /* className: 'text-center' */
                
          },
          {
            id: 'transaction_date', // Required because our accessor is not a string
            Header: props=><span><b>Order Date</b></span>,
              filterable,
            accessor:'created_at', // Custom value accessors!
            /* className: 'text-center' */
          },{
            id:'status',
              filterable,
            sortable: true,
            Header: props=><span><b>Order Status</b></span>,
            accessor: d=>d.status.title, // String-based value accessors!
              Cell: props => <div>
                  {props.value === "Dispute" ? (<span class="badge badge-danger">Dispute</span>) : props.value === "Disputed" ? (<span class="badge badge-danger">Disputed</span>) : props.value === "Canceled" ? (<span class="badge badge-danger">Canceled</span>) : props.value === "Rejected" ? (<span class="badge badge-warning">Rejected</span>) : props.value === "Pending" ? (<span class="badge badge-warning">Pending</span>) : props.value === "Pending II" ? (<span class="badge badge-warning">Pending II</span>) : props.value === "Pending Buyer Response" ? (<span class="badge badge-warning">Pending Buyer Response</span>) : props.value === "Awaiting Agreement" ? (<span class="badge badge-warning">Awaiting Agreement</span>) : props.value === "Agreed" ? (<span class="badge badge-info">Agreed</span>) : (<span class="badge badge-success">{props.value}</span>)}
              </div>,
            /* className: 'text-center' */
          },{
            id:'payment',
            filterable,
            sortable: true,
            Header: props => <span><b>Payment Status</b></span>,
            accessor: 'payment', // String-based value accessors!
            className: 'text-left',
            Cell: props => <div>
                {props.value ? (<span class="badge badge-success">Paid</span>) : (<span class="badge badge-danger">Not Paid</span>)}
            </div>
          },{
            id:"actions",
              filterable,
            Header: props => <span><b>Actions</b></span>,
            sortable: false,
            accessor: d => d,
            Cell: props => <span>
                
                {/* Transaction button */}
            
                <Link  style={{cursor : "pointer"}} class="text-primary" to={`/app/transaction/${props.value.id}`}>
                    <small>View Transaction</small><br></br>
                </Link>


                {/* Pay Transaction button */}
                {role === 'customer' && props.value.status.title !== 'Canceled' && props.value.status.title === 'Agreed' &&
                    <div>
                    {/* <a style={{ cursor: "pointer" }} class="text-success" onClick={() => this.onOpenModal("paymentOptions", transactionHelper.rformatTransactionData(props.value))}>
                            <small>Make Payment {props.value.gateway_name}</small>
                            &nbsp;&nbsp;<br />
                        </a> */} 

                        <a style={{ cursor: "pointer" }} class="text-success" onClick={() => this.redirectPaymentoptions(props.value.gateway_name,transactionHelper.rformatTransactionData(props.value))}>
                            <small>Make Payment</small>
                            &nbsp;&nbsp;<br />
                        </a>  

                    </div>
                }

                {/* Cancel button */}
                {
                    (this.checkDeliveryTerm(props.value.sellers.delivery_terms.refund_option,props.value.transaction_date,props.value.extra.current_date,props.value.extra.validSec)) && props.value.status.title !== 'Accepted' && props.value.status.title !== 'Rejected' && props.value.status.title !== 'Canceled' && props.value.status.title !== 'Dispute' && props.value.status.title !== 'Disputed' && props.value.status.title !== 'Complete' &&<div><a style={{ cursor: "pointer" }} class="text-danger" onClick={() => this.onOpenModal("cancelOrder", props.value)}>
                        <small>Cancel Transaction </small>
                    </a></div> 
                }

                {/* Dispute button 
                 {props.value.status.title === 'Dispute' &&
                  <div>
                      <Link 
                          onClick={(e) => {
                              if(!props.value.payment) {
                                  e.preventDefault()
                              }
                          }} 
                          to={`/app/seller/dispute/raise/${props.value.id}`} class="text-danger">
                          <small>Raise Dispute</small>
                      </Link> 
                  </div>
                  }
                */}

                {/* Cancel Dispute button */}
                {props.value.status.title == 'Disputed' && <div>
                    <Link to="#" class="text-dark">
                    <small>Cancel Dispute</small>
                    &nbsp;&nbsp;
                </Link></div>}

                {/* Respond to terms button */}
                {(props.value.status.title === 'Awaiting Agreement' && !props.value.payment) && props.value.started_by != role &&<div><a style={{cursor : "pointer"}} class="text-success" onClick={()=>this.onOpenModal("respondModal",props.value)}>
                    <small>Respond to Terms</small>
                </a></div>}
            </span>,
            /* className: 'text-center' // String-based value accessors! */
          }]
          

      }else{
         columns = [{
            Header: props => <span><input type="checkbox" name="check[]" onChange={(ev) => this.checkAll(ev)}/></span>,
            id:"check",
            sortable:false,
            filterable:false,
            accessor:d=>d ,// String-based value accessors!
            Cell: props => <input type="checkbox" className="checkBox" name="checks" value={props.value.id} />
         ,
        },
        {
            Header: props => <span class="text-left"><b>Order ID</b></span>,
            id:"id",
            filterable,
            accessor:d=>d ,// String-based value accessors!
             Cell: props => <a href="#/app/transactions" onClick={()=>this.onOpenModal("fullModal", props.value)}>{formatToSixDigits(props.value.id)}</a>,
            /* className: 'text-center' */
          }, {
            Header: props => <span><b>Buyer</b></span>,
            id: "firstname",
            filterable,
            accessor: d => d.customers,
            Cell: props => <span className='number'>{props.value == null ? '' : props.value.firstname }<br/>{props.value == null ? '':props.value.lastname}</span>,
            /* className: 'text-center' // Custom cell components! */
          }, 
          
          {
            id: 'transaction_date', // Required because our accessor is not a string
            Header: props =>  <span><b>Order Date</b></span>,
            filterable,
            accessor:'created_at',
            /* className: 'text-center' // Custom value accessors!, */
            
          },
          
          {
            id:"value",
              filterable,
            Header: props => <span><b>Order Status</b></span>,
            accessor: d=>d.status.title,
              Cell: props => <div>
                  {props.value === "Dispute" ? (<span class="badge badge-danger">Dispute</span>) : props.value === "Disputed" ? (<span class="badge badge-danger">Disputed</span>) : props.value === "Canceled" ? (<span class="badge badge-danger">Canceled</span>) : props.value === "Rejected" ? (<span class="badge badge-warning">Rejected</span>) : props.value === "Pending" ? (<span class="badge badge-warning">Pending</span>) : props.value === "Pending II" ? (<span class="badge badge-warning">Pending II</span>) : props.value === "Pending Buyer Response" ? (<span class="badge badge-warning">Pending Buyer Response</span>) : props.value === "Awaiting Agreement" ? (<span class="badge badge-warning">Awaiting Agreement</span>) : props.value === "Agreed" ? (<span class="badge badge-info">Agreed</span>) : (<span class="badge badge-success">{props.value}</span>)}
              </div>,
            /* className: 'text-center' // String-based value accessors! */
          },{
            id:'payment',
            sortable: true,
            filterable,
            Header:props=><span><b>Payment Status</b></span>,
            accessor: 'payment', // String-based value accessors!
            className: 'text-left',
            Cell: props => <div>
                {props.value ? (<span class="badge badge-success">Paid</span>) : (<span class="badge badge-danger">Not Paid</span>)}
            </div>
          },{
            id:"actions",
            Header: props => <span><b>Actions</b></span>,
            sortable: false,
              filterable,
            accessor: d => d,
            Cell: props => <span>

                {/* View button */}
                <Link  style={{cursor : "pointer"}} class="text-primary" to={`/app/transaction/${props.value.id}`}>
                    <small>View Transaction</small><br></br>
                </Link>
                

                {/* Cancel button */}
                {
                    props.value.status.title !== 'Accepted' && props.value.status.title !== 'Rejected' && props.value.status.title !== 'Canceled' && props.value.status.title !== 'Complete' && props.value.status.title !== 'Dispute' && props.value.status.title !== 'Disputed' &&
                    <div>
                     <a style={{ cursor: "pointer" }} class="text-danger" onClick={() => this.onOpenModal("cancelOrder", props.value)}>
                        <small>Cancel Transaction </small>
                        &nbsp;&nbsp;<br />
                    </a>
                    </div>
                }

                {/* Dispute button */}
                {props.value.status.title === 'Dispute' &&
                <div>
                    <Link
                        onClick={(e) => {
                            if (!props.value.payment) {
                                e.preventDefault()
                            }
                        }}
                        to={`/app/seller/dispute/raise/${props.value.id}`} class="text-danger">
                        <small>Raise Dispute</small>
                        &nbsp;&nbsp;
                    </Link> 
                </div>
                }
                

                {props.value.status.title === 'Disputed' &&
                <div>
                <Link to="#" class="text-dark">
                <small>Cancel Dispute</small>
                &nbsp;&nbsp;
                </Link>
                </div>}

                {(props.value.status.title === 'Awaiting Agreement' && props.value.status.title !== 'Complete' && props.value.status.title !== 'Accepted' && props.value.status.title !== 'Canceled' && !props.value.payment) && props.value.started_by !== role && <div><a style={{cursor : "pointer"}} class="text-success" onClick={()=>this.onOpenModal("respondModal",props.value)}>
                    <small>Respond to Terms</small>
                </a></div>}
                
                {/*deliveryMen !== null && props.value.delivery_men != null && props.value.status.title !== 'Complete' && props.value.status.title !== 'Accepted' && props.value.status.title !== 'Canceled' && props.value.status.title !== 'Rejected' && props.value.payment  &&<div> <a style={{cursor : "pointer"}} class="text-black" onClick={()=>this.onOpenModal("assignModal",props.value)}>
                    <small>Reassign Delivery person</small>
                </a></div>*/}
                {/*deliveryMen !== null && props.value.delivery_men == null && props.value.status.title !== 'Complete' && props.value.status.title !== 'Accepted' && props.value.status.title !== 'Canceled' && props.value.status.title !== 'Rejected' && props.value.payment &&<div><a style={{cursor : "pointer"}}  class="text-success" onClick={()=>this.onOpenModal("assignModal",props.value)}>
                    <small>Assign Delivery person</small>
                </a></div>*/
                }
               
                
            </span>,
            /* className: 'text-center' // String-based value accessors! */
          }]
      }
      
        return(
          
           <div>
            <SuccessNotification open={this.state.successModal}
                id={this.state.successId} onClose={() => { this.toggleModal("successModal") }} />
              <ModalComponent pay={() => {
                    this.props.pay(role, this.state.order, this.state.alt, this.props.history);
                }} handlePhone={(val) => {
                    this.handlePhone(val && formatPhoneNumber(val, 'International').replace(/ /g, "") || '')
                    }} role={role} seller_phone={null} user={user} openInfo={this.handleInfoView} open={this.state.showPayment} onClose={() => this.onCloseModal("showPayment")} data={this.state.order} terms={this.state.order && this.state.order.sellers && this.state.order.sellers.delivery_terms && this.state.order.sellers.delivery_terms} />

              <USSDModalComponent ussdpay={() => {
                    this.props.ussdpay(role, this.state.order, this.state.alt, this.props.history);
                }} handlePhone={(val) => {
                    this.handlePhone(val && formatPhoneNumber(val, 'International').replace(/ /g, "") || '')
                    }} role={role} seller_phone={null} user={user} openInfo={this.handleInfoView} open={this.state.USSDshowPayment} onClose={() => this.onCloseModal("USSDshowPayment")} data={this.state.order} terms={this.state.order && this.state.order.sellers && this.state.order.sellers.delivery_terms && this.state.order.sellers.delivery_terms} />

            <Modal open={this.state.fullModal} onClose={() => this.onCloseModal("fullModal")} center>
                <form>
                    <div class="modal-header">
                        <h4 class="modal-title"><small></small></h4> 
                    </div>

                    <div class="modal-body">
                        <div class="row"></div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group"><label>Order Number</label> <br/> <input type="text" readonly="readonly"
                                    value={formatToSixDigits(this.state.order&&this.state.order.id)}     class="form-control" /></div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label>Amount</label><br/>
                                <input type="text" readonly="readonly"                                
                                        value={this.state.order && toLocaleDisplay(parseFloat(this.state.order.amount_payed/100))} class="form-control" /></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group"><label>Order Date</label> <br/> <input type="text" readonly="readonly"
                                        value={this.state.order&&this.state.order.created_at}  class="form-control" /></div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group"><label>Order Status</label> <br/> <input type="text" readonly="readonly"
                                        value={this.state.order&&this.state.order.status.title}   class="form-control" /></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group"><label>Buyer Name</label> <br/> <input type="text" readonly="readonly"
                                        value={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname}    class="form-control" /></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group"><label>Delivery Location</label> <br/> <input type="text" readonly="readonly"
                                        value={this.state.order&&this.state.order.delivery_location || "N/A"}  class="form-control" /></div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group"><label>Delivery Date</label> <br/> <input type="text" readonly="readonly"
                                        value={this.state.order&&this.state.order.delivery_date || "N/A"} class="form-control" /></div>
                            </div>
                        </div><br/><br/>
                            {this.state.order.extra ?
                        <div class="row">
                                
                            {this.state.order && Object.keys(this.state.order.extra).map(extra => (
                                <div class="col-sm-6">
                                    <div class="form-group"><label>{extra.charAt(0).toUpperCase() + extra.slice(1).replace(/_/g, " ")}</label> <br/> <input type="text" readonly="readonly"
                                            value={this.state.order.extra[extra]}  class="form-control" /></div>
                                </div>
                            ))}
                        </div>: null }
                        <br/><br/>
                    </div>
                </form>
            </Modal>
            <Modal open={this.state.cancelOrder} onClose={() => this.onCloseModal("cancelOrder")} center>

                    <div class="modal-header">
                        <h4 class="modal-title">Cancel Transaction</h4>
                    </div>
                    <div class="modal-body">
                        <br />
                        <div class="container">
                            <ul>
                                <li>Please Provide a reason for cancelling this transaction and select Confirm button</li>
                                <li> The other party will be given the reason for cancelling this transaction</li>
                                <li> An asterisk (*) denotes a required field</li>
                            </ul>
                        </div>
                        <br></br>
                        <div class="container">
                            <div class="form-group">
                                <div class="col-sm-10">
                                    <label>Reason for Cancellation * <small>(Up to 435 Characters)</small></label>
                                    <textarea maxLength={"435"} disabled={this.state.order && this.state.order.status.title === 'Canceled'} class="form-control" name="reason" onChange={this.handleChange} value={this.state.reason}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer"><button type="button" data-dismiss="modal"
                        class="btn btn-primary" onClick={() => this.onCloseModal("cancelOrder")} >Don't Cancel</button>
                        &nbsp;&nbsp;
            <button onClick={() => this.cancelTransaction()} disabled={canceling} type="button" class="btn btn-danger">{canceling ? 'Canceling ...' : 'Cancel'}</button></div>

                </Modal>
        
                <Modal open={this.state.assignModal} onClose={() => this.onCloseModal("assignModal")} center>
                    <form>
                        <div class="modal-header">
                            <h4 class="modal-title"><small>{this.state.order.delivery_men == null ? "Assign" : "Reassign"} Person to Order {formatToSixDigits(this.state.order.id)}</small></h4>

                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    {/* <div class="alert alert-danger assign-danger d-none"><button type="button" class="close">×</button>
                                    <strong>Error!</strong>
                                </div> */}
                                    {/* {alert && <div class="alert alert-success assign-success d-none"><button type="button" class="close">×</button>
                                    <strong>Success!</strong> Order {formatToSixDigits(this.state.order.id)} has been assigned.
                                </div>} */}
                                    {/* {alert && alert.message && <div class="alert alert-success assign-success"><button type="button" class="close">×</button>
                                    <strong>Success!</strong> {alert.message}
                                </div>} */}
                                    <small style={{ 'color': 'red' }}>Ensure a Personnel is created via the “Fulfillment�? tab on the left of the screen.</small>


                                    <div class="form-group"> <br /> <select class="form-control" defaultValue={this.state.order.delivery_man} name="dispatcher" onChange={this.handleDispatch}>
                                        <option value="">Select Personnel</option>
                                        {this.state.order.delivery_men !== null ? (deliveryMen && deliveryMen.map((man) => this.state.order && this.state.order.delivery_men && <option key={man.id} value={man.id}>{man.firstname + " " + man.lastname + ' '} {man.email ? ' - ' + man.email : null}</option>)) :
                                            (deliveryMen && deliveryMen.map((man) => <option key={man.id} value={man.id}>{man.firstname + " " + man.lastname + ' '}{man.email ? ' - ' + man.email : null}</option>))}

                                    </select> <br /> <button class="btn btn-primary" disabled={adding} onClick={this.assignDispatcher}>{adding ? "Assigning..." : this.state.order.delivery_men === null ? "Assign" : "Reassign"} </button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal> 
            <Modal open={this.state.respondModal} onClose={()=>this.onCloseModal("respondModal")} center>
                <form>
                    <div class="modal-header" style={{width: '400px'}}>
                        Respond to Transaction Agreement
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="alert alert-danger assign-danger d-none"><button type="button" class="close">×</button>
                                    <strong>Error!</strong>
                                </div>
                                {/* {alert && <div class="alert alert-success assign-success d-none"><button type="button" class="close">×</button>
                                    <strong>Success!</strong> Order {formatToSixDigits(this.state.order.id)} has been assigned.
                                </div>} {alert && alert.message && <div class="alert alert-success assign-success"><button type="button" class="close">×</button>
                                    <strong>Success!</strong> {alert.message}
                                </div>} */}
                                
                                <div class="form-group"> 
                                    <label>Agree or Disagree</label>
                                    <select class="form-control" name="response" onChange={this.handleResponse} value={this.state.response.response}>
                                        <option value="">Select One</option>
                                        <option value="agree">Agree</option>
                                        <option value="disagree">Disagree</option>
                                    </select> 
                                </div>
                                {this.state.response.response == "disagree" && <div class="form-group"> <br/> 
                                <label>Comment</label>
                                    <textarea type="text" class="form-control" name="comment" value={this.state.response.comment} onChange={this.handleResponse} rows="5"></textarea>
                                </div>}
                                    <button type="button" class="btn btn-primary" disabled={updating} onClick={this.submitResponse}>{updating ? 'Submitting...' : 'Submit Response'}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal open={this.state.paymentOptions} onClose={()=>this.onCloseModal("paymentOptions")} center>
                <form>
                    <div class="modal-header" style={{width: '400px'}}>
                        Choose an option:
                    </div>
                    <div class="modal-body payment-option">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="alert alert-danger assign-danger d-none"><button type="button" class="close">×</button>
                                    <strong>Error!</strong>
                                </div>
                                <p class="p_method paybystack">
                                 <label for="radio-label" class="radio-label">Pay with Paystack</label>
                                 <input type="radio" name="paymentOptions" id="pmethod" placeholder="paybystack" value="paystack" checked={this.state.selectedoption === 'paystack'} class="radio-ext paymentOptions" onChange={this.onoptionChanged} />
                                </p>
                                <span class="small_txt">(Card, Bank Transfer, GTPay and VISA QR)</span>
                                <p class="p_method paybyussd">
                                  <label for="radio-label" class="radio-label">Pay with USSD</label>
                                  <input type="radio" name="paymentOptions" id="pmethod" placeholder="paybyussd" value="ussd" class="radio-ext paymentOptions" checked={this.state.selectedoption === 'ussd'} onChange={this.onoptionChanged} />
                                 </p>                       
                                <button type="button" class="btn btn-primary"  onClick={this.submitPaymentoptions}>{updating ? 'Submitting...' : 'Proceed'}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal open={this.state.ussdthankyou} onClose={()=>this.onCloseModal("ussdthankyou")} center>
                <form>
                    <div class="modal-header" style={{width: '400px'}}>
                        Dear Customer,
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="alert alert-danger assign-danger d-none"><button type="button" class="close">×</button>
                                    <strong>Error!</strong>
                                </div>                       
                                <div class="form-group"> 
                                    <p>Thank you for choosing AtaraPay. An email has been sent to your registered AtaraPay email
                                     address with the details below. This order will be valid for 48 hours from when you placed the order 
                                     until 2020-01-01 09:25:42. The order will be cancelled if payment made is less than the expected total
                                      amount
                                      </p>
                                      <p>To complete your purchase, please make payment via USSD to the following account details:</p>
                                      <p>Bank Name: Providus Bank</p>
                                      <p>Bank Account Number: 6322355824</p>
                                      <p>Amount to pay: N61606.79</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>    
            <Modal open={this.state.confirmModal} onClose={() => this.onCloseModal("confirmModal")} center>

                    <div class="modal-header">
                        <h4 class="modal-title">Complete Order</h4>
                    </div>
                    <div class="modal-body">
                        <br/>
                        <div class="container">
                            <p>Before you complete delivery, please confirm from buyer that the goods or service(s) were received with no issues. This will prevent buyer from rejecting your order and causing delays to your settlement. Do you wish to continue this task?</p>
                        </div>
                    </div>
                    <div class="modal-footer">
            <button onClick={() => this.confirmModals()} disabled={loading} type="button" class="btn btn-danger">{loading ? 'confirming ...' : 'Yes'}</button>
            
                        &nbsp;&nbsp;
                                            <button type="button" data-dismiss="modal"
                        class="btn btn-primary" onClick={() => this.onCloseModal("confirmModal")} >No</button></div>

                </Modal>
            <div class="content-wrapper" style={{minHeight: 136}}>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <h1 class="m-0 text-dark">My P2P Transactions</h1>
                                <div class="attop">
                                  {localStorage.getItem("trust_user_type")==='seller'?
                                    <p>
                                        Here you will find details of all Person to Person (P2P) transactions. Also, from here you are able to view, agree and cancel P2P transactions. 
                                    </p>
                                    :
                                    <p>Here you will find details of all Person to Person (P2P) transactions. Also, from here you are able to view, agree, cancel and make payments for P2P transactions. </p>
                                    }
                                </div> <br/>
                                
                            </div>
                        </div>
                    </div>
                </div>
                { !loading ? 
                <section class="content">
                    <div class="container-fluid"><br/>
                        <div class="row ">
                            <div class="col-12 col-sm-12 col-md-12">
                                <div class="alert animated bounceIn alert-danger d-none search-alert"><a aria-label="close" class="close alert-close">×</a>
                                    <span></span></div>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-sm-2">
                                                <div class="form-group"><label for="period" >Period</label>
                                <select class="form-control" name="filter" onChange={this.handleSelect}>
                                                        <option value="All"  >All</option>
                                                        <option value="This Week">This Week</option>
                                                        <option value="This Month">This Month</option>
                                                        <option value="60 Days Ago">60 Days Ago</option>
                                                        <option value="90 Days Ago">90 Days Ago</option>
                                                        <option value="Custom">Custom</option>
                                                    </select></div>
                                            </div>
                                            <div class="col-12 col-sm-2">
                                                    <div class="form-group"><label for="filter">Filter</label><input type="text" placeholder="Filter" class="form-control" value={this.state.filter} onChange={this.handleFilter} /></div>
                                             </div>    
                                            <div class="col-12 col-sm-3">
                                                <div class="form-group"><label for="from">Date From</label> <input type="date"
                                                    name="from_date" value={this.state.from_date} disabled={this.state.notCustom} onChange={this.handleDate} class="form-control" /></div>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <div class="form-group"><label for="from" >Date To</label> <input type="date"
                                                    name="to_date"  value={this.state.to_date} disabled={this.state.notCustom} onChange={this.handleDate} class="form-control" /></div>
                                            </div>
                                            <div class="col-12 col-sm-2">
                                                <div class="form-group"> <label>&nbsp;</label>
                                                    <div class="row">
                                                        <div class="col-8">
                                                            <button disabled={this.state.notCustom} onClick={()=>{this.setState({data:[...this.filterDates(this.state.data)]})}} class="btn btn-info btn-block form-control">Search</button>
                                                        </div>
                                                        {/* <div class="col-4">
                                                            <button onClick={()=>{this.setState({filterable: !filterable})}} class="btn btn-info btn-md">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> <br/>
                                        <div class="table-responsive">
                                                    <ReactTable
                                                        defaultPageSize={5}
                                                        filterable
                                                        data={this.state.data}
                                                        columns={columns} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> <br/>
                    </div>
                </section>:
                <div className='sweet-loading'>
                <ClipLoader
                    className={override}
                    sizeUnit={"px"}
                    size={70}

                    color={'blue'}
                    loading={loading}
                />
                </div>
                }
            </div>
        </div>
        )
    }
}

export default withRouter(
    connect(
        state => {
            const { loading,orders,deliverySuccess,adding} = state.orders;
            const {deliveryMen, user} = state.users;
            const { transactions, updating, canceling} = state.transaction
            const alert  = state.alert;
            return {
                transactions,
                loading,
                updating,
                orders,
                alert,
                user,
                deliveryMen,
                deliverySuccess,
                canceling,
                adding,
                role: localStorage.getItem('trust_user_type')
            }
        },
        dispatch => ({
            getTransactions: (type, history) => dispatch(transactionActions.transaction.getTransactions(type, history)),
            view:(type,history)=>dispatch(orderActions.order.view(type,history)),
            pay: (type, _transaction, alt_phone, history) => dispatch(transactionActions.transaction.makePayment(type, _transaction, alt_phone, history)),
            assignDispatcher:(type,data,history) => dispatch(orderActions.order.assignDispatcher(type,data,history)),
            respond: (type, response, history) => dispatch(transactionActions.transaction.submitResponse(type, response, history)),
            cancelTransaction: (type, response, history) => dispatch(transactionActions.transaction.cancelTransaction(type, response, history)),
            completeOrder: (data) => dispatch(orderActions.order.completeOrder(data)),
            ussdpay : (type, _transaction, alt_phone, history) => dispatch(transactionActions.transaction.makePaymentUSSD(type, _transaction, alt_phone, history))
        })
    )(Transactions)
);
