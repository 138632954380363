import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Admins from '../views/Admins';
import { userActions } from '../actions';

const mapStateToProps = (state) =>{

  const { loading, dashboard, admins} = state.users;

  const alert  = state.alert;
  return {

    alert,
    dashboard,
    loading,
    admins: [],
  };
}

const mapDispatchToProps = (dispatch) =>({
  dispatch,
  getAdmins: (history) => dispatch(userActions.user.getAdmins(history)),
})


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Admins));