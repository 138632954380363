import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import CancelTransaction from './CancelTransaction';
import ModalComponent from '../../views/ModalComponent';
import InfoView from '../../views/InfoView';
import transactionHelper from '../../helpers/transaction';
import { formatPhoneNumber } from 'react-phone-number-input';
import swal from '@sweetalert/with-react';

const summation = (ads) => {
    let _sum = 0;
    ads.map(item => {
        if(item.selected) {
            _sum += item.cost;
        }
    })
    return _sum
}
const formatToSixDigits = (number)=>{
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;
    }
    return output;
}
const sentenceCase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

const toLocaleDisplay = (quantity,curency='NGN') => {
    if(quantity === undefined)
        quantity = 0;
    
    return quantity.toLocaleString(undefined, {style: 'currency', currency: curency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2})
}

const isStatusDone = (status, target, delivery = null) => {
    const statuses = ['Awaiting Agreement', 'Agreed', 'Pending', 'Accepted', 'Rejected', 'Complete'];
    if (target === status ) {
        if (delivery && delivery !== 'rejected') {
            return <span className="text-success">(Done)</span>;
        }
    }
    if (target === 'Rejected' && status === 'Pending' && delivery === 'rejected'){
        return '';
    }

    if(statuses.indexOf(status) < statuses.indexOf(target) && status !== target){
        return <span className="text-success">(Done)</span>;
    }
    return '';
}
const displayCustomer = (text) => {
    if(text === 'customer') {
        return 'Buyer';
    }
    return text;
}

const subTotal = (data) =>{
    let shippingCost=transactionHelper.calculateShipping(data,0)['customer'];
    let netProductCost=parseFloat(data.amount_payed * data.quantity)
    let sub_total=parseFloat((shippingCost+netProductCost)/data.conversion_ratio);
    return sub_total;
}


class StageThree extends Component {

    state = {
        cancel: false,
        deposit: false,
        info: false,
        alt: ''
    }

    handleCancelView = () => {
        this.setState (prevState => (
            {cancel: !prevState.cancel}
        ))
    }
    handleDepositView = () => {
        this.setState (prevState => (
            {deposit: !prevState.deposit}
        ))
    }
    handleInfoView = () => {
        this.setState (prevState => (
            {info: !prevState.info}
        ))
    }
    
    handlePhone = (value) => {
        this.setState({ 'alt': value });
        // console.log("dat", this.state.alt);
    }   
    
    render () {
        const {data, role, terms, user, accounts, handlePhone} = this.props
        const { cancel, alt } = this.state
        const { editTransaction } = this.props
        // console.log("Role Value Before Render Stage Three:", role);
        if (data && data.status.title === 'Canceled') {
            swal("This Transaction has been Canceled for the following Reason:", data.comment, 'error');
        }

        return (
            <div className="content-wrapper" style={{minHeight: 93}}>
                <div className="container"><br/><br/>
                    <ModalComponent pay={() => {
                        this.props.pay(role, data, alt, this.props.history);
                    }} handlePhone={(val) => { 
                        this.handlePhone( val && formatPhoneNumber(val, 'International').replace(/ /g, "") || '')
                    }} role={role} seller_phone={null} user={user} openInfo={this.handleInfoView} open={this.state.deposit} onClose={this.handleDepositView} data={data} terms={data.sellers && data.sellers.delivery_terms && data.sellers.delivery_terms}/>

                    <InfoView open={this.state.info} onClose={this.handleInfoView}/>

                    <div className="row">
                        <div className="col-sm-8">
                            {data.started_by == role && <ul>
                                <li>You have provided the terms of this transaction.</li>
                                <li>
                                    The other party must sign in to AtaraPay and agree to these terms. 
                                    An E-Mail reminder was sent to the other party.
                                </li>
                            </ul>}
                            <div>
                            <div className="row text-center">
                                <div className="btn-group">
                                <div className="col-md-6 mb-2">
                                    <Link to="/app/seller/transactions">
                                        <button className="btn btn-sm btn-success"> <i className="fa fa-list"></i>  My Transactions</button>
                                    </Link>
                                </div>
                                        <div className="col-md-6 mb-2">
                                            <button onClick={this.handleCancelView} disabled={data.status.title === 'Accepted' || data.status.title === 'Rejected' || data.status.title === 'Canceled' || data.status.title === 'Complete' } className="btn btn-sm btn-dark"><i className="fa fa-close"></i>  Cancel Transaction</button>
                                </div>

                                </div>
                                <div className="btn-group">
                                        <div className="col-md-6 mb-2">
                                    <button onClick={() => {editTransaction(data, this.props.history)}} disabled={data.started_by != role || data.status.title === 'Canceled' || data.status.title !== 'Awaiting Agreement'} className="btn btn-sm  btn-info">  <i className="fa fa-edit"></i>  Edit Transaction</button>
                                </div>
                                        <div className="col-md-6 mb-2">
                                    <button disabled={role != 'customer' || data.status.title === 'Canceled' || data.status.title !== 'Agreed'} onClick={this.handleDepositView} className="btn btn-sm btn-warning"><i className="fa fa-money"></i>  Deposit in Escrow</button>
                                </div>
                                </div>
                            </div>
                            </div>
                            <br></br>
                            <div>
                                { cancel ? 
                                <div>
                                        <CancelTransaction cancelTransaction={this.props.cancel} history={this.props.history} data={data} handleCancelView={this.handleCancelView}/>
                                </div>: 
                                <div>
                                    <div className="row">
                                <div className="col-sm-12" style={{margin: '0 !important'}}>
                                    <div className="bg-header bg-callout text-center" style={{padding: 10}}>
                                                    <strong>Transaction Details</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="" style={{padding: '20px 12px', background: 'white', marginBottom: '10px', borderRadius: 4}}>
                                    {/* <div className="card"> */}
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Item Description</th>
                                                    <th className="text-center">Quantity</th>
                                                    <th className="text-right" style={{width: 170}}>Unit Price</th>
                                                    <th className="text-right" style={{width: 120}}>Total Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td className={'text-left'}>
                                                        {data.product_name}<br/>
                                                        <small className={'text-left'}>{data.category}</small><br/>
                                                        <small>{data.desc}</small>
                                                        </td>
                                                    <td className="text-center">{data.quantity}</td>

                                                    <td className="text-right">{toLocaleDisplay((data.amount_payed/data.conversion_ratio),data.currency)}</td>
                                                    <td className="text-right">{toLocaleDisplay(((data.amount_payed * data.quantity)/data.conversion_ratio),data.currency)}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Shipping Cost</strong></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"></td>
                                                    <td className="text-right">
                                                        {toLocaleDisplay((transactionHelper.calculateShipping(data,0)['customer']/data.conversion_ratio),data.currency)}
                                                        {/* {parseFloat(data.shipping_cost).toLocaleString(undefined, {style: 'currency', currency: 'NGN', currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2})} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right">
                                                        Sub Total<br/> <small>Order No: {formatToSixDigits(data.id)}</small></td>
                                                                <td className="text-right">{toLocaleDisplay(subTotal(data),data.currency)}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"><strong>Escrow Fee</strong></td>
                                                    <td className="text-right">{toLocaleDisplay(parseFloat(transactionHelper.calculatePayableEscrow(data)/data.conversion_ratio),data.currency)}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                <td className="text-right"><strong>Payment Switch Fees</strong></td>
                                                <td className="text-right">{toLocaleDisplay(data.tx_fee_bearer !== "seller" ? data.gateway_name === "USSD" ? transactionHelper.calculateUSSDCardFee(data, "customer"): data.currency==='USD'? transactionHelper.calculateUsdCardFee(data, "customer") : transactionHelper.calculateCardFee(data, "customer") : 0,data.currency)}</td>
                                                </tr>
                                               
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right"><strong>Total</strong></td>                                                    
                                                    <td className="text-right">{toLocaleDisplay(eval(parseFloat(transactionHelper.calculateTransactionPayable(data)/data.conversion_ratio) +(data.tx_fee_bearer !== "seller" ?data.gateway_name === "USSD" ?transactionHelper.calculateUSSDCardFee(data, "customer"):data.currency==='USD'?transactionHelper.calculateUsdCardFee(data, "customer"):transactionHelper.calculateCardFee(data, "customer"):0)),data.currency)}</td>
     </tr>
                                                
                                                {data.extra && Object.keys(data.extra).map(item => (
                                                   
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><strong>{item==='current_date'?``: item==='validSec'?``:sentenceCase(item.replace(/_/g, " "))}</strong></td>
                                                        <td className="text-right">{item==='current_date'?``: item==='validSec'?``:data.extra[item].toString().toUpperCase()}</td>
                                                    </tr>
                                                    
                                                ))}
                                            </tbody>
                                            
                                            
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                <div className="bg-header text-center" style={{padding: 10}}>
                                        <strong>Terms</strong>
                                    </div>
                                </div>
                            </div>
                            {(data.sellers && data.sellers.delivery_terms.refund_option===0)?
                            <div className="row">
                                <div className="col-sm-12">
                                <div className="text-center" style={{padding: 10,backgroundColor:'red',color:'#fff'}}>
                                        <strong>Please note that the seller doesn't support refunds. Only REPLACEMENTS are supported.</strong>
                                    </div>
                                </div>
                            </div>: null}
                            <div className="card" style={{padding: 20}}>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Order ID:</strong></div>
                                    <div className="col-6">
                                    {formatToSixDigits(data.id)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Transaction Title:</strong></div>
                                    <div className="col-6 capitalize">
                                        {data.product_name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Buyer:</strong></div>
                                    <div className="col-6">
                                        {data.buyer_email}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Seller:</strong></div>
                                    <div className="col-6">
                                        {data && data.sellers && data.sellers.individual !== 1 ? data && data.sellers && data.sellers.business_email : data.seller_email}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Escrow Fee to be paid by:</strong></div>
                                    <div className="col-6">
                                        <strong>{displayCustomer(data.escrow_fee_bearer === 'both' ? 'Buyer and Seller' : data.escrow_fee_bearer === 'seller' ? 'Seller' : 'Buyer')} ({toLocaleDisplay(parseFloat(transactionHelper.calculatePayableEscrow(data,1)/data.conversion_ratio),data.currency)})</strong> <br/> 
                                        <small>The buyer is responsible for 100% of the escrow fee in the event
                                            the transaction is cancelled or the merchandise is rejected.</small>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Shipping Fee to be paid by:</strong></div>
                                    <div className="col-6">
                                    <strong>{displayCustomer(!data.shipping_method || transactionHelper.calculateShipping(data, 1, 1)[role] === 0? 'None': data.shipping_fee_bearer === 'both' ? 'Buyer and Seller': data.shipping_fee_bearer === 'seller' ? 'Seller' : 'Buyer')} ({toLocaleDisplay(transactionHelper.calculateShipping(data,1,1)[role]/data.conversion_ratio,data.currency)})</strong></div>
                                </div>

                                <div className="row">
                                     <div className="col-6 text-right"><strong>Maximum Delivery Days ({Number(data.max_delivery_days)} {Number(data.max_delivery_days) === 1 ? 'day': 'days'}):</strong></div>
                                    <div className="col-6">
                                        <strong><small>Buyer will be refunded less escrow and other service fees, if the order is not accepted or rejected after this maximum delivery days has elapsed</small></strong></div>
                                </div>
                                            
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Payment Switch Fees:</strong></div>
                                    <div className="col-6">

                                        { data.gateway_name === "USSD" ? <strong>{data.tx_fee_bearer === 'seller' ? 'Seller' : 'Buyer'} ({toLocaleDisplay(transactionHelper.calculateUSSDCardFee(data, "customer"))})</strong> : <strong>{data.tx_fee_bearer === 'seller' ? 'Seller' : 'Buyer'} ({data.currency==='USD'?toLocaleDisplay(transactionHelper.calculateUsdCardFee(data, "customer"),data.currency):toLocaleDisplay(transactionHelper.calculateCardFee(data, "customer"),data.currency)})</strong> }
                                        <br/> <small>The buyer is responsible for 100% of the card and transfer fee in the event
                                the transaction is cancelled or the merchandise is rejected.</small></div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right"><strong>Payment Method:</strong></div>
                                    <div className="col-6">
                                        { data.gateway_name === "USSD" ? <strong>{'Monnify'} </strong>: <strong>{'Paystack'}</strong> }
                                    </div>
                                </div>
                            </div> 
                            <br/><br/>
                                </div>}
                            </div>
                        </div>

                        <div className="col-sm-4">
                        <div className=""><h4><strong>Status</strong></h4> 
                        <p><small>Select any status for help.</small></p>
                            <div>
                            <Link to="/app/p2p/help" className={`black-text status-link ${data.status.title === 'Awaiting Agreement' ? 'text-black': ''}`}     
                               
                            >1. BUYER AND SELLER AGREE TO TERMS {isStatusDone('Awaiting Agreement', data.status.title)} {data.status.title === 'Awaiting Agreement' ? '(Pending)': ''}</Link></div>

                                <div><Link to="/app/p2p/help" className={`status-link ${data.status.title === 'Agreed' ? 'text-black' : ''}`}>2. BUYER MAKES PAYMENT TO ESCROW {isStatusDone('Agreed', data.status.title)} {data.status.title == 'Agreed' ? '(Pending)': ''}</Link></div>

                                <div><Link to="/app/p2p/help" className={`status-link ${!data.delivery_man && data.status.title === 'Pending' ? 'text-black' : ''}`}>3. SELLER SHIPS SERVICE TO BUYER {isStatusDone('Pending', data.status.title, data.delivery_man)} {!data.delivery_man && data.status.title == 'Pending' ? '(Pending)': ''}</Link></div>

                                <div><Link to="/app/p2p/help" className={`status-link ${(data.delivery_man && data.status.title === 'Pending') || data.status.title === 'Rejected' ? 'text-black' : ''}`}>4. BUYER INSPECTS SERVICE {isStatusDone('Pending', data.status.title, 'rejected')} {(data.delivery_man && data.status.title === 'Pending') || data.status.title === 'Rejected' ? '(Pending)': ''}</Link></div>

                                <div><Link to="/app/p2p/help" className={`status-link ${data.status.title === 'Accepted' && data.delivery_man ? 'text-black' : ''}`}>5. ATARAPAY PAYS SELLER {isStatusDone('Complete', data.status.title, data.delivery_man)} {data.status.title === 'Accepted' && data.delivery_man ? '(Pending)': ''}</Link></div></div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default StageThree;