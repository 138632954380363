import React, {Component} from 'react';
import Modal from "react-responsive-modal";
import {BASE_URL, USER_ROLE_MARKET} from "../constants";
import Select from 'react-select';
import * as axios from "axios";
import swal from "sweetalert";
import ReactTable from "react-table";
import {ClipLoader} from "react-spinners";

class Sellers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sellers: [],
      mo: [],
      processing: false,
      associateSeller: false,

      selectedMo: {label: "", value: ""},
      selectedSeller: {label: "", value: ""}
    }
  }

  componentDidMount() {

    this.loadMo()
    this.loadSellers()
  }

  loadSellers = () => {

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/sellers`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         console.log(res.data)
         if (res.data) {
           console.log(res.data)
           this.setState({
             data: res.data.data,
             dataOriginal: res.data.data,
             sellers: res.data.data.map(item => {

               let name = item.business_name !== null ? item.business_name + ` (${item.users ? item.users.phone_number : ''})` : (
                  item.users ? item.users.firstname + ` (${item.users ? item.users.phone_number : ''})`
                     : `N/A (${item.users ? item.users.phone_number : ''})`
               );

               return {
                 value: item.id, label: name,
               }
             }),
           })
         }

       })
       .catch(err => {
         console.log(err)
       })
  }

  loadMo = () => {

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/mo`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         if (res.data) {
           this.setState({
             mo: res.data.data.map(item => ({
               value: item.id, label: item.firstname + ' ' + item.lastname + ` (${item.phone_number})`
             })),
           })
         }

       })
       .catch(err => {
         console.log(err)
       })
  }

  createAssociation = () => {

    this.setState({
      processing: true,
    })

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/associate_seller_to_marketplace/${this.state.selectedSeller.value}/${this.state.selectedMo.value}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         if (res.data.status === 'success') {
           this.setState({});

           swal({
             title: 'Success',
             text: 'Association Created',
           })
         }
         else{
           if(res.data.error == 'association_exists'){
             swal({
               icon: 'error',
               title: 'Error',
               text: 'Association already exists',
             })
           }
         }

       })
       .catch(err => {
         console.log(err)
       })
       .finally(() => {
         this.setState({
           processing: false,
         })
       })

  }

  getUser = (e, userId) => {
    e.preventDefault();
    console.log(userId);

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
        .then(({data}) => {
          this.setState({
            user: data.data,
            showUser: true,
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  }

  getUser = (e, userId) => {
    e.preventDefault();
    console.log(userId);

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
        .then(({data}) => {
          this.setState({
            user: data.data,
            showUser: true,
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  }

  // getUser

  handleFilter = (e) => {
    const {value} = e.target;
    this.setState({filter: value}, () => this.filterTable(this.state.filter));
    // this.filterTable();

  }


  filterTable = (filter) => {
    var data = this.state.dataOriginal;
    var filteredRows = [];
    // this.setState({data:[...this.state.deliveryMen]});
    if (filter !== "") {
      for (var i = 0; i < data.length; i++) {

        if (data[i].id === parseInt(filter) ||
            (data[i].created_at && data[i].created_at.includes(filter)) ||
            (data[i].users && data[i].users.firstname.toUpperCase().includes(filter.toUpperCase())) ||
            (data[i].users && data[i].users.lastname.toUpperCase().includes(filter.toUpperCase())) ||
            (data[i].marketplaceuser && data[i].marketplaceuser.firstname.toUpperCase().includes(filter.toUpperCase())) ||
            (data[i].marketplaceuser && data[i].marketplaceuser.lastname.toUpperCase().includes(filter.toUpperCase())) ||
            (data[i].users && data[i].users.phone_number.toString().includes(filter))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({data: [...filteredRows]});
    } else {
      this.setState({data: this.state.dataOriginal});
    }

  }

  render() {

    const columns = [
      {
        Header: props => <span><b>User ID</b></span>,
        id: "id",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.id,
        Cell: ({original: props}) => {
          return <span>{props.id}</span>;
        }
      },
      {
        Header: props => <span><b>Seller Name</b></span>,
        id: "seller_name",
        filterable: false,
        accessor: d => d.users ? d.users.firstname : null,
        Cell: ({original: props}) => {
          const user = props.users;
          return <span>{user ? <a href="#" onClick={e => this.getUser(e, user.id)}>{user.firstname + ' ' + user.lastname}</a> : 'N/A'}</span>;
        }
      },
      {
        Header: props => <span><b>Phone Number</b></span>,
        id: "phone_number",
        filterable: false,
        accessor: d => d.users ? d.users.phone_number : null,
        Cell: ({original: props}) => {
          const user = props.users;
          return <span>{user ? (user.phone_number || "N/A") : 'N/A'}</span>;
        }
      },
      {
        Header: props => <span><b>Associated Marketplace</b></span>,
        id: "marketplaceuser",
        filterable: false,
        accessor: d => d.marketplaceuser ? d.marketplaceuser.firstname : null,
        Cell: ({original: props}) => {
          return <span>{props.marketplaceuser ? (props.marketplaceuser.firstname + ' '  + props.marketplaceuser.lastname) : 'N/A'}</span>;
        }
      },
    ]
    return (
       <div className="content-wrapper" style={{minHeight: 137}}>
         <Modal open={this.state.showUser} onClose={() => this.setState({showUser: false})}>
           {
             this.state.user ?
                 <form>
                   <div className="modal-header">
                     <h4 className="modal-title"><small></small></h4>
                   </div>

                   <div className="modal-body">
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>First Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.firstname}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Last Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.lastname}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Phone No.</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.phone_number || "N/A"}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Email</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.email || "N/A"}
                             className="form-control"/></div>
                       </div>
                     </div>
                     {
                       this.state.user.seller &&
                       <>
                         <div className="row">
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Name</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_name || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Email</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_email || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Address</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_address || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Address 2</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_address_2 || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business City</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_city || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business State</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_state || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Country</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_country || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Website</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.siteURL || "N/A"}
                                 className="form-control"/></div>
                           </div>
                         </div>
                       </>
                     }
                     <br/><br/>
                   </div>
                 </form> :
                 <ClipLoader
                     loading={true}
                 />
           }
         </Modal>
         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">
               <div className="col-sm-6">
                 <h1 className="m-0 text-dark">Sellers</h1><br/>
               </div>
             </div>
           </div>
         </div>
         <Modal open={this.state.associateSeller} onClose={() => this.setState({associateSeller: false})} center>

           <div className="modal-header">
             <h4 className="modal-title">Associate Seller</h4>
           </div>
           <div className="modal-body">
             <div className="row">
               <div className="col-sm-12">
                 <form action="">
                   <div className="form-group">
                     <label htmlFor="">Seller</label>

                     <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={this.state.sellers.length > 0 ? this.state.sellers[0] : null}
                        isSearchable={true}
                        name="color"
                        options={this.state.sellers}
                        onChange={value => this.setState({selectedSeller: value})}

                        value={this.state.selectedSeller}
                     />
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Marketplace Operator</label>

                     <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={this.state.sellers.length > 0 ? this.state.sellers[0] : null}
                        isSearchable={true}
                        name="color"
                        options={this.state.mo}
                        value={this.state.selectedMo}
                        onChange={value => this.setState({selectedMo: value})}
                     />
                   </div>
                 </form>
               </div>
             </div>
           </div>
           <div className="modal-footer">
             <button type="button" onClick={() => this.setState({associateSeller: false,})} data-dismiss="modal"
                     className="btn btn-danger">Cancel
             </button>
             &nbsp;&nbsp;
             <button onClick={this.createAssociation} disabled={this.state.processing} type="button"
                     className="btn btn-primary">{this.state.processing ? 'Working..' : 'Associate'}</button>
           </div>

         </Modal>

         <section className="content">
           <div className="container-fluid">
             <div className={'row'}>
               <div className="col-md-12">
                 <button className="btn-primary btn" onClick={() => this.setState({associateSeller: true})}>
                   Associate Seller to Marketplace Operator
                 </button>
               </div>
             </div>
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-md-4">
                         <div className="form-group"><label htmlFor="filters">Filter</label>
                           <input type="text"
                                  name={'filters'}
                                  className={'form-control'}
                                  onChange={this.handleFilter}
                           />
                         </div>
                       </div>
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date From</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="from_date"*/}
                       {/*       value={this.state.from_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date To</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="to_date"*/}
                       {/*       value={this.state.to_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}

                     </div>
                     <br/>
                     <div className="table-responsive">
                       <ReactTable
                           loading={this.state.loading}
                           defaultPageSize={5}
                           filterable
                           data={this.state.data}
                           columns={columns}/>

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default Sellers;