import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
const DisplayValueSpanBox = styled.span`
  ${({ isFee }) => {
    if (isFee) {
      return css`
        color: #f23b3b;
      `;
    }
  }}
`;
export const EM_DASH = "-";
const NumberTextBox = ({ number, dp, isFee, isDollar, autoDetectFee }) => {
  let displayValue = EM_DASH;
  const floatCastedNumber =
    typeof number === "string" ? parseFloat(number) : number;

  if (floatCastedNumber && !isNaN(floatCastedNumber)) {
    displayValue = parseFloat(
      (floatCastedNumber / (isDollar ? 1 : 100)).toFixed(dp)
    ).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  if (
    (isFee && displayValue !== EM_DASH) ||
    (autoDetectFee && floatCastedNumber < 0)
  ) {
    displayValue = `( ${displayValue} )`;
  }
  return (
    <DisplayValueSpanBox
      isFee={isFee || (autoDetectFee && floatCastedNumber < 0)}
    >
      {displayValue}
    </DisplayValueSpanBox>
  );
};
NumberTextBox.propTypes = {
  dp: PropTypes.number,
  number: PropTypes.number,
  isFee: PropTypes.bool,
  isDollar: PropTypes.bool,
  autoDetectFee: PropTypes.bool,
};
NumberTextBox.defaultProps = {
  dp: 2,
  autoDetectFee: false,
};
export default NumberTextBox;
