import { alertConstants } from '../constants';



const success = (message) => {
    
    return { type: alertConstants.alert.SUCCESS, message: message.replace(/_/g, " ").replace(/\\n/g, "") };
}

const error=(message)=>{
    return { type: alertConstants.alert.ERROR, message: message.replace(/_/g, " ").replace(/\\n/g, "") };
}

const clear=()=> {
    return { type: alertConstants.alert.CLEAR };
}
export  const alert= {
    success,
    error,
    clear
};  