import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from 'react-responsive-modal';
import * as moment from 'moment';
import {alertActions} from '../actions'
import swal from '@sweetalert/with-react';
import matchSorter from 'match-sorter'

import {jsx, css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';
import transactionHelper from '../helpers/transaction';
import * as axios from "axios";
import {BASE_URL} from "../constants";

const override = css`
display: block;
margin-top:10%;
margin-left:40%;
margin-right:60%;
border-color: red;
`;

const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class Order extends Component {


  state = {
    loading: false,

    filterable: false,
    notCustom: true,
    period: "All",
    assignModal: false,
    fullModal: false,
    from_date: "",
    filter: "",
    to_date: "",
    cancel: false,
    data: [],
    order: "",
    checkorders: [],
    fields: {
      order_id: 0,
      delivery_man_id: 0
    },
    orders: [],
    role: 'seller',
    reason: '',
    cancelOrder: false,
    completeDelivery: true,
    terms: [],
    current_date: '',
    validSec: 300
  }

  componentDidMount() {

    // if (localStorage.getItem("trust_user_type") === 'seller')
    //   this.setState({role: 'seller'});
    // else
    //   this.setState({role: 'buyer'});

    /*this.setState({
        current_date:new Date('2020-05-21 08:35:37'),
        validSec:300
      });*/
    //console.log('OrderProps',this.props.orders);

    this.getOrders()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('updated')
  }

  getOrders = () => {

    this.setState({
      loading: true,
    })

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/orders`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         this.setState({
           data: res.data.data,
           orders: res.data.data,
         })
       })
       .catch(err => {
         // console.log(err)
         console.log(err.response);
       })
       .finally(() => {
         this.setState({
           loading: false,
         })
       })
  }

  // componentWillReceiveProps(props) {
  //   console.log(this.state.role);
  //   if (props.alert && props.alert.message && props.alert.type === "alert-danger") {
  //     swal("Error", props.alert.message, "error").then(() => {
  //       props.dispatch(alertActions.alert.clear());
  //     });
  //   }
  //
  //   if (props.alert && props.alert.message && props.alert.type === "alert-success") {
  //     swal("Success", props.alert.message, "success").then(() => {
  //       props.dispatch(alertActions.alert.clear());
  //       if (this.state.role === 'seller') {
  //         this.props.view("seller", this.props.history);
  //       } else {
  //         this.props.view("customer", this.props.history);
  //       }
  //
  //     });
  //     this.onCloseModal('assignModal');
  //     this.onCloseModal('cancel');
  //     this.onCloseModal('cancelOrder');
  //     this.setState({reason: '', order: ""})
  //     // this.onCloseModal('delete');
  //   }
  //
  // }

  handleCancel = (e) => {
    e.preventDefault();
    const {order} = this.state;
    this.props.cancel(localStorage.getItem("trust_user_type"), {dispute_id: order.id}, this.props.history);

  }
  handleSelect = (e) => {
    const {name, value} = e.target;
    if (value == "Custom") {
      this.setState({notCustom: !this.state.notCustom})
    }
    this.filterPeriod(value);
    this.setState({period: value});
  }


  filterPeriod = (value) => {
    const data = this.state.orders;
    if (value == "All") {
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.state.orders]});
    }

    if (value == "This Week") {
      var ordersThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].transaction_date);
        var isThisWeek = input.isSame(now, "week");

        if (isThisWeek) {
          ordersThisWeek.push(data[i]);
        }
      }

      this.table_rows = ordersThisWeek;
      this.notCustom = true;
      this.page = 1;
      console.log(this.table_rows);
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value == "This Month") {
      var ordersThisMonth = [];

      for (var i = 0; i < data.length; i++) {
        //var now = moment();
        var input = moment(data[i].transaction_date);
        var isThisMonth = input.isSame(new Date(), 'month');

        if (isThisMonth) {
          ordersThisMonth.push(data[i]);
        }
      }

      this.table_rows = ordersThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value == "60 Days Ago") {
      var ordersSixtyDaysAgo = [];

      for (var i = 0; i < data.length; i++) {

        var sixty_days_ago = moment().subtract(60, 'days');

        var isBetweenSixtyDays = moment(data[i].transaction_date).isAfter(sixty_days_ago);

        if (isBetweenSixtyDays) {
          ordersSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value == "90 Days Ago") {
      var ordersNinetyDaysAgo = [];

      for (var i = 0; i < data.length; i++) {

        var ninety_days_ago = moment().subtract(90, 'days');

        var isBetweenNinetyDays = moment(data[i].transaction_date).isAfter(ninety_days_ago);

        if (isBetweenNinetyDays) {
          ordersNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value == "Custom") {
      this.table_rows = data;
      this.setState({to_date: "", from_date: ""});
      //   this.state.notCustom =  !this.state.notCustom;
      //   return this.table_rows;

    }
  }

  filterDates = (data) => {

    if (this.state.from_date == "") {
      swal("Error", "Please select a From date", "error");
      return this.state.orders;
    }
    if (this.state.to_date == "") {
      swal("Error", "Please select a To date", "error");
      return this.state.orders;
    }

    if (moment(this.state.from_date).isAfter(this.state.to_date)) {
      swal("Error", "Please select a From date that is before your To date", "error");
      return this.state.orders;
    }

    console.log(this.state.from_date);
    console.log(this.state.to_date);

    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].transaction_date).isBetween(this.state.from_date, this.state.to_date); // true
      console.log(isBetweenDates);
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    this.page = 1;
    console.log(this.table_rows);
    return this.table_rows;
  }


  handleDate = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value});

  }
  handleDispatch = (e) => {
    const {name, value} = e.target;
    const fields = {...this.state.fields, delivery_man_id: value}
    this.setState({fields});
  }
  assignDispatcher = (e) => {
    e.preventDefault();
    const {fields, order} = this.state;
    const data = {"order_id": order.id, "delivery_man_id": fields.delivery_man_id};
    this.props.assignDispatcher("seller", data, this.props.history);

    this.setState({data: [this.state.orders]});
  }

  filterTable = (filter) => {
    var data = this.state.orders;
    var filteredRows = [];
    // this.setState({data:[...this.state.deliveryMen]});
    if (filter != "") {
      for (var i = 0; i < data.length; i++) {
        if (
           formatToSixDigits(data[i].id).match(new RegExp(filter, "gi")) ||
           (data[i].customers && (data[i].customers.firstname + " " + data[i].customers.lastname).toUpperCase().match(filter.toUpperCase(), "gi")) ||
           // (data[i].customers && data[i].customers.lastname && data[i].customers.lastname.toUpperCase().match(filter.toUpperCase(), "gi")) ||
           // (data[i].customers && data[i].customers.firstname && data[i].customers.firstname.toUpperCase().match(new RegExp(filter.toUpperCase(), "gi"))) ||
           (data[i].sellers && data[i].sellers.business_name && data[i].sellers.business_name.toUpperCase().match(new RegExp(filter.toUpperCase(), "gi"))) ||


           (data[i].sellers && data[i].sellers.marketplaceuser && (data[i].sellers.marketplaceuser.firstname + " " + data[i].sellers.marketplaceuser.lastname).toUpperCase().match(new RegExp(filter.toUpperCase(), "gi"))) ||
           (data[i].transaction_date && data[i].transaction_date.match(new RegExp(filter, "gi"))) ||
           (data[i].status && data[i].status.title.match(new RegExp(filter, "gi")))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({data: [...filteredRows]});
    } else {
      this.setState({data: [...this.state.orders]});
    }

  }

  handleFilter = (e) => {
    const {value} = e.target;
    this.setState({filter: value}, () => this.filterTable(this.state.filter));
    // this.filterTable();

  }


  onOpenModal = (name, order) => {
    this.setState({[name]: true});
    this.setState({order: order});

  };

  onCloseModal = (name) => {
    this.setState({[name]: false});
    this.setState({order: "", reason: ""});
  };

  formatAmount = (amount) => {
    let a = amount + '';
    let naira = a.slice(0, -3);
    let kobo = a.substr(a.length - 2);
    return naira + "." + kobo;
  }

  handleChange = (e) => {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  cancelTransaction = () => {
    console.log(this.state.role, 'Role');
    this.props.cancelTransaction(this.state.role, {
      order_id: this.state.order.id,
      comment: this.state.reason
    }, this.props.history)
  }

  confirmModals = (e) => {
    const phone = this.state.order.customers.phone_number
    const email = this.state.order.customers.email
    const orderId = formatToSixDigits(this.state.order.id)
    let data = {"order": orderId, "phone_number": phone, "email": email};
    this.props.completeOrder(data)
    this.props.dispatch(alertActions.alert.success(("Thank you. Buyer has been notified to accept or reject your delivery.")));
    this.onCloseModal("confirmModal")
  }

  checkDeliveryTerm = (is_refundable, transaction_date, current_date, validSec) => {
    console.log('is_refundable:', is_refundable);
    console.log('transaction date :', transaction_date);
    console.log('current_date  :', current_date);
    let date1 = new Date(transaction_date.replace(/-/g, "/"));
    let date2 = new Date(current_date.replace(/-/g, "/"));
    let SecDiff = (date2.getTime() - date1.getTime()) / 1000;
    console.log('transaction_date Sec Diff', SecDiff);
    if (is_refundable === 0) {
      if (SecDiff > validSec)
        return false;
    }
    return true;
  }


  render() {
    const {orders, deliveryMen, alert, canceling, canceling2, user, adding} = this.props;
    const {loading} = this.state;

    const {filterable, role, completeDelivery} = this.state
    //   var data = [];
    //   orders ? data = orders  : data = [];
    var columns = [];

    var i = 1;
    columns = [
      {
        Header: props => <span><b>Order ID</b></span>,
        id: "id",
        filterable,
        accessor: d => d,// String-based value accessors!

        filterAll: true,
        Cell: props => <a href="#/app/orders"
                          onClick={() => this.onOpenModal("fullModal", props.value)}>{formatToSixDigits(props.value.id)}</a>
        ,
        filterMethod: (filter, rows) =>
           matchSorter(rows, filter.value, {keys: ["id"]}),
      },
      {
        Header: props => <span><b>Service Type</b></span>,
        id: "ecommerce",
        filterable,
        accessor: d => d.type || null,// String-based value accessors!

        filterAll: true,
        Cell: props => <span>
                  {props.original.type == 1 ? 'eCommerce' : props.original.type == 2 ? 'P2P' : 'Other'}
                </span>
        ,
        filterMethod: (filter, rows) =>
           matchSorter(rows, filter.value, {keys: ["id"]}),
      }, {
        Header: props => <span><b>Buyer</b></span>,
        id: "firstname",
        filterable,
        accessor: d => d.customers,
        Cell: props => (<span
              className='number'>{props.value == null ? '' : props.value.firstname}<br/>{props.value == null ? '' : props.value.lastname}</span> // Custom cell components!
        )
      }, {
        id: 'seller',
        sortable: true,
        filterable,
        Header: props => <span><b>Seller</b></span>,
        accessor: 'sellers', // String-based value accessors!
        className: 'text-left',
        Cell: props => <div>
          {props.original.user ? props.original.user.firstname + ' ' + props.original.user.lastname :
             props.original.sellers ? props.original.sellers.business_name : 'N/A'
          }
        </div>
      },

      {
        id: 'transaction_date', // Required because our accessor is not a string
        Header: props => <span><b>Order Date</b></span>,
        filterable,
        accessor: 'transaction_date', // Custom value accessors!

      },
      {
        id: "value",
        filterable,
        Header: props => <span><b>Order Status </b></span>,
        accessor: d => d.status.title, // String-based value accessors!
        Cell: props => <div>
          {props.value === "Dispute" ? (
             <span class="badge badge-danger">Dispute</span>) : props.value === "Disputed" ? (
             <span class="badge badge-danger">Disputed</span>) : props.value === "Canceled" ? (
             <span class="badge badge-danger">Canceled</span>) : props.value === "Rejected" ? (
             <span class="badge badge-warning">Rejected</span>) : props.value === "Pending" ? (
             <span class="badge badge-warning">Pending</span>) : props.value === "Pending II" ? (
             <span class="badge badge-warning">Pending II</span>) : props.value === "Pending Buyer Response" ? (
             <span class="badge badge-warning">Pending Buyer Response</span>) : props.value === "Awaiting Agreement" ? (
             <span class="badge badge-warning">Awaiting Agreement</span>) : (
             <span class="badge badge-success">{props.value}</span>)}
        </div>
      }, {
        id: 'payment',
        sortable: true,
        filterable,
        Header: props => <span><b>Payment Status</b></span>,
        accessor: 'payment', // String-based value accessors!
        className: 'text-left',
        Cell: props => <div>
          {props.value ? (<span class="badge badge-success">Paid</span>) : (
             <span class="badge badge-danger">Not Paid</span>)}
        </div>
      }, {
        id: 'marketplace',
        sortable: true,
        filterable,
        Header: props => <span><b>Marketplace</b></span>,
        accessor: 'marketplace', // String-based value accessors!
        className: 'text-left',
        Cell: props => <div>
          {
            props.original.sellers &&

            props.original.sellers.marketplaceuser ? props.original.sellers.marketplaceuser.firstname + ' ' + props.original.sellers.marketplaceuser.lastname : 'N/A'}
        </div>
      }
    ]

    return (

       <div>
         <Modal open={this.state.fullModal} onClose={() => this.onCloseModal("fullModal")} center>
           <form>
             <div class="modal-header">
               <h4 class="modal-title"><small></small></h4>
             </div>
             <div class="modal-body">
               <div class="row"></div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Order Number</label> <br/> <input type="text" readonly="readOnly"
                                                                                    value={formatToSixDigits(this.state.order && this.state.order.id)}
                                                                                    class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Amount</label> <br/> <input type="text" readonly="readOnly"
                                                                              value={this.state.order && transactionHelper.toLocaleDisplay(parseFloat(this.state.order.amount_payed / 100))}
                                                                              class="form-control"/></div>

                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Order Date</label> <br/> <input type="text" readonly="readOnly"
                                                                                  value={this.state.order && this.state.order.created_at}
                                                                                  class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Order Status</label> <br/> <input type="text" readonly="readOnly"
                                                                                    value={this.state.order && this.state.order.status.title}
                                                                                    class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Buyer Name</label> <br/> <input type="text" readonly="readOnly"
                                                                                  value={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname}
                                                                                  class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Delivery Location</label> <br/> <input type="text" readonly="readOnly"
                                                                                         value={this.state.order && this.state.order.delivery_location}
                                                                                         class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Delivery Date</label> <br/> <input type="text" readonly="readOnly"
                                                                                     value={this.state.order && this.state.order.delivery_date}
                                                                                     class="form-control"/></div>
                 </div>
               </div>
               <br/><br/>
             </div>
           </form>
         </Modal>

         <div class="content-wrapper" style={{minHeight: 136}}>
           <div class="content-header">
             <div class="container-fluid">
               <div class="row mb-2">
                 <div class="col-sm-12">
                   <h1 class="m-0 text-dark">All Orders</h1>
                   <br/>

                 </div>
               </div>
             </div>
           </div>
           {!loading ?
              <section class="content">
                <div class="container-fluid"><br/>
                  <div class="row ">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="alert animated bounceIn alert-danger d-none search-alert">
                        <a aria-label="close" className="close alert-close">×</a>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          {/* <div class="row">
                                    <div class="col-12 col-sm-3">
                                                    <div class="form-group"><label for="filter">Filter</label><input type="text" placeholder="Filter" class="form-control" value={this.state.filter} onChange={this.handleFilter} /></div>
                                             </div>         
                                                    </div> */}

                          <div class="row">
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label for="period">Period</label>
                                <select class="form-control" name="filter" onChange={this.handleSelect}>
                                  <option value="All">All</option>
                                  <option value="This Week">This Week</option>
                                  <option value="This Month">This Month</option>
                                  <option value="60 Days Ago">60 Days Ago</option>
                                  <option value="90 Days Ago">90 Days Ago</option>
                                  <option value="Custom">Custom</option>
                                </select></div>
                            </div>
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label for="filter">Filter</label><input type="text"
                                                                                               placeholder="Filter"
                                                                                               class="form-control"
                                                                                               value={this.state.filter}
                                                                                               onChange={this.handleFilter}/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-3">
                              <div class="form-group"><label for="from">Date From</label> <input type="date"
                                                                                                 name="from_date"
                                                                                                 value={this.state.from_date}
                                                                                                 disabled={this.state.notCustom}
                                                                                                 onChange={this.handleDate}
                                                                                                 class="form-control"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-3">
                              <div class="form-group"><label for="from">Date To</label> <input type="date"
                                                                                               name="to_date"
                                                                                               value={this.state.to_date}
                                                                                               disabled={this.state.notCustom}
                                                                                               onChange={this.handleDate}
                                                                                               class="form-control"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label>&nbsp;</label>
                                <div class="row">
                                  <div class="col-12">
                                    <button disabled={this.state.notCustom} onClick={() => {
                                      this.setState({data: [...this.filterDates(this.state.orders)]})
                                    }} class="btn btn-info btn-block form-control">
                                      <center>Search</center>
                                    </button>
                                  </div>
                                  {/* <div class="col-4">
                                                            <button onClick={()=>{this.setState({filterable: !filterable})}} class="btn btn-info btn-md">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <div class="table-responsive">
                            <ReactTable
                               defaultPageSize={5}
                               filterable
                               data={this.state.data.sort(function (a, b) {
                                 var c = new Date(a.transaction_date);
                                 var d = new Date(b.transaction_date);
                                 return d - c;
                               })}
                               columns={columns}/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                </div>
              </section> :
              <div className='sweet-loading'>
                <ClipLoader
                   className={override}
                   sizeUnit={"px"}
                   size={70}

                   color={'blue'}
                   loading={loading}
                />
              </div>
           }
         </div>
       </div>
    )
  }
}

export default Order;