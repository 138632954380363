import { BASE_URL } from '../constants';
import axios from 'axios';

const getTransactions = (type) => {
	return axios({
		method: 'get',
		mode: 'no-cors',
		url: `${BASE_URL}${type}/transaction/view`,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}

const createTransaction = (type, transaction) => {
	return axios({
		method: 'post',
		mode: 'no-cors',
		url: `${BASE_URL}${type}/transaction/create`,
		data: transaction,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}

const updateTransaction = (type, transaction) => {
	console.log(transaction);
	return axios({
		method: 'put',
		mode: 'no-cors',
		url: `${BASE_URL}${type}/transaction/update/${transaction.id}`,
		data: transaction,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}
const cancelTransaction = (type, response) => {
	return axios({
		method: 'put',
		mode: 'no-cors',
		url: `${BASE_URL}${type}/transaction/cancel/${response.order_id}`,
		data: response,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}
const submitResponse = (type, response) => {
	return axios({
		method: 'post',
		mode: 'no-cors',
		url: `${BASE_URL}${type}/transaction/respond`,
		data: response,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}

const makePayment = (type, transaction, alt) => {
	return axios({
		method: 'post',
		mode: 'no-cors',
		url: `${BASE_URL}customer/transaction/payment`,
		data: { order_id: transaction.id, alt: alt },
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}
const makePaymentUSSD = (type, transaction, alt) => {
	return axios({
		method: 'post',
		mode: 'no-cors',
		url: `${BASE_URL}customer/transaction/ussdpayment`,
		data: { order_id: transaction.id, alt: alt },
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Authorization': 'Bearer ' + localStorage.getItem("user"),
			'Content-Type': 'application/json',
		}
	})
}
const checkUser = (role, data) => {
	return axios({
		method: 'post',
		mode: 'no-cors',
		url: `${BASE_URL}${role}/check`,
		data: data,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		}
	})
}


export const transaction = {
	getTransactions,
	createTransaction,
	cancelTransaction,
	updateTransaction,
	submitResponse,
	makePayment,
	makePaymentUSSD,
	checkUser
};


