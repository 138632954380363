import { userConstants, deliveryConstants} from '../constants';

export const users = (state = { deliveryMen: []}, action)=>{
  switch (action.type) {
    case userConstants.user.LOGOUT:
      return {
        
      };
    
    case userConstants.user.GET_VERIFY_REQUEST:
      return {
        ...state, msg: action.msg, loading: true
      };
    case userConstants.user.GET_VERIFY_SUCCESS:
      return {
        ...state, msg: action.msg, loading: false
      };
    case userConstants.user.GET_VERIFY_FAILURE:
      return {
        ...state, msg: action.error, loading: false
      };
    case userConstants.user.RESEND_EMAIL_REQUEST:
      return {
        ...state, msg: action.data, loading: true
      };
    case userConstants.user.RESEND_EMAIL_SUCCESS:
      return {
        ...state, msg: action.msg, loading: false
      };
    case userConstants.user.RESEND_EMAIL_FAILURE:
      return {
        ...state, msg: action.error, loading: false
      };
    case userConstants.user.GETALL_REQUEST:
      return {
        ...state,loading: true
      };
    case userConstants.user.GETALL_SUCCESS:
      return {
        ...state,user: action.user,loading:false
      };
    case userConstants.user.GETALL_FAILURE:
      return { 
       ...state ,error: action.error ,loading:false
      };
      case userConstants.user.UPDATE_PROFILE:
      return {
        ...state,updating:true
      };
    case userConstants.user.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,success:true,updating:false,user:{...state.user,...action.user}
      };
    case userConstants.user.UPDATE_PROFILE_FAILURE:
      return { 
       ...state ,error: action.error,success:false,updating:false
      };
      case userConstants.user.PASSWORD_CHANGE_REQUEST:
      return {
        ...state,changing:true
      };
    case userConstants.user.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,success:true,changing:false
      };
    case userConstants.user.PASSWORD_CHANGE_FAILURE:
      return { 
       ...state ,error: action.error,success:false,changing:false
      };
      case userConstants.user.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,sending:true
      };
    case userConstants.user.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,success:true,sending:false
      };
    case userConstants.user.FORGOT_PASSWORD_FAILURE:
      return { 
       ...state ,error: action.error,success:false,sending:false
      };
      case userConstants.user.RESET_PASSWORD_REQUEST:
      return {
        ...state,resetting:true
      };
    case userConstants.user.RESET_PASSWORD_SUCCESS:
      return {
        ...state,success:true,resetting:false
      };
    case userConstants.user.RESET_PASSWORD_FAILURE:
      return { 
       ...state ,error: action.error,success:false,resetting:false
      };
      case userConstants.user.GET_KEY_REQUEST:
      return {
       ...state, loading: true
      };
    case userConstants.user.GET_KEY_SUCCESS:
      return {
        ...state,key: action.key,loading:false
      };
    case userConstants.user.GET_KEY_FAILURE:
      return { 
       ...state ,error: action.error ,loading:false
      };
      case userConstants.user.GEN_KEY_REQUEST:
      return {
        ...state,loading: true
      };
    case userConstants.user.GEN_KEY_SUCCESS:
      return {
        ...state,key: action.key,loading:false
      };
    case userConstants.user.GEN_KEY_FAILURE:
      return { 
       ...state ,error: action.error ,loading:false
      };

    case userConstants.user.GO_LIVE_REQUEST:
      return {
        ...state, goingLive: true
      };
    case userConstants.user.GO_LIVE_SUCCESS:
      return {
        ...state, key: action.key, goingLive: false
      };
    case userConstants.user.GO_LIVE_FAILURE:
      return {
        ...state, error: action.error, goingLive: false
      };

      case deliveryConstants.delivery.GET_DELIVERY_MEN:
      return {
       ...state,loading: true
      };
    case deliveryConstants.delivery.GET_DELIVERY_MEN_SUCCESS:
      return {
        ...state,deliveryMen: action.deliveryMen,loading:false
      };
    case deliveryConstants.delivery.GET_DELIVERY_MEN_FAILURE:
      return { 
       ...state ,error: action.error ,loading:false
      };
      case deliveryConstants.delivery.GET_DELIVERY_MAN_TYPE:
      return {
       ...state,loading: true
      };
    case deliveryConstants.delivery.GET_DELIVERY_MAN_TYPE_SUCCESS:
      return {
        ...state,deliveryManType: action.deliveryManType,loading:false
      };
    case deliveryConstants.delivery.GET_DELIVERY_MAN_TYPE_FAILURE:
      return { 
       ...state ,error: action.error ,loading:false
      };
      case deliveryConstants.delivery.GET_COURIERS_REQUEST:
      return {
       ...state,loading: true
      };
    case deliveryConstants.delivery.GET_COURIERS_SUCCESS:
      return {
        ...state,couriers: action.couriers,loading:false
      };
    case deliveryConstants.delivery.GET_COURIERS_FAILURE:
      return { 
       ...state ,error: action.error ,loading:false
      };


    case deliveryConstants.delivery.ADD_COURIER_REQUEST:
      return {
       ...state,adding: true
      };
    case deliveryConstants.delivery.ADD_COURIER_SUCCESS:
      return {
        ...state,couriers:[...state.couriers,action.couriers],adding:false,success:true
      };
    case deliveryConstants.delivery.ADD_COURIER_FAILURE:
      return { 
       ...state ,error: action.error ,adding:false,success:false
      };


    case deliveryConstants.delivery.ADD_DELIVERY_MAN:
      return {
       ...state,adding: true
      };
    case deliveryConstants.delivery.ADD_DELIVERY_MAN_SUCCESS:
      if(state.deliveryMen){
        return {

          ...state, deliveryMen: [...state.deliveryMen, action.deliveryMen], adding: false, success: true
        };
      }
      return {

        ...state, deliveryMen: [action.deliveryMen], adding: false, success: true
      };
        

    case deliveryConstants.delivery.ADD_DELIVERY_MAN_FAILURE:
      return { 
       ...state ,error: action.error ,adding:false,success:false
      };

    case userConstants.user.DASHBOARD_DETAILS_REQUEST:
      return { ...state, loading: true };
    case userConstants.user.DASHBOARD_DETAILS_SUCCESS:
      return { ...state, dashboard: action.dashboard, loading: false };
    case userConstants.user.DASHBOARD_DETAILS_FAILURE:
      return { ...state, error: action.error, loading: false };


    case deliveryConstants.delivery.DELETE_COURIER_REQUEST:
      return {
       ...state,deleting: true
      };
    case deliveryConstants.delivery.DELETE_COURIER_SUCCESS:
      return {
        ...state,couriers:[...action.courier],deleting:false,success:true
      };
    case deliveryConstants.delivery.DELETE_COURIER_FAILURE:
      return { 
       ...state ,error: action.error ,deleting:false,success:false
      };

    case deliveryConstants.delivery.UPDATE_COURIER_REQUEST:
      return {
       ...state,updating: true
      };
    case deliveryConstants.delivery.UPDATE_COURIER_SUCCESS:
      return {
        ...state,couriers:[...state.couriers.filter(courier => courier.id !== action.courier.id), action.courier],updating:false,success:true
      };
    case deliveryConstants.delivery.UPDATE_COURIER_FAILURE:
      return { 
       ...state ,error: action.error ,updating:false,success:false
      };

    case deliveryConstants.delivery.DELETE_DELIVERY_MAN:
      return {
       ...state,deleting: true
      };
    case deliveryConstants.delivery.DELETE_DELIVERY_MAN_SUCCESS:
      return {
        ...state,deliveryMen:[...action.dispatcher],deleting:false,success:true
      };
    case deliveryConstants.delivery.DELETE_DELIVERY_MAN_FAILURE:
      return { 
       ...state ,error: action.error ,deleting:false,success:false
    };

    case deliveryConstants.delivery.UPDATE_DELIVERY_MAN:
      return {
       ...state,updating: true
      };
    case deliveryConstants.delivery.UPDATE_DELIVERY_MAN_SUCCESS:
      return {
        ...state,deliveryMen:[...state.deliveryMen.filter(item => item.id != action.dispatcher.id), action.dispatcher],updating:false,success:true
      };
    case deliveryConstants.delivery.UPDATE_DELIVERY_MAN_FAILURE:
      return { 
       ...state ,error: action.error ,updating:false,success:false
    };
    
    case userConstants.user.SUBMIT_BUSINESS_REQUEST:
      return {
       ...state,adding: true
      };
    case userConstants.user.SUBMIT_BUSINESS_SUCCESS:
      return {
        ...state, user: { ...state.user, ...action.user}, adding: false, success: true
      };
    case userConstants.user.SUBMIT_BUSINESS_FAILURE:
      return { 
       ...state ,error: action.error ,adding:false,success:false
    };



    case 'ADMIN_LIST_SUCCESS':
      return {
        ...state, admins: action.data,
      }
    
    
      default:
        return state
  }
}

export default users;