import React,{Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import swal from '@sweetalert/with-react';

import { userActions } from '../actions';
// import { payoutActions } from '../actions';

class Confirm extends Component{

    componentDidMount(){
        this.props.confirm(this.props.match.params.token,this.props.history);
    }

    componentWillReceiveProps(props){
        if(props.alert && props.alert.message&& props.alert.type == 'alert-danger'){
            swal("Error",props.alert.message,"error");
        }
    }
    
    render(){
         const {alert,confirming,success} = this.props;
        return(
            <div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'14%'}}>
                    <img src="/dist/img/logo.3fc64c3.png" width="320" alt="AtaraPay" className="img img-responsive" />
                </div>
                <div style={{textAlign:"center", fontSize: '30px'}}>Your Account is being Confirmed....</div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    const { success,confirming} = state.register;
    // const {success, confirming } = state.users;
    const alert  = state.alert;
    
    return {
       success,
       confirming,
       alert
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
   
    confirm:(token,history)=>dispatch(userActions.user.confirmBuyer(token,history)),
    
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Confirm));