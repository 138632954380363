import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from 'react-responsive-modal';
import * as moment from 'moment';
import {alertActions} from '../actions'
import swal from '@sweetalert/with-react';
import matchSorter from 'match-sorter'

import {jsx, css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';
import * as axios from "axios";
import {BASE_URL} from "../constants";

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

class BusinessApprovals extends Component {

    state = {
        filterable: false,
        notCustom: true,
        period: "All",
        assignModal: false,
        fullModal: false,
        from_date: "",
        filter: "",
        to_date: "",
        data: [],
        order: "",
        fields: {
            order_id: 0,
            delivery_man_id: 0
        },
        verifications: [],
        declineComments: '',

        loading: false,
        processing: false,

        declineId: null,
        approveId: null,
        baseUrl: null,
    }

    componentDidMount() {
        /* console.log("oox", this.props); */

        this.getData()
    }

    getData = () => {
        this.setState({
            loading: true,
        })
        axios({
            method: 'get',
            mode: 'no-cors',
            url: `${BASE_URL}admin/verifications`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                this.setState({
                    data: res.data.data,
                    verifications: res.data.data,
                    baseUrl: res.data.meta.base_url,
                })
            })
            .catch(err => {
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    approveBusiness = (e, verificationId) => {
        e.preventDefault();

        this.setState({
            processing: true,
        })

        console.log('approveing')
        axios({
            method: 'post',
            mode: 'no-cors',
            url: `${BASE_URL}admin/verifications/approve/${verificationId}`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                swal({
                    title: 'Success',
                    text: 'Successfully approved business',
                })

                this.setState({
                    showApproveModal: false,
                })
            })
            .catch(err => {
                const message = (err.response.data && err.response.data.message) || null
                swal({
                    title: 'Error',
                    icon: 'error',
                    text: `Error performing action${message ?  (", Message: " + message) : ""}`,
                })
            })
            .finally(() => {
                this.setState({
                    processing: false,
                })
                this.getData();
            })
    }

    declineBusiness = (e, verificationId) => {
        e.preventDefault();

        this.setState({
            processing: true,
        })

        axios({
            method: 'post',
            mode: 'no-cors',
            url: `${BASE_URL}admin/verifications/decline/${verificationId}`,
            data: {
                comments: this.state.declineComments,
            },
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                swal({
                    title: 'Success',
                    text: 'Successfully declined business',
                })

                this.setState({
                    showDeclineModal: false,
                    declineComments: '',
                })
            })
            .catch(err => {
                const message = (err.response.data && err.response.data.message) || null
                swal({
                    title: 'Error',
                    icon: 'error',
                    text: `Error performing action${message ?  (", Message: " + message) : ""}`,
                })
            })
            .finally(() => {
                this.setState({
                    processing: false,
                })
                this.getData();
            })
    }

    showDeclineModal = (e, verificationId) => {
        e.preventDefault();

        this.setState({
            declineId: verificationId,
            showDeclineModal: true,
        })
    }

    showApproveModal = (e, verificationId) => {
        e.preventDefault();

        this.setState({
            approveId: verificationId,
            showApproveModal: true,
        })
    }

    handleSelect = (e) => {
        const {value} = e.target;
        if (value === "Custom") {
            this.setState({notCustom: !this.state.notCustom})
        }
        this.filterPeriod(value);
        // const period = {period: value}
        this.setState({period: value});
        /* console.log(value); */
        console.log(this.state.period);
        console.log(this.state.data);
    }
    filterPeriod = (value) => {
        const data = this.state.verifications;

        if (value === "All") {
            /* console.log(data); */
            this.table_rows = data;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.state.payments]});
        }

        if (value === "This Week") {
            var ordersThisWeek = [];

            for (var i = 0; i < data.length; i++) {
                var now = moment();
                var input = moment(data[i].created_at);
                var isThisWeek = input.isSame(now, "week");

                if (isThisWeek) {
                    ordersThisWeek.push(data[i]);
                }
            }

            this.table_rows = ordersThisWeek;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            /* console.log(this.table_rows); */
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "This Month") {
            var ordersThisMonth = [];

            for (let i = 0; i < data.length; i++) {
                //var now = moment();
                let input = moment(data[i].created_at);
                var isThisMonth = input.isSame(new Date(), 'month');

                if (isThisMonth) {
                    ordersThisMonth.push(data[i]);
                }
            }

            this.table_rows = ordersThisMonth;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "60 Days Ago") {
            var ordersSixtyDaysAgo = [];

            for (let i = 0; i < data.length; i++) {

                var sixty_days_ago = moment().subtract(60, 'days');

                var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);

                if (isBetweenSixtyDays) {
                    ordersSixtyDaysAgo.push(data[i]);
                }
            }

            this.table_rows = ordersSixtyDaysAgo;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "90 Days Ago") {
            var ordersNinetyDaysAgo = [];

            for (let i = 0; i < data.length; i++) {

                var ninety_days_ago = moment().subtract(90, 'days');

                var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);

                if (isBetweenNinetyDays) {
                    ordersNinetyDaysAgo.push(data[i]);
                }
            }

            this.table_rows = ordersNinetyDaysAgo;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "Custom") {
            this.table_rows = data;
            this.to_date = null;
            this.from_date = null;

        }
    }

    filterDates = (data) => {

        // if(this.state.from_date == null){

        //     this.alertMessage = "Please select a From date";
        //     // $(".search-alert").removeClass("d-none");
        // }
        // if(this.state.to_date == null){
        //     this.alertMessage = "Please select a To date";
        //     // $(".search-alert").removeClass("d-none");
        // }

        // if(moment(this.from_date).isAfter(this.to_date)){
        //     this.alertMessage = "Please select a From date that is before your To date";
        //     // $(".search-alert").removeClass("d-none");
        // }

        /* console.log(this.state.from_date); console.log(this.state.to_date); */

        var ordersBetweenTheTwoDates = [];

        for (var i = 0; i < data.length; i++) {

            var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
            /* console.log(isBetweenDates); */
            if (isBetweenDates) {
                ordersBetweenTheTwoDates.push(data[i]);
            }
        }

        this.table_rows = ordersBetweenTheTwoDates;
        //this.notCustom = true;
        this.page = 1;
        //this.to_date = null;
        //this.from_date = null;
        /* console.log(this.table_rows); */
        return this.table_rows;
    }
    // componentDidMount= ()=>{
    //     'i got called';
    //     this.props.view("seller",this.props.history);
    // }
    handleDate = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});

    }

    filterTable = (filter) => {
        var data = this.state.verifications;
        var filteredRows = [];
        // this.setState({data:[...this.state.deliveryMen]});
        if (filter !== "") {
            for (var i = 0; i < data.length; i++) {

                if (data[i].id === parseInt(filter) ||
                    (data[i].business_name && data[i].business_name.toUpperCase().includes(filter.toUpperCase())) ||
                    (data[i].users && data[i].users.phone_number && data[i].users.phone_number.includes(filter)) ||
                    (data[i].business_email && data[i].business_email.toUpperCase().includes(filter.toUpperCase())) ||
                    (data[i].v_status && data[i].v_status.toUpperCase().includes(filter.toUpperCase())) ||
                    (data[i].payment_ref && data[i].payment_ref.toUpperCase().includes(filter.toUpperCase())) ||
                    (data[i].amount === parseInt(filter))
                ) {
                    filteredRows.push(data[i]);
                }
            }

            this.setState({data: [...filteredRows]});
        } else {
            this.setState({data: [...this.state.verifications]});
        }

    }

    handleFilter = (e) => {
        const {value} = e.target;
        this.setState({filter: value}, () => this.filterTable(this.state.filter));
        // this.filterTable();

    }
    onOpenModal = (name, payments) => {
        this.setState({[name]: true});
        this.setState({payments: payments});

    };

    onCloseModal = (name) => {
        this.setState({[name]: false});
        this.setState({payments: ""});
    };

    formatAmount = (amount) => {
        let a = amount + '';
        let naira = a.slice(0, -3);
        let kobo = a.substr(a.length - 2);
        return naira + "." + kobo;
    }

    render() {
        const {loading} = this.state;
        const {filterable} = this.state
        var columns = [];

        columns = [
            {
                Header: props => <span><b>SN</b></span>,
                id: "sn",
                filterable,
                maxWidth: 80,
                accessor: d => d,// String-based value accessors!
                Cell: (row) => {
                    return <span>{row.index + 1}</span>;
                }
            }, {
                id: "nameofbusiness",
                Header: props => <span><b>Name of Business</b></span>,
                filterable,
                accessor: d => d,// String-based value accessors!
                Cell: props => <span>
          {props.value.business_name || 'N/A'}
        </span>
            },
            {
                id: 'phoneno', // Required because our accessor is not a string
                Header: props => <span><b>Contact Phone No.</b></span>,
                filterable,
                accessor: d => d, // Custom value accessors!
                Cell: props => <span
                    className='number'>
          {props.value.users && props.value.users.phone_number ? props.value.users.phone_number : 'N/A'}

        </span> // Custom cell components!

            },
            {
                id: 'email', // Required because our accessor is not a string
                Header: props => <span><b>Contact Email</b></span>,
                filterable,
                accessor: d => d, // Custom value accessors!
                Cell: props => <span
                    className='number'>
          {props.value.business_email ? props.value.business_email : 'N/A'}

        </span> // Custom cell components!
            },
            {
                id: 'certificate', // Required because our accessor is not a string
                Header: props => <span><b>Certificate of incorporation</b></span>,
                filterable,
                accessor: d => d, // Custom value accessors!
                maxWidth: 250,
                Cell: props => <div
                    className='number text-center'>
                    {props.value.memorandum_of_article ?

                        <a target={"_blank"} href={`${this.state.baseUrl}/${props.value.memorandum_of_article}`}
                           className={'btn btn-primary'}>View</a>

                        : 'N/A'}

                </div> // Custom cell components!
            },
            {
                Header: props => <span><b>Actions</b></span>,
                id: "actions",
                maxWidth: 250,
                filterable: false,
                sortable: false,
                accessor: d => d,
                Cell: props => {


                    const status = props.original.v_status;


                    return (
                        <div className={'text-center'}>

                            {
                                (status == "Pending" || status == "Declined") &&

                                <>
                                    <div
                                        style={{marginBottom: 5}}
                                    >
                                        <button
                                            className={"btn btn-success"} disabled={false}
                                            onClick={e => this.showApproveModal(e, props.original.id)}>Approve
                                        </button>
                                    </div>

                                    <button className={"btn btn-danger"}
                                            onClick={e => this.showDeclineModal(e, props.original.id)}>Decline
                                    </button>
                                </>
                            }

                            {
                                status == "Approved" &&

                                <>
                                    <div
                                        style={{marginBottom: 5}}
                                    >
                                        <button
                                            className={"btn btn-success"} disabled={true}
                                            onClick={e => e.preventDefault()}>
                                            Approve
                                        </button>
                                    </div>
                                </>
                            }

                        </div>
                    )
                }
            },
            {
                Header: props => <span><b>Status</b></span>,
                id: "status",
                maxWidth: 250,
                filterable: false,
                accessor: d => d.v_status,
                Cell: props => {

                    return <span>
            <div>{props.original.v_status}
            </div>
          </span>
                }
            },]


        return (

            <div>
                <Modal open={this.state.showDeclineModal} onClose={() => this.setState({showDeclineModal: false})}
                       center>
                    <form action="" onSubmit={e => e.preventDefault()}>
                        <div className="form-group">
                            <label htmlFor="decline">Decline Reason</label>
                            <textarea value={this.state.declineComments}
                                      onChange={e => this.setState({declineComments: e.target.value})}
                                      className={'form-control'}
                                      id={'decline'}/>
                        </div>
                        <div className="form-group">
                            <button disabled={this.state.processing}
                                    onClick={e => this.declineBusiness(e, this.state.declineId)}
                                    className={'btn-danger btn'}>Decline
                            </button>
                            &nbsp;
                            <button onClick={() => this.setState({showDeclineModal: false,})}
                                    className={'btn-default btn'}>Cancel
                            </button>
                        </div>
                    </form>
                </Modal>
                <Modal open={this.state.showApproveModal} onClose={() => this.setState({showApproveModal: false})}
                       center>
                    <form action="" onSubmit={e => e.preventDefault()}>
                        <div>
                            Are you sure you want to approve this business?
                        </div>
                        <div className="form-group">
                            <button disabled={this.state.processing}
                                    onClick={e => this.approveBusiness(e, this.state.approveId)}
                                    className={'btn-success btn'}>Approve
                            </button>
                            &nbsp;
                            <button onClick={() => this.setState({showApproveModal: false,})}
                                    className={'btn-default btn'}>Cancel
                            </button>
                        </div>
                    </form>
                </Modal>


                <div class="content-wrapper" style={{minHeight: 136}}>
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-12">
                                    <h1 class="m-0 text-dark">Business Approvals</h1>
                                    <br/>

                                </div>
                            </div>
                        </div>
                    </div>
                    {!loading ?
                        <section class="content">
                            <div class="container-fluid"><br/>
                                <div class="row ">
                                    <div class="col-12 col-sm-12 col-md-12">
                                        <div class="alert animated bounceIn alert-danger d-none search-alert"><a
                                            aria-label="close"
                                            class="close alert-close">×</a>
                                            <span></span></div>
                                        <div class="card">
                                            <div class="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-sm-2">
                                                        <div className="form-group"><label
                                                            htmlFor="period">Period</label>
                                                            <select className="form-control" name="filter"
                                                                    onChange={this.handleSelect}>
                                                                <option value="All">All</option>
                                                                <option value="This Week">This Week</option>
                                                                <option value="This Month">This Month</option>
                                                                <option value="60 Days Ago">60 Days Ago</option>
                                                                <option value="90 Days Ago">90 Days Ago</option>
                                                                <option value="Custom">Custom</option>
                                                            </select></div>
                                                    </div>
                                                    <div className="col-12 col-sm-2">
                                                        <div className="form-group"><label
                                                            htmlFor="filter">Filter</label><input type="text"
                                                                                                  placeholder="Filter"
                                                                                                  className="form-control"
                                                                                                  value={this.state.filter}
                                                                                                  onChange={this.handleFilter}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-3">
                                                        <div className="form-group"><label htmlFor="from">Date
                                                            From</label> <input type="date"
                                                                                name="from_date"
                                                                                value={this.state.from_date}
                                                                                disabled={this.state.notCustom}
                                                                                onChange={this.handleDate}
                                                                                className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-3">
                                                        <div className="form-group"><label htmlFor="from">Date
                                                            To</label> <input type="date"
                                                                              name="to_date"
                                                                              value={this.state.to_date}
                                                                              disabled={this.state.notCustom}
                                                                              onChange={this.handleDate}
                                                                              className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-2">
                                                        <div className="form-group"><label>&nbsp;</label>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <button disabled={this.state.notCustom}
                                                                            onClick={() => {
                                                                                this.setState({data: [...this.filterDates(this.state.orders)]})
                                                                            }}
                                                                            className="btn btn-info btn-block form-control">
                                                                        <center>Search</center>
                                                                    </button>
                                                                </div>
                                                                {/* <div class="col-4">
                                                            <button onClick={()=>{this.setState({filterable: !filterable})}} class="btn btn-info btn-md">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div class="table-responsive">
                                                    <ReactTable
                                                        defaultPageSize={5}
                                                        filterable
                                                        data={this.state.data}
                                                        columns={columns}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        </section> :
                        <div className='sweet-loading'>
                            <ClipLoader
                                className={override}
                                sizeUnit={"px"}
                                size={70}

                                color={'blue'}
                                loading={loading}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default BusinessApprovals;