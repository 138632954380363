import { termService } from "../service.js";
import { termConstants } from "../constants";
import { alertActions, userActions } from "./";

  
 const createTerm = (term,history)=> {
    return (dispatch) => {
        dispatch(request(term));
      
        termService.term.createTerm(term, history)
            .then(  
                res => { 
                    if(res.data.status=="success"){
                        dispatch(success(res.data.data));
                        
                        dispatch(alertActions.alert.success(("Delivery Terms successfully created").replace(/_/g, " ")));
                       
                    }else{
                        dispatch(failure(res.data.message));
                        dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " "))); 
                    }
                  
                }
            ).catch(
         
                err=>{
                    if(err.response){
                        if(err.response.status === 401){
                            dispatch(userActions.user.logout(history))
                            dispatch(failure(err.response.data.message));
                        dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }else{
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }
                    else if(err.request){
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    else{
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
    
                    console.log(err.config)
    
            })
    };
    function request(term){ return { type: termConstants.term.CREATE_TERM, term } };
    function success(term) { return { type: termConstants.term.CREATE_TERM_SUCCESS, term } };
    function failure(error) { return { type: termConstants.term.CREATE_TERM_FAILURE, error } };
 }
 const editTerm = (term,history)=> {
    return (dispatch) => {
        dispatch(request(term));
      
        termService.term.editTerm(term, history)
            .then(
                res => { 
                    if(res.data.status === "success"){
                        dispatch(success(res.data.data));
                        
                        dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " ")));
                       
                    }else{
                        dispatch(failure(res.data.message));
                        dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " "))); 
                    }
                  
                }
            ).catch(
         
                err=>{
                    if(err.response){
                        if(err.response.status === 401){
                            dispatch(userActions.user.logout(history))
                            dispatch(failure(err.response.data.message));
                        dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }else{
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }
                    else if(err.request){
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    else{
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
    
                    console.log(err.config)
    
            })
    };
    function request(term){ return { type: termConstants.term.EDIT_TERM, term } };
    function success(term) { return { type: termConstants.term.EDIT_TERM_SUCCESS, term } };
    function failure(error) { return { type: termConstants.term.EDIT_TERM_FAILURE, error } };
 }

const getTerm =(history)=> {
    return (dispatch) => {
        dispatch(request());
        
        termService.term.getTerm()
            .then(
                
                res => { 
                    if(res.data.status === "success"){
                        dispatch(success(res.data.data));
                        
                        // dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " ")));
                       
                    }else{
                        dispatch(failure(res.data.message));
                        // dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " "))); 
                    }
                   
                }
            ).catch(
         
                err=>{
                    if(err.response){
                        if(err.response.status === 401){
                            dispatch(userActions.user.logout('seller', history))
                            dispatch(failure(err.response.data.message));
                        dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }else{
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }
                    else if(err.request){
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    else{
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
    
                    console.log(err.config)
    
            })
    };
    function request(){ return { type: termConstants.term.REQUEST_TERM } };
    function success(term) { return { type: termConstants.term.REQUEST_TERM_SUCCESS, term } };
    function failure(error) { return { type: termConstants.term.REQUEST_TERM_FAILURE, error } };

}


export {
    createTerm,
    editTerm,
    getTerm
};