import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {alertActions, userActions} from '../actions'
import swal from '@sweetalert/with-react';
import {ClipLoader} from 'react-spinners';
import {jsx, css} from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import {USER_ROLE_SELLER, USER_ROLE_MARKET, BASE_URL} from '../constants';
import {userService} from "../service.js";
import * as axios from "axios";
import Modal from "react-responsive-modal";

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

class Admins extends Component {

  state = {
    dashboard: [],
    admins: [],
    processing: false,
    addAdmin: false,
    editAdmin: false,
    editAdminInfo: {
      id: 0,
      name: '',
      email: '',
    },
    deleteAdminId: false,
    loading: false,
  }


  componentDidMount() {
    this.getAdmins();
  }

  getAdmins = () => {
    this.setState({loading: true})
    const {dispatch, history} = this.props;
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/list_admins`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         if (res.data.status === 'success') {
           console.log(res.data);

           this.setState({
             admins: res.data.data,
           })
         }
       })
       .catch(err => {
         if (err.response) {
           if (err.response.status === 401) {
             dispatch(userActions.user.logout(history));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           } else {
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
         } else {
           console.log(err.message);
         }

       })
       .finally(() => {
         this.setState({loading: false})
       })
  }

  componentWillReceiveProps(props) {
    if (props.alert && props.alert.message && props.alert.type == "alert-danger") {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (props.dashboard !== null) {
      this.setState({dashboard: props.dashboard});
    }
  }

  handleAddAdmin = e => {
    e.preventDefault();

    const name = this.addAdminName.value;
    const email = this.addAdminEmail.value;
    const password = this.addAdminPass.value;
    const passconf = this.addAdminPassConf.value;

    if (password !== passconf) {
      swal({
        title: 'Password don\'t match',
        text: "Passwords don't match",
      })

      return;
    }

    const data = {
      name,
      email,
      password,
    }

    this.setState({
      processing: true,
    })

    axios({

      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}admin/create`,
      data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data}) => {
         console.log(data);

         if (data.status === 'success') {
           this.setState({
             addAdmin: false,
           })

           swal({
             title: 'Successfully Added',
             text: 'The user has been added successfully',
           })
         }

       })
       .catch(err => {
         swal({
           title: 'Error!',
           text: 'There was an error. Make sure all fields are filled.',
         })
       })
       .finally(() => {
         this.getAdmins()

         this.setState({
           processing: false,
         })
       })
  }

  handleEditAdmin = e => {
    e.preventDefault();

    const name = this.editAdminName.value;
    const email = this.editAdminEmail.value;
    const password = this.editAdminPass.value;
    const passconf = this.editAdminPassConf.value;

    if (password !== passconf) {
      swal({
        title: 'Password don\'t match',
        text: "Passwords don't match",
      })

      return;
    }
    this.setState({
      processing: true,
    })

    const data = {
      name,
      email,
      password,
    }

    axios({

      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}admin/edit/${this.state.editAdminInfo.id}`,
      data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data}) => {
         console.log(data);

         if (data.status === 'success') {
           this.setState({
             editAdmin: false,
           })

           swal({
             title: 'Successfully Edited',
             text: 'The user has been edited successfully',
           })
         }

       })
       .catch(err => {

       })
       .finally(() => {
         this.getAdmins()

         this.setState({
           processing: false,
         })

       })
  }

  editAdmin = (e, admin) => {
    e.preventDefault();

    this.setState({
      editAdminInfo: admin,
      editAdmin: true,
    })
  }

  deleteAdmin = () => {
    this.setState({
      processing: true,
    })


    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/delete/${this.state.deleteAdminId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         console.log(res.data);
         swal({
           title: 'Deleted Admin',
           text: 'User successfully deleted.'
         })
       })
       .catch(err => {
         swal({
           title: 'Failed',
           text: 'Something went wrong',
         })
       })
       .finally(() => {
         this.getAdmins()
         this.setState({
           deleteAdminId: false,
           processing: false,
         })
       })
  }

  render() {
    const {user, loading: userLoading} = this.props;
    const {loading} = this.state;

    return (

       <div class="content-wrapper" style={{minHeight: 137}}>
         <div class="content-header">
           <div class="container-fluid">
             <div class="row mb-2">
               <div class="col-sm-6">
                 <h1 class="m-0 text-dark">Admins</h1><br/>
               </div>
             </div>
           </div>
         </div>
         <Modal open={this.state.addAdmin} onClose={() => this.setState({addAdmin: false})} center>

           <div class="modal-header">
             <h4 class="modal-title">Add Admin</h4>
           </div>
           <div class="modal-body">
             <div class="row form-group">
               <div class="col-sm-12">
                 <form action="">
                   <div className="form-group">
                     <label htmlFor="">Name</label>
                     <input ref={ref => this.addAdminName = ref} type="text" className={'form-control'}/>
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Email</label>
                     <input ref={ref => this.addAdminEmail = ref} type="email" className={'form-control'}/>
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Password</label>
                     <input ref={ref => this.addAdminPass = ref} type="password" className={'form-control'}/>
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Confirm Password</label>
                     <input ref={ref => this.addAdminPassConf = ref} type="password" className={'form-control'}/>
                   </div>
                 </form>
               </div>
             </div>
           </div>
           <div class="modal-footer">
             <button type="button" onClick={() => this.setState({addAdmin: false,})} data-dismiss="modal"
                     class="btn btn-danger">Cancel
             </button>
             &nbsp;&nbsp;
             <button onClick={this.handleAddAdmin} disabled={this.state.processing} type="button"
                     class="btn btn-primary">{this.state.processing ? 'Adding..' : 'Add Admin'}</button>
           </div>

         </Modal>
         <Modal open={this.state.deleteAdminId !== false} onClose={() => this.setState({deleteAdminId: false,})} center>

           <div class="modal-header">
             <h4 class="modal-title">Delete Admin?</h4>
           </div>
           <div class="modal-body">
             <div class="row form-group">
               <div class="col-sm-12">
                 Are you sure you want to delete this admin?
               </div>
             </div>
           </div>
           <div class="modal-footer">
             <button type="button" onClick={() => this.setState({deleteAdminId: false,})} data-dismiss="modal"
                     class="btn btn-danger">Cancel
             </button>
             &nbsp;&nbsp;
             <button onClick={this.deleteAdmin} disabled={this.state.processing} type="button"
                     class="btn btn-primary">{this.state.processing ? 'Deleting..' : 'Delete'}</button>
           </div>

         </Modal>

         <Modal open={this.state.editAdmin} onClose={() => this.setState({editAdmin: false})} center>

           <div class="modal-header">
             <h4 class="modal-title">Edit Admin</h4>
           </div>
           <div class="modal-body">
             <div class="row form-group">
               <div class="col-sm-12">
                 <form action="">
                   <div className="form-group">
                     <label htmlFor="">Name</label>
                     <input ref={ref => this.editAdminName = ref} type="text"
                            defaultValue={this.state.editAdminInfo.name} className={'form-control'}/>
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Email</label>
                     <input ref={ref => this.editAdminEmail = ref} type="email"
                            defaultValue={this.state.editAdminInfo.email} className={'form-control'}/>
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Password</label>
                     <input ref={ref => this.editAdminPass = ref} type="password" className={'form-control'}/>
                   </div>
                   <div className="form-group">
                     <label htmlFor="">Confirm Password</label>
                     <input ref={ref => this.editAdminPassConf = ref} type="password" className={'form-control'}/>
                   </div>
                 </form>

               </div>
             </div>
           </div>
           <div class="modal-footer">
             <button type="button" onClick={() => this.setState({editAdmin: false,})} data-dismiss="modal"
                     class="btn btn-danger">Cancel
             </button>
             &nbsp;&nbsp;
             <button onClick={this.handleEditAdmin} disabled={this.state.processing} type="button"
                     class="btn btn-primary">{this.state.processing ? 'Editing..' : 'Edit Admin'}</button>
           </div>
         </Modal>

         {(!loading && !userLoading) ?
            <section class="content">
              <div class="container-fluid">
                <div className={'row'}>
                  <div className="col-md-12">
                    <button className="btn-primary btn" onClick={() => this.setState({addAdmin: true})}>Add Admin
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-6 col-md-8">
                    <div class="card">
                      <div class="card-header border-transparent">
                        <h3 class="card-title"
                            style={{fontSize: 15, fontWeight: 600, marginTop: 5, marginBottom: 0.3}}>List of Admins</h3>
                      </div>
                      <div class="card-body p-0">
                        {this.state.admins ?
                           <div class="table-responsive">
                             <table class="table m-0">
                               <thead>
                               <tr>
                                 <th>Name</th>
                                 <th>Email</th>
                                 {user && user.role && user.role.name === USER_ROLE_MARKET && <th>Seller</th>}
                                 <th>Actions</th>
                               </tr>
                               </thead>
                               <tbody>
                               {this.state.admins.map((admin, i) => {
                                 return [
                                   <tr key={i}>
                                     <td>{admin.name}</td>
                                     <td>{admin.email}</td>
                                     <td>
                                       <a href="#" onClick={e => this.editAdmin(e, admin)}>Edit</a>
                                       {
                                         user.id !== admin.id && <> | <a href="#" onClick={e => {
                                           e.preventDefault();
                                           this.setState({deleteAdminId: admin.id})
                                         }}>Delete</a></>
                                       }
                                     </td>
                                   </tr>

                                 ];
                               })}

                               </tbody>
                             </table>
                           </div> :
                           <div style={{fontSize: 14, padding: 10, color: 'red'}}>No recent orders</div>
                        }
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </section> :
            <div className='sweet-loading'>
              <ClipLoader
                 className={override}
                 sizeUnit={"px"}
                 size={70}

                 color={'blue'}
                 loading={loading || userLoading}
              />
            </div>
         }
       </div>
    )
  }
}

export default withRouter(Admins);