import { payoutConstants} from '../constants';

 const  payout = (state = {accounts: []}, action)=>{
  switch (action.type) {
    case payoutConstants.payout.PAYOUT_DETAIL_ADD_REQUEST:
        return {...state, adding: true };
    case payoutConstants.payout.PAYOUT_DETAIL_ADD_SUCCESS:
        return { 
          ...state,
          adding: false,
          accounts: [...state.accounts,action.account],
          success:true
        };
    case payoutConstants.payout.PAYOUT_DETAIL_ADD_FAILURE:
        return {...state,adding: false,success:false};
    
        case payoutConstants.payout.PAYOUT_DETAIL_DELETE_REQUEST:
        return { ...state,deleting: true };
    case payoutConstants.payout.PAYOUT_DETAIL_DELETE_SUCCESS:
        return { ...state,
          deleting: false,
          accounts: [...action.accounts],
          success:true
        };
    case payoutConstants.payout.PAYOUT_DETAIL_DELETE_FAILURE:
        return {...state,deleting: false,success:false};

        case payoutConstants.payout.PAYOUT_DETAIL_EDIT_REQUEST:
        return { ...state,updating: true };
    case payoutConstants.payout.PAYOUT_DETAIL_EDIT_SUCCESS:
        return { ...state,
          updating: false,
          accounts: [...state.accounts.filter(account => account.id !==action.account.id),action.account],
          success:true
        };
    case payoutConstants.payout.PAYOUT_DETAIL_EDIT_FAILURE:
        return {...state,updating: false,success:false};

        case payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_REQUEST:
        return {...state, updating: true };
    case payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_SUCCESS:
        return { ...state,
          updating: false,
          accounts: [...state.accounts.filter(account => account.id !==action.account.id),action.account],
          success:true
        };
    case payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_FAILURE:
        return {...state,updating: false,success:false};

    case payoutConstants.payout.PAYOUT_DETAILS_REQUEST:
      return { ...state,loading: true };

    case payoutConstants.payout.PAYOUT_DETAILS_SUCCESS:
      return {...state,accounts: action.details, loading: false };
    case payoutConstants.payout.PAYOUT_DETAILS_FAILURE:
      return {...state, error: action.error , loading: false};
    
    case payoutConstants.payout.ALL_PAYOUTS_REQUEST:
      return { ...state,loading: true };

    case payoutConstants.payout.ALL_PAYOUTS_SUCCESS:
      return { ...state, payouts: action.payouts, loading: false };
    case payoutConstants.payout.ALL_PAYOUTS_FAILURE:
      return {...state, error: action.error , loading: false};
    default:
      return state
  }
}

export default payout;