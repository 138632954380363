import { settingService } from "../service.js";
import { settingConstants } from "../constants";
import { alertActions, termActions, userActions } from "./";


 const setRefundable = (setting, history)=> {
     const request = (setting) => { return { type: settingConstants.settings.SET_REFUNDABLE, setting } };
     const success = (setting) => { return { type: settingConstants.settings.SET_REFUNDABLE_SUCCESS, setting } };
     const failure = (error) => { return { type: settingConstants.settings.SET_REFUNDABLE_FAILURE, error } };
     
    return (dispatch) => {
        dispatch(request(setting));
      
        settingService.settings.setRefundable(setting)
            .then(
                res => { 
                   
                    dispatch(success(res.data.data));
                    if(res.data.status==="success"){
                        dispatch(success(res.data.data));
                         dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " "))); 
                        dispatch(termActions.getTerm());
                    }else{
                        dispatch(failure(res.data.message));
                        dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " "))); 
                    }
                    
                    
                }
            ).catch(
         
                err=>{
                    if(err.response){
                        if(err.response.status === 401){
                            dispatch(userActions.user.logout(history))
                            dispatch(failure(err.response.data.message));
                        dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }else{
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }
                    else if(err.request){
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    else{
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
    
                    console.log(err.config)
    
            })
        
    };
    
 }

const setPayment = (setting, history) => {
    
    const request = (setting) => { return { type: settingConstants.settings.SET_PAYMENT, setting } };
    const success = (setting) => { return { type: settingConstants.settings.SET_PAYMENT_SUCCESS, setting } };
    const failure = (error) => { return { type: settingConstants.settings.SET_PAYMENT_FAILURE, error } };

    return (dispatch) => {
        dispatch(request(setting));

        settingService.settings.setPayment(setting)
            .then(
                res => {

                    dispatch(success(res.data.data));
                    if (res.data.status === "success") {
                        dispatch(success(res.data.data));
                        dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " ")));
                        dispatch(termActions.getTerm());
                    } else {
                        dispatch(failure(res.data.message));
                        dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
                    }


                }
            ).catch(

                err => {
                    if (err.response) {
                        if (err.response.status === 401) {
                            dispatch(userActions.user.logout(history))
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        } else {
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }
                    else if (err.request) {
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    else {
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }

                    console.log(err.config)

                })
       
    };
    
}

const setCardFee = (setting, history) => {
    const request = (setting) => { return { type: settingConstants.settings.SET_CARD_FEE, setting } };
    const success = (setting) => { return { type: settingConstants.settings.SET_CARD_FEE_SUCCESS, setting } };
    const failure = (error) => { return { type: settingConstants.settings.SET_CARD_FEE_FAILURE, error } };

    return (dispatch) => {
        dispatch(request(setting));
        settingService.settings.setCardFee(setting)
            .then(
                res => {
                    dispatch(success(res.data.data));
                    if (res.data.status === "success") {
                        dispatch(success(res.data.data));
                        dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " ")));
                        dispatch(termActions.getTerm());
                    } else {
                        dispatch(failure(res.data.message));
                        dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
                    }
                }
            ).catch(
                err => {
                    if (err.response) {
                        if (err.response.status === 401) {
                            dispatch(userActions.user.logout(history))
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        } else {
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }else if (err.request) {
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }else {
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    console.log(err.config)
                })

    };

}

const setCallbackUrl = (setting, history) => {
    const request = (setting) => { return { type: settingConstants.settings.SET_CARD_FEE, setting } };
    const success = (setting) => { return { type: settingConstants.settings.SET_CARD_FEE_SUCCESS, setting } };
    const failure = (error) => { return { type: settingConstants.settings.SET_CARD_FEE_FAILURE, error } };

    return (dispatch) => {
        dispatch(request(setting));
        settingService.settings.setCallbackUrl(setting)
            .then(
                res => {
                    dispatch(success(res.data.data));
                    if (res.data.status === "success") {
                        dispatch(success(res.data.data));
                        dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " ")));
                        dispatch(termActions.getTerm());
                    } else {
                        dispatch(failure(res.data.message));
                        dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
                    }
                }
            ).catch(
                err => {
                    if (err.response) {
                        if (err.response.status === 401) {
                            dispatch(userActions.user.logout(history))
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        } else {
                            dispatch(failure(err.response.data.message));
                            dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
                        }
                    }else if (err.request) {
                        console.log(err.request);
                        console.log(err.message);
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }else {
                        console.log(err.message)
                        dispatch(failure(err.message));
                        dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
                    }
                    console.log(err.config)
                })

    };

}

export {
    setRefundable,
    setPayment,
    setCardFee,
    setCallbackUrl
};