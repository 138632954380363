import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {alertActions} from '../actions'
import swal from '@sweetalert/with-react';
import {ClipLoader} from 'react-spinners';
import {jsx, css} from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import {USER_ROLE_SELLER, USER_ROLE_MARKET, BASE_URL} from '../constants';
import * as axios from "axios";

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

class Dashboard extends Component {


  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
    }
  }


  componentDidMount() {
    this.loadData();
    console.log('cdm')
  }

  loadData = () => {
    this.setState({loading: true})
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/dashboard`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then((res) => {
         if (res.data.status == 'success') {

           this.setState({
             data: res.data.data,
           })

         } else {
           swal({
             icon: 'error',
             title: 'Error',
             text: 'Error fetching data',
           })
         }
       })
       .catch(() => {
         swal({
           title: 'error',
           text: 'error',
         })
       })
       .finally(() => {
         this.setState({loading: false,})
       })
  }

  render() {
    const {data, loading} = this.state;

    return (

       <div class="content-wrapper" style={{minHeight: 137}}>
         <div class="content-header">
           <div class="container-fluid">
             <div class="row mb-2">
               <div class="col-sm-6">
                 <h1 class="m-0 text-dark">Dashboard</h1>
               </div>
             </div>
           </div>
         </div>


         {
           !loading && data !== null?
              <>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="info-box"> <span className="info-box-icon bg-info elevation-1"><i
                           className="ion-ios-pulse-strong"></i></span>
                          <div className="info-box-content"><span className="info-box-text">Payout
                                Total</span>
                            <i className="ion-help floatr-top" data-tip data-for='payouttotal'></i>
                            <span className="info-box-number">
                                ₦ {data.metrics.payoutTotal}
                            </span>
                            <ReactTooltip id='payouttotal' place="bottom" aria-haspopup='true'>
                              Payout Total refers to the total sum in Naira of AtaraPay escrow orders <br />paid out to Seller whose orders were Accepted by the Buyer<br />Please note that this amount is less escrow and card fees.
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="info-box"> <span className="info-box-icon bg-success elevation-1"><i
                           className="ion-ios-help-outline"></i></span>
                          <div className="info-box-content"><span className="info-box-text">Payout Pending</span>
                            <i className="ion-help floatr-top" data-tip data-for='payoutpending'></i>
                            <span className="info-box-number">
                                ₦ {data.metrics.payoutPending}
                            </span>
                            <ReactTooltip id='payoutpending' place="bottom" aria-haspopup='true'>
                              Payout Pending refers to the total sum in Naira of AtaraPay escrow orders <br />yet to be paid out to Seller whose orders were Accepted by the Buyer.<br />Please note that this amount is less escrow and card fees.
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="info-box"> <span className="info-box-icon bg-info elevation-1"><i
                           className="ion-help"></i></span>
                          <div className="info-box-content"><span className="info-box-text">No. of Sellers</span>
                            <i className="ion-help floatr-top" data-tip data-for='sellerCount'></i>
                            <span className="info-box-number">
                                {data.metrics.totalSellers}
                            </span>
                            <ReactTooltip id='sellerCount' place="bottom" aria-haspopup='true'>
                              Number of sellers refers to the total number of sellers <br />under a marketplace operator
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="info-box"> <span className="info-box-icon bg-dark elevation-1"><i
                           className="fa-user fa"></i></span>
                          <div className="info-box-content"><span className="info-box-text">No. of Buyers</span>
                            <i className="ion-help floatr-top" data-tip data-for='buyerCount'></i>
                            <span className="info-box-number">
                                {data.metrics.totalBuyers}
                            </span>

                            <ReactTooltip id='buyerCount' place="bottom" aria-haspopup='true'>
                              Number of buyers refers to the total number of buyers on the platform.
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="info-box"> <span className="info-box-icon bg-primary elevation-1"><i
                           className="fa-gear fa"></i></span>
                          <div className="info-box-content"><span
                             className="info-box-text">No. of Marketplace Operators</span>
                            <i className="ion-help floatr-top" data-tip data-for='moCount'></i>
                            <span className="info-box-number">
                                {data.metrics.totalMarketplaceOperators}
                            </span>

                            <ReactTooltip id='moCount' place="bottom" aria-haspopup='true'>
                              Number of marketplace operators refers to the total number of marketplace operators in the system
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="info-box"> <span className="info-box-icon bg-danger elevation-1"><i
                           className="ion-ios-cart-outline"></i></span>
                          <div className="info-box-content"><span
                             className="info-box-text">Orders</span>
                            <i className="ion-help floatr-top" data-tip data-for='orders'></i>
                            <span className="info-box-number">
                                {data.metrics.totalOrders}
                            </span>
                            <ReactTooltip id='orders' place="bottom" aria-haspopup='true'>
                              Orders refer to the number of AtaraPay escrow orders placed via Seller website or Person to Person (P2P) transaction <br />created via the Buyer, Seller or Broker’s AtaraPay account. These orders will each go through the escrow <br />status of Pending, Cancelled, Accepted, Rejected, Disputed and Completed
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-8">
                      <div className="card">
                        <div className="card-header border-transparent">
                          <h3 className="card-title"
                              style={{fontSize: 15, fontWeight: 600, marginTop: 5, marginBottom: 0.3}}>Latest
                            Orders</h3>
                        </div>
                        <div className="card-body p-0">
                          {data.latestOrders.length ?
                             <div className="table-responsive">
                               <table className="table m-0">
                                 <thead>
                                 <tr>
                                   <th>Order ID</th>
                                   <th>Item</th>
                                   <th>Status</th>
                                   <th>Seller Name</th>
                                   <th>Buyer Name</th>
                                   <th>Price</th>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 {data.latestOrders.map((item, i) => {
                                   return [
                                     <tr key={i}>
                                       <td>{item.id}</td>
                                       <td>{item.product_name}</td>

                                       {item.status.id === 1 || item.status.id === 11 || item.status.id === 7 || item.status.id === 3 ?
                                          <td><span className="badge badge-warning"> {item.status.title}</span>
                                          </td> : item.status.id === 9 || item.status.id === 4 || item.status.id === 10 ?
                                             <td><span className="badge badge-danger"> {item.status.title}</span>
                                             </td> : item.status.id === 6 || item.status.id === 8 ?
                                                <td><span className="badge badge-info"> {item.status.title}</span>
                                                </td> : item.status.id === 2 || item.status.id === 5 ?
                                                   <td><span className="badge badge-success"> {item.status.title}</span>
                                                   </td> :
                                                   <td><span className="badge badge-warning">{item.status.title}</span>
                                                   </td>
                                       }
                                       <th>{(item.sellers ? item.sellers.business_name : false) || (item.user && (item.user.firstname + ' ' + item.user.lastname)) || 'N/A'}</th>
                                       <th>{item.customers ? (item.customers.firstname + ' ' + item.customers.lastname) : 'N/A'}</th>

                                       <td>
                                         ₦ {(item.amount_payed / 100).toLocaleString('en', {minimumFractionDigits: 2})}

                                       </td>
                                     </tr>

                                   ];
                                 })}

                                 </tbody>
                               </table>
                             </div> :
                             <div style={{fontSize: 14, padding: 10, color: 'red'}}>No recent orders</div>
                          }
                        </div>
                        <div className="card-footer clearfix">
                          <Link class="btn btn-sm btn-primary"
                                style={{padding: "8 40", color: "rgb(255, 255, 255)", float: "right"}} to="/app/orders">View
                            All Orders</Link></div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4">
                      <div className="card-body p-0">
                        <ReactTooltip id='global' place="bottom" type="dark" effect="float"/>
                        <div className="info-box mb-1 mt-1">
                          <i className="ion-help floatr" data-tip data-for='ptranx'></i>
                          <span className="info-box-icon"><i className="fa fa-clock-o"></i></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Pending Transactions</span>
                            <span className="info-box-number">{data.metrics.pendingTransactions}</span>
                          </div>
                          <ReactTooltip id='ptranx' place="bottom" aria-haspopup='true'>
                            Pending Transactions refers to the total number of AtaraPay escrow orders <br/>that are
                            pending
                            delivery to the Buyer or Recipient
                          </ReactTooltip>
                        </div>

                        <div className="info-box mb-1">
                          <i className="ion-help floatr" data-tip data-for='ctranx'></i>
                          <span className="info-box-icon"><i className="fa fa-times"></i></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Cancelled Transactions</span>
                            <span className="info-box-number">{data.metrics.cancelledTransactions}</span>
                          </div>
                          <ReactTooltip id='ctranx' place="bottom" aria-haspopup='true'>
                            Cancelled Transactions refers to the total number of AtaraPay escrow orders <br/>that were
                            cancelled by the Buyer within or beyond the Seller’s <br/>Service Level Agreement (SLA) for
                            cancellations
                          </ReactTooltip>
                        </div>

                        <div className="info-box mb-1 ">
                          <i className="ion-help floatr" data-tip data-for='atranx'></i>
                          <span className="info-box-icon"><i className="fa fa-thumbs-up"></i></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Accepted Transactions</span>
                            <span className="info-box-number">{data.metrics.acceptedTransactions}</span>
                          </div>
                          <ReactTooltip id='atranx' place="bottom" aria-haspopup='true'>
                            Accepted Transactions refers to the total number of AtaraPay escrow <br/>orders that were
                            accepted by the Buyer
                          </ReactTooltip>
                        </div>

                        <div className="info-box mb-1">
                          <i className="ion-help floatr" data-tip data-for='rtranx'></i>
                          <span className="info-box-icon"><i className="fa fa-thumbs-down"></i></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Rejected Transactions</span>
                            <span className="info-box-number">{data.metrics.rejectedTransactions}</span>
                          </div>
                          <ReactTooltip id='rtranx' place="bottom" aria-haspopup='true'>
                            Rejected Transactions refers to the total number of AtaraPay <br/>escrow orders that were
                            rejected by the Buyer
                          </ReactTooltip>
                        </div>


                        <div className="info-box mb-1">
                          <i className="ion-help floatr" data-tip data-for='cotranx'></i>
                          <span className="info-box-icon"><i className="fa fa-check"></i></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Completed Transactions</span>
                            <span className="info-box-number">{data.metrics.completedTransactions}</span>
                          </div>
                          <ReactTooltip id='cotranx' place="bottom" aria-haspopup='true'>
                            <p>Completed transactions are transactions that are one of the below;</p>
                            <ol type="1">
                              <li>Cancelled</li>
                              <li>Effect a refund to Buyer due to rejection of items</li>
                              <li>Effect payout to Seller due to acceptance of items or cancellation fee due</li>
                              <li>Effect settlement in favour of Buyer or Seller from a dispute resolution</li>
                            </ol>
                          </ReactTooltip>
                        </div>
                        <div className="info-box mb-1">
                          <i className="ion-help floatr" data-tip data-for='dtranx'></i>
                          <span className="info-box-icon"><i className="fa fa-question"></i></span>
                          <div className="info-box-content">
                            <span className="info-box-text">Disputed Transactions</span>
                            <span className="info-box-number">{data.metrics.disputedTransactions}</span>
                          </div>
                          <ReactTooltip id='dtranx' place="bottom" aria-haspopup='true'>
                            Disputed Transactions refers to the total number of disputes raised on <br/>AtaraPay escrow
                            orders concerning the Seller or Buyer
                          </ReactTooltip>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
              </>
              :

              <div className='sweet-loading'>
                <ClipLoader
                   className={override}
                   sizeUnit={"px"}
                   size={70}

                   color={'blue'}
                   loading={loading}
                />
              </div>
         }
       </div>
    )
  }
}

export default withRouter(Dashboard);