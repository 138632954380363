import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";
import {ClipLoader} from "react-spinners";
import Modal from "react-responsive-modal";


const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class DeliveryTerms extends Component {
  state = {
    loading: false,

    data: [],
    dataOriginal: [],

    notCustom: true,
  }

  componentDidMount() {

    this.loadReport();
  }

  loadReport = () => {
    this.setState({loading: true})
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/reports/delivery-terms`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data: {data}}) => {
         console.log(data);
         this.setState({
           dataOriginal: data
         }, () => this.setState({data: this.state.dataOriginal}))
       })
       .catch(err => {
         console.log(err.response);
       })
       .finally(() => this.setState({loading: false}))

  };


  // handleSelect = (e) => {
  //   const {value} = e.target;
  //   if (value === "Custom") {
  //     this.setState({notCustom: false})
  //   }
  //   this.filterPeriod(value);
  //   // const period = {period: value}
  //   this.setState({period: value});
  //   /* console.log(value); */
  //   /* console.log(this.state.period); */
  //   /* console.log(this.state.data); */
  // }
  //
  // filterPeriod = (value) => {
  //   const data = this.state.dataOriginal;
  //   /* console.log(data); */
  //
  //   if (value === "All") {
  //     /* console.log(data); */
  //     this.table_rows = data;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.state.dataOriginal]});
  //   }
  //
  //   if (value === "This Week") {
  //     var ordersThisWeek = [];
  //
  //     for (var i = 0; i < data.length; i++) {
  //       var now = moment();
  //       var input = moment(data[i].created_at);
  //       var isThisWeek = (now.isoWeek() === input.isoWeek())
  //
  //       if (isThisWeek) {
  //         ordersThisWeek.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersThisWeek;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     /* console.log(this.table_rows); */
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "This Month") {
  //     var ordersThisMonth = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //       //var now = moment();
  //       let input = moment(data[i].created_at);
  //       var isThisMonth = input.isSame(new Date(), 'month');
  //
  //       if (isThisMonth) {
  //         ordersThisMonth.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersThisMonth;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "60 Days Ago") {
  //     var ordersSixtyDaysAgo = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //
  //       var sixty_days_ago = moment().subtract(60, 'days');
  //
  //       var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);
  //
  //       if (isBetweenSixtyDays) {
  //         ordersSixtyDaysAgo.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersSixtyDaysAgo;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "90 Days Ago") {
  //     var ordersNinetyDaysAgo = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //
  //       var ninety_days_ago = moment().subtract(90, 'days');
  //
  //       var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);
  //
  //       if (isBetweenNinetyDays) {
  //         ordersNinetyDaysAgo.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersNinetyDaysAgo;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "Custom") {
  //     this.table_rows = data;
  //     this.to_date = null;
  //     this.from_date = null;
  //
  //   }
  // }
  //
  // filterDates = (data) => {
  //   var ordersBetweenTheTwoDates = [];
  //
  //   for (var i = 0; i < data.length; i++) {
  //
  //     var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
  //     /* console.log(isBetweenDates); */
  //     if (isBetweenDates) {
  //       ordersBetweenTheTwoDates.push(data[i]);
  //     }
  //   }
  //
  //   this.table_rows = ordersBetweenTheTwoDates;
  //   //this.notCustom = true;
  //   this.page = 1;
  //   //this.to_date = null;
  //   //this.from_date = null;
  //   /* console.log(this.table_rows); */
  //   return this.table_rows;
  // }
  // // componentDidMount= ()=>{
  // //     'i got called';
  // //     this.props.view("seller",this.props.history);
  // // }
  // handleDate = (e) => {
  //   const {name, value} = e.target;
  //   this.setState({[name]: value});
  //
  // }


  handleFilter = e => {
    const {target: {value}} = e;
    const {dataOriginal: data} = this.state;

    const includes = property => property ? property.toString().toLowerCase().includes(value.toLowerCase()) : false

    if (value === "" || value === null || value === undefined) {
      this.setState({data: this.state.dataOriginal});
      return;
    }


    const filtered = [];

    for (var i = 0; i < data.length; i++) {
      const {
        id,
        user_name,
        user_phone_number,
        SLA,
        cancellation_fee,
        refund_policy,
        card_fee_responsibility,
      } = data[i];
      if (
         includes(id) ||
         includes(user_name) ||
         includes(user_phone_number) ||
         includes(SLA) ||
         includes(cancellation_fee) ||
         includes(refund_policy) ||
         includes(card_fee_responsibility)
      ) {
        filtered.push(data[i]);
      }
    }

    this.setState({data: filtered})
  }

  getUser = (e, userId) => {
    e.preventDefault();
    console.log(userId);

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
        .then(({data}) => {
          this.setState({
            user: data.data,
            showUser: true,
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  }

  render() {

    const columns = [
      {
        Header: props => <span><b>SN</b></span>,
        id: "id",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.id,
        Cell: (props) => {
          return <span>{props.index + 1}</span>;
        }
      },
      {
        Header: props => <span><b>Contact Name</b></span>,
        id: "user_name",
        filterable: false,
        accessor: d => d.user_name,
        Cell: ({original: props}) => {
          return <span>{props.user_name ? <a href="#" onClick={e => this.getUser(e, props.user_id)}>{props.user_name}</a> : "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Contact Phone</b></span>,
        id: "user_phone_number",
        filterable: false,
        accessor: d => d.user_phone_number,
        Cell: ({original: props}) => {
          return <span>{props.user_phone_number || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>SLA</b></span>,
        id: "SLA",
        filterable: false,
        accessor: d => d.SLA,
        Cell: ({original: props}) => {
          return <span>{props.SLA || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Cancellation Fee</b></span>,
        id: "cancellation_fee",
        filterable: false,
        accessor: d => d.cancellation_fee,
        Cell: ({original: props}) => {
          return <span>{props.cancellation_fee || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Refund Policy</b></span>,
        id: "refund_policy",
        filterable: false,
        accessor: d => d.refund_policy,
        Cell: ({original: props}) => {
          return <span>{props.refund_policy || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Payment Fee Responsibility</b></span>,
        id: "card_fee_responsibility",
        filterable: false,
        accessor: d => d.card_fee_responsibility,
        Cell: ({original: props}) => {
          return <span>{props.card_fee_responsibility || "N/A"}</span>;
        }
      },
    ];
    return (
       <div className={'content-wrapper'}>
         <Modal open={this.state.showUser} onClose={() => this.setState({showUser: false})}>
           {
             this.state.user ?
                 <form>
                   <div className="modal-header">
                     <h4 className="modal-title"><small></small></h4>
                   </div>

                   <div className="modal-body">
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>First Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.firstname}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Last Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.lastname}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Phone No.</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.phone_number || "N/A"}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Email</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.email || "N/A"}
                             className="form-control"/></div>
                       </div>
                     </div>
                     {
                       this.state.user.seller &&
                       <>
                         <div className="row">
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Name</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_name || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Email</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_email || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Address</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_address || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Address 2</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_address_2 || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business City</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_city || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business State</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_state || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Business Country</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.business_country || "N/A"}
                                 className="form-control"/></div>
                           </div>
                           <div className="col-sm-6">
                             <div className="form-group"><label>Website</label> <br/> <input
                                 type="text" readOnly="readonly"
                                 value={this.state.user.seller.siteURL || "N/A"}
                                 className="form-control"/></div>
                           </div>
                         </div>
                       </>
                     }
                     <br/><br/>
                   </div>
                 </form> :
                 <ClipLoader
                     loading={true}
                 />
           }
         </Modal>
         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">

               <div className="col-sm-12">
                 <h1 className="m-0 text-dark">Delivery Terms</h1>
               </div>
             </div>
           </div>
         </div>
         <section className="content">
           <div className="container-fluid">
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label htmlFor="period">Period</label>
                           <select className="form-control" name="filter" onChange={this.handleSelect}>
                             <option value="All">All</option>
                             <option value="This Week">This Week</option>
                             <option value="This Month">This Month</option>
                             <option value="60 Days Ago">60 Days Ago</option>
                             <option value="90 Days Ago">90 Days Ago</option>
                             <option value="Custom">Custom</option>
                           </select></div>
                       </div>
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label htmlFor="filters">Filter</label>
                           <input type="text"
                                  name={'filters'}
                                  className={'form-control'}
                                  onChange={this.handleFilter}
                           />
                         </div>
                       </div>
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date From</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="from_date"*/}
                       {/*       value={this.state.from_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date To</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="to_date"*/}
                       {/*       value={this.state.to_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label>&nbsp;</label>
                           <div className="row">
                             <div className="col-12">
                               <button disabled={this.state.notCustom} onClick={() => {
                                 this.setState({data: [...this.filterDates(this.state.dataOriginal)]})
                               }} className="btn btn-info btn-block form-control">
                                 <center>Search</center>
                               </button>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <br/>
                     <div className="table-responsive">
                       <ReactTable
                          loading={this.state.loading}
                          defaultPageSize={5}
                          filterable
                          data={this.state.data}
                          columns={columns}/>

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default DeliveryTerms;