import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";
import {ClipLoader} from "react-spinners";
import Modal from "react-responsive-modal";
import {toLocaleDisplay} from "./OrderDetails";
import transactionHelper from "../../helpers/transaction";


const revenueDisplay = (quantity,curency='NGN') => {
    if(quantity === undefined)
        quantity = 0;

    return quantity.toLocaleString(undefined, {style: 'currency', currency: curency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2})
}

const formatToSixDigits = (number) => {
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;
    }
    return output;
}

class RevenueDetails extends Component {
    state = {
        loading: false,

        data: [],
        dataOriginal: [],

        notCustom: true,
    }

    componentDidMount() {

        this.loadReport();
    }

    loadReport = () => {
        this.setState({loading: true})
        axios({
            method: 'get',
            mode: 'no-cors',
            url: `${BASE_URL}admin/reports/revenue-details`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(({data: {data}}) => {

                this.setState({
                    dataOriginal: data.map(d => ({...d, escrow_fee: parseFloat(d.escrow_fee)}))
                }, () => this.setState({data: this.state.dataOriginal}))
            })
            .catch(err => {
                console.log(err.response);
            })
            .finally(() => this.setState({loading: false}))

    };


    handleSelect = (e) => {
        const {value} = e.target;
        if (value === "Custom") {
            this.setState({notCustom: false})
        }
        this.filterPeriod(value);
        // const period = {period: value}
        this.setState({period: value});
        /* console.log(value); */
        /* console.log(this.state.period); */
        /* console.log(this.state.data); */
    }

    filterPeriod = (value) => {
        const data = this.state.dataOriginal;
        /* console.log(data); */

        if (value === "All") {
            /* console.log(data); */
            this.table_rows = data;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.state.dataOriginal]});
        }

        if (value === "This Week") {
            var ordersThisWeek = [];

            for (var i = 0; i < data.length; i++) {
                var now = moment();
                var input = moment(data[i].created_at);
                var isThisWeek = input.isSame(now, "week");

                if (isThisWeek) {
                    ordersThisWeek.push(data[i]);
                }
            }

            this.table_rows = ordersThisWeek;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            /* console.log(this.table_rows); */
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "This Month") {
            var ordersThisMonth = [];

            for (let i = 0; i < data.length; i++) {
                //var now = moment();
                let input = moment(data[i].created_at);
                var isThisMonth = input.isSame(new Date(), 'month');
                if (isThisMonth) {
                    ordersThisMonth.push(data[i]);
                }
            }

            this.table_rows = ordersThisMonth;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "60 Days Ago") {
            var ordersSixtyDaysAgo = [];

            for (let i = 0; i < data.length; i++) {

                var sixty_days_ago = moment().subtract(60, 'days');

                var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);

                if (isBetweenSixtyDays) {
                    ordersSixtyDaysAgo.push(data[i]);
                }
            }

            this.table_rows = ordersSixtyDaysAgo;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "90 Days Ago") {
            var ordersNinetyDaysAgo = [];

            for (let i = 0; i < data.length; i++) {

                var ninety_days_ago = moment().subtract(90, 'days');

                var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);

                if (isBetweenNinetyDays) {
                    ordersNinetyDaysAgo.push(data[i]);
                }
            }

            this.table_rows = ordersNinetyDaysAgo;
            this.notCustom = true;
            this.page = 1;
            this.to_date = null;
            this.from_date = null;
            this.setState({notCustom: true, data: [...this.table_rows]});
        }

        if (value === "Custom") {
            this.table_rows = data;
            this.to_date = null;
            this.from_date = null;

        }
    }

    filterDates = (data) => {
        var ordersBetweenTheTwoDates = [];

        for (var i = 0; i < data.length; i++) {

            var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
            /* console.log(isBetweenDates); */
            if (isBetweenDates) {
                ordersBetweenTheTwoDates.push(data[i]);
            }
        }

        this.table_rows = ordersBetweenTheTwoDates;
        //this.notCustom = true;
        this.page = 1;
        //this.to_date = null;
        //this.from_date = null;
        /* console.log(this.table_rows); */
        return this.table_rows;
    }
    // componentDidMount= ()=>{
    //     'i got called';
    //     this.props.view("seller",this.props.history);
    // }
    handleDate = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});

    }


    handleFilter = e => {
        const {target: {value}} = e;
        const {dataOriginal: data} = this.state;

        const includes = property => property ? property.toString().toLowerCase().includes(value.toLowerCase()) : false

        if (value === "" || value === null || value === undefined) {
            this.setState({data: this.state.dataOriginal});
            return;
        }


        const filtered = [];

        for (var i = 0; i < data.length; i++) {
            const {
                id,
                gateway,
                escrow_fee,
                service_type,
                created_at,
                amount_payed,
            } = data[i];
            if (
                includes(id) ||
                includes(gateway) ||
                includes(escrow_fee) ||
                includes(service_type) ||
                includes(amount_payed) ||
                includes(created_at)
            ) {
                filtered.push(data[i]);
            }
        }

        this.setState({data: filtered})
    }

    getOrder = (e, orderId) => {
        e.preventDefault();

        axios({
            method: 'get',
            mode: 'no-cors',
            url: `${BASE_URL}admin/orders/${orderId}`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(({data}) => {
                this.setState({
                    order: data.data,
                    showOrder: true,
                })
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    render() {

        const columns = [
            {
                Header: props => <span><b>Transaction ID</b></span>,
                id: "id",
                filterable: false,
                maxWidth: 150,
                Cell: props => {
                    return <span><a href="#"
                                    onClick={e => this.getOrder(e, props.original.id)}>{formatToSixDigits(props.original && props.original.id)}</a></span>;
                }
            },
            {
                Header: props => <span><b>Gateway</b></span>,
                id: "gateway",
                filterable: false,
                accessor: d => d.gateway,
                Cell: p => {

                    return <span> {p.original.gateway || "N/A"}</span>;
                },
            },
            // {
            //   Header: props => <span><b>Revenue</b></span>,
            //   id: "amount_payed",
            //   filterable: false,
            //   accessor: d => d.amount_payed,
            //   Cell: p => {
            //
            //     return <span>₦ {p.original.amount_payed ? (p.original.amount_payed  / 100).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2,}) : "N/A"}</span>;
            //   },
            // },
            {
                Header: props => <span><b>Escrow Fee</b></span>,
                id: "escrow_fee",
                filterable: false,
                accessor: d => (d.amount_payed - d.amount_net),
                Cell: p => {
                    const transaction = {
                        ...p.original,
                        // additional_service_amount: p.original.additional_service_amount || 0,
                        // escrow_fee_bearer: 'customer',
                        // amount_payed: p.original.amount_net,
                    };
                    //
                    // let revenue = data.amount_payed - data.amount_net;
                    // if(revenue < 0){
                    //     revenue  = 0;
                    // }

                    return <span>
                        {transaction && '₦ ' + (transactionHelper.calculateTransactionEscrow(
                           (transaction.amount_net + (transaction.shipping_cost * (transaction && transaction.shipping_fee_bearer === "both" ? 0.5 :
                              transaction && transaction.shipping_fee_bearer === "seller" ? 0 : 1))) / 100) * (transaction && transaction.escrow_fee_bearer === "both" ? 0.5 : 1)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ''}
                    </span>
                    // return <span>{revenueDisplay(parseFloat(revenue)/100)}</span>;
                },
            },
            {
                Header: props => <span><b>Amount Paid</b></span>,
                id: "amount_payed",
                filterable: false,
                accessor: d => d.amount_payed,
                Cell: p => {

                    return <span>{revenueDisplay(parseFloat(p.original.amount_payed)/100)}</span>;
                },
            },
            {
                Header: props => <span><b>Service Type</b></span>,
                id: "service_type",
                filterable: false,
                accessor: d => d.service_type,
                Cell: p => {

                    return <span>{p.original.service_type || "N/A"}</span>;
                },
            },
            {
                Header: props => <span><b>Date</b></span>,
                id: "created_at",
                filterable: false,
                accessor: d => d.created_at,
                Cell: p => {

                    return <span>{p.original.created_at}</span>;
                },
            }

        ];
        return (
            <div className={'content-wrapper'}>
                <Modal open={this.state.showOrder} onClose={() => this.setState({showOrder: false})}>
                    {
                        this.state.order ?
                            <form>
                                <div className="modal-header">
                                    <h4 className="modal-title"><small></small></h4>
                                </div>

                                <div className="modal-body">
                                    <div className="row"></div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Order Number</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={formatToSixDigits(this.state.order && this.state.order.id)}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Amount</label><br/>
                                                <input type="text" readOnly="readonly"
                                                       value={this.state.order && toLocaleDisplay(parseFloat(this.state.order.amount_payed / 100))}
                                                       className="form-control"/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Order Date</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.created_at}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Order Status</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.status.title}
                                                className="form-control"/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Buyer Name</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Seller Name</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.user && this.state.order.user.firstname + " " + this.state.order.user.lastname}
                                                className="form-control"/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Delivery Location</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.delivery_location || "N/A"}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Delivery Date</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.delivery_date || "N/A"}
                                                className="form-control"/></div>
                                        </div>
                                    </div>
                                    <br/><br/>
                                    {this.state.order.extra ?
                                        <div className="row">

                                            {this.state.order && Object.keys(this.state.order.extra).map(extra => (
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>{extra.charAt(0).toUpperCase() + extra.slice(1).replace(/_/g, " ")}</label>
                                                        <br/> <input type="text" readOnly="readonly"
                                                                     value={this.state.order.extra[extra]}
                                                                     className="form-control"/></div>
                                                </div>
                                            ))}
                                        </div> : null}
                                    <br/><br/>
                                </div>
                            </form> :
                            <ClipLoader
                                loading={true}
                            />
                    }
                </Modal>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">

                            <div className="col-sm-12">
                                <h1 className="m-0 text-dark">Revenue Details</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="alert animated bounceIn alert-danger d-none search-alert">
                                    <a aria-label="close" className="close alert-close">×</a>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-sm-2">
                                                <div className="form-group"><label htmlFor="period">Period</label>
                                                    <select className="form-control" name="filter"
                                                            onChange={this.handleSelect}>
                                                        <option value="All">All</option>
                                                        <option value="This Week">This Week</option>
                                                        <option value="This Month">This Month</option>
                                                        <option value="60 Days Ago">60 Days Ago</option>
                                                        <option value="90 Days Ago">90 Days Ago</option>
                                                        <option value="Custom">Custom</option>
                                                    </select></div>
                                            </div>
                                            <div className="col-12 col-sm-2">
                                                <label htmlFor="filterr">Filter</label>
                                                <input
                                                    type="text"
                                                    name="filterr"
                                                    onChange={this.handleFilter}
                                                    className="form-control"/>

                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="from">Date From</label>
                                                    <input
                                                        type="date"
                                                        name="from_date"
                                                        value={this.state.from_date}
                                                        disabled={this.state.notCustom}
                                                        onChange={this.handleDate}
                                                        className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="from">Date To</label>
                                                    <input
                                                        type="date"
                                                        name="to_date"
                                                        value={this.state.to_date}
                                                        disabled={this.state.notCustom}
                                                        onChange={this.handleDate}
                                                        className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2">
                                                <div className="form-group"><label>&nbsp;</label>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <button disabled={this.state.notCustom} onClick={() => {
                                                                this.setState({data: [...this.filterDates(this.state.dataOriginal)]})
                                                            }} className="btn btn-info btn-block form-control">
                                                                <center>Search</center>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="table-responsive">
                                            <ReactTable
                                                loading={this.state.loading}
                                                defaultPageSize={5}
                                                filterable
                                                data={this.state.data}
                                                columns={columns}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default RevenueDetails;