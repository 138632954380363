import {userService} from "../service.js";
import {userConstants, deliveryConstants} from "../constants";
import {alertActions, userActions} from "./";

const getAdmins = (history) => {
  return dispatch => {

    userService.user.getAdmins()
       .then(res => {
         if(res.data.status === 'success') {
           dispatch({
             type: 'ADMIN_LIST_SUCCESS',
             data: res.data,
           })
         }
       })
       .catch(err => {
         if (err.response) {
           if (err.response.status === 401) {
             dispatch(userActions.user.logout(history));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           } else {
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
         } else {
           console.log(err.message);
         }

       })
  }
}

const getDetails = (history) => {
  return dispatch => {
    // console.log();

    dispatch(request());

    userService.user.getUser()
       .then(
          res => {
            // console.log(res);
            if (res.data.status === "success") {
              dispatch(success(res.data.data));
            } else {
              dispatch(failure(res.data.message));
              dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
            }

          }
       ).catch(
       err => {
         console.log(err);
         if (err.response) {
           if (err.response.status === 401) {
             dispatch(userActions.user.logout(history));
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           } else {
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
         } else {
           console.log(err.message);
           dispatch(failure(err.message));
           // dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
         }

         console.log(err.config);

       })
  };


  function request() {
    return {type: userConstants.user.GETALL_REQUEST}
  }

  function success(user) {
    return {type: userConstants.user.GETALL_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.user.GETALL_FAILURE, error}
  }
}



const login = (credentials, history) => {
  return (dispatch) => {
    dispatch(request(credentials));

    userService.user.login(credentials, dispatch)
       .then(
          res => {
            if (res.data.status === "success") {
              dispatch(success(res.data.data.token));
              localStorage.setItem("user", res.data.data.token);

              history.push('/');

            } else {

              dispatch(failure(res.data.message));
              dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));


            }
          }
       ).catch(
       err => {
         if (err.response) {
           if (err.response.status === 401) {
             // dispatch(logout(history))

             if (err.response.data.message !== "invalid_credentials") {
               // dispatch(failure(err.response.data.message));
               dispatch(alertActions.alert.error(("unverified").replace(/_/g, " ")));
               dispatch(unverified(credentials.email));
             } else if (err.response.data.message === "invalid_credentials") {
               dispatch(failure(err.response.data.message));
               dispatch(alertActions.alert.error(("Invalid credentials".replace(/_/g, " "))));
             } else {
               dispatch(failure(err.response.data.message));
               dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
             }

           } else {
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error(("Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you.")));
         } else {
           console.log(err.message)
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
         }

         console.log(err.config)

       })
  };

  function request(user) {
    return {type: userConstants.user.LOGIN_REQUEST, user}
  };

  function success(user) {
    return {type: userConstants.user.LOGIN_SUCCESS, user}
  };

  function unverified(error) {
    return {type: userConstants.user.LOGIN_UNVERIFIED, error}
  };

  function failure(error) {
    return {type: userConstants.user.LOGIN_FAILURE, error}
  };
}


const logout = (type, history) => {
  return (dispatch) => {
    userService.user.logout();
    dispatch({type: userConstants.user.LOGOUT});
    if (type === "seller") {
      history.push(`/login/${type}`);
    } else if (type === "none") {
      console.log("ready...");
    } else {
      history.push(`/login/buyer`);
    }

  }
}

const checkVerified = (data, history) => {
  return dispatch => {
    // console.log(history);
    dispatch(request(data));
    // userService.user.checkVerified(data)
    //    .then(
    //       res => {
    //         if (res.data.status === "success") {
    //           dispatch(success(res.data.message));
    //           if (res.data.message === "customer_verified") {
    //             history.push('/login/buyer');
    //           }
    //         } else {
    //           dispatch(failure(res.data.message));
    //           if (res.data.message === "customer_not_found") {
    //             if (data.ref !== "p2p-buyer" && data.ref !== "p2p-seller")
    //               history.push('/login/buyer');
    //           } else if (res.data.message === "not_verified") {
    //             dispatch(alertActions.alert.error(("Your phone number and email address is yet to be verified. To verify, please check your email for your verification link").replace(/_/g, " ")));
    //             history.push('/login/buyer');
    //           }
    //         }
    //
    //       }
    //    ).catch(
    //    err => {
    //      if (err.response) {
    //        console.log(err.response);
    //
    //        dispatch(failure(err.response.data.message));
    //        // dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
    //        // dispatch(alertActions.alert.error((err.response.data.message).replace(/\\n/g, " ")));
    //
    //
    //      } else if (err.request) {
    //        console.log(err.request);
    //        console.log(err.message);
    //        dispatch(failure(err.message));
    //        // dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
    //        // dispatch(alertActions.alert.error((err.message).replace(/\\n/g, " ")));
    //      } else {
    //        console.log(err.message)
    //        dispatch(failure(err.message));
    //        // dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
    //        // dispatch(alertActions.alert.error((err.message).replace(/\\n/g, " ")));
    //      }
    //
    //      console.log(err.config)
    //
    //    })
  };

  function request(data) {
    return {type: userConstants.user.GET_VERIFY_REQUEST, data}
  }

  function success(msg) {
    return {type: userConstants.user.GET_VERIFY_SUCCESS, msg}
  }

  function failure(error) {
    return {type: userConstants.user.GET_VERIFY_FAILURE, error}
  }
}

// const resendEmail = (email, type, history) => {
//   return dispatch => {
//     // console.log(history);
//     dispatch(request(email));
//     userService.user.resendEmail(email, type)
//        .then(
//           res => {
//             if (res.data.status === "success") {
//               dispatch(success(res.data.data.email));
//               dispatch(alertActions.alert.success((res.data.message)));
//             } else {
//               dispatch(failure(res.data.message));
//               dispatch(alertActions.alert.error(("An error occurred, please try later")));
//             }
//
//           }
//        ).catch(
//        err => {
//          if (err.response) {
//            console.log(err.response);
//
//            dispatch(failure(err.response.data.message));
//            // dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
//            // dispatch(alertActions.alert.error((err.response.data.message).replace(/\\n/g, " ")));
//
//
//          } else if (err.request) {
//            console.log(err.request);
//            console.log(err.message);
//            dispatch(failure(err.message));
//            // dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
//            // dispatch(alertActions.alert.error((err.message).replace(/\\n/g, " ")));
//          } else {
//            console.log(err.message)
//            dispatch(failure(err.message));
//            // dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
//            // dispatch(alertActions.alert.error((err.message).replace(/\\n/g, " ")));
//          }
//
//          console.log(err.config)
//
//        })
//   };
//
//   function request(data) {
//     return {type: userConstants.user.RESEND_EMAIL_REQUEST, data}
//   }
//
//   function success(msg) {
//     return {type: userConstants.user.RESEND_EMAIL_SUCCESS, msg}
//   }
//
//   function failure(error) {
//     return {type: userConstants.user.RESEND_EMAIL_FAILURE, error}
//   }
// }


const viewDashboard = (type, history) => {
  return (dispatch) => {
    dispatch(request());

    userService.user.getDashboard(type)
       .then(
          res => {
            if (res.data.status === "success") {
              dispatch(success(res.data));
            } else {
              dispatch(failure(res.data.message));
              // dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
            }

          }
       ).catch(
       err => {
         if (err.response) {
           if (err.response.status === 401) {
             dispatch(userActions.user.logout(type, history));
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           } else {
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error("Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."));
         } else {
           console.log(err.message)
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
         }

         console.log(err.config)

       })
  };

  function request() {
    return {type: userConstants.user.DASHBOARD_DETAILS_REQUEST}
  };

  function success(dashboard) {
    return {type: userConstants.user.DASHBOARD_DETAILS_SUCCESS, dashboard: dashboard}
  };

  function failure(error) {
    return {type: userConstants.user.DASHBOARD_DETAILS_FAILURE, error}
  };

}


const getDispatchers = (type, history) => {

  return (dispatch) => {
    dispatch(request());

    userService.user.getDispatchers(type)
       .then(
          res => {

            if (res.data.status === "success") {
              dispatch(success(res.data.data));
            } else {
              dispatch(failure(res.data.message));
              // dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
            }

          }
       ).catch(
       err => {
         if (err.response) {
           if (err.response.status === 401) {
             dispatch(userActions.user.logout(type, history));
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           } else {
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
         } else {
           console.log(err.message)
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
         }

         console.log(err.config)

       })
  };

  function request() {
    return {type: deliveryConstants.delivery.GET_DELIVERY_MEN}
  };

  function success(deliveryMen) {
    return {type: deliveryConstants.delivery.GET_DELIVERY_MEN_SUCCESS, deliveryMen}
  };

  function failure(error) {
    return {type: deliveryConstants.delivery.GET_DELIVERY_MEN_FAILURE, error}
  };

}


const updatePassword = (type, data, history) => {

  return (dispatch) => {
    dispatch(request());
    type === "marketplace" ? type = 'seller' : type = type;

    userService.user.updatePassword(type, data)
       .then(
          res => {

            if (res.data.status === "success") {
              dispatch(success());
              dispatch(alertActions.alert.success((res.data.message).replace(/_/g, " ")));
            } else {
              dispatch(failure(res.data.message));
              dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
              dispatch(alertActions.alert.error((res.data.message).replace(/\\n/g, " ")));
            }

          }
       ).catch(
       err => {
         if (err.response) {
           if (err.response.status === 401) {
             dispatch(userActions.user.logout(type, history));
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/\\n/g, " ")));
           } else {
             dispatch(failure(err.response.data.message));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/_/g, " ")));
             dispatch(alertActions.alert.error((err.response.data.message).replace(/\\n/g, " ")));
           }
         } else if (err.request) {
           console.log(err.request);
           console.log(err.message);
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error("Connection Error\n\nYou are experiencing a connection error that may be due to the following:\n1. Poor mobile internet connection\n2. Poor Wi-Fi connection\n3. Unstable network cable connection\n\nPlease fix the above and try again. Thank you."));
           // dispatch(alertActions.alert.error((err.message).replace(/\\n/g, " ")));
         } else {
           console.log(err.message)
           dispatch(failure(err.message));
           dispatch(alertActions.alert.error((err.message).replace(/_/g, " ")));
           dispatch(alertActions.alert.error((err.message).replace(/\\n/g, " ")));
         }

         console.log(err.config)

       })
  };

  function request() {
    return {type: userConstants.user.PASSWORD_CHANGE_REQUEST}
  };

  function success() {
    return {type: userConstants.user.PASSWORD_CHANGE_SUCCESS}
  };

  function failure(error) {
    return {type: userConstants.user.PASSWORD_CHANGE_FAILURE, error}
  };

}

export const user = {
  login,
  logout,
  checkVerified,
  getAdmins,
  // resendEmail,
  getDetails,
  viewDashboard,
  getDispatchers,
  // forgotPassword,
  // confirmBuyer,
  // resetPassword,
  // register,
  // updateProfile,
  // updateTour,
  updatePassword,
  // goLive,
  // genKey,
  // viewKey,
  // getOtp,
  // submitOtp,
  // submitBusiness,
  // bvnPay
};