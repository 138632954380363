import {combineReducers} from 'redux';
import auth from './auth';
import register from './register';
import payout from './payout';
import support from './support';
import terms from './terms';
import settings from './settings';
import orders from './order';
import users from './user_details';
import transaction from './transaction';
import {alertReducer as alert} from './alert';

let rootReducer = combineReducers({
   auth,
   register,
   alert,
   orders,
   payout,
   terms,
   users,
   support,
   settings,
   transaction
});

export default rootReducer;