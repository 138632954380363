import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import PayoutHistory from '../views/payoutHistory';
import { payoutActions } from '../actions';
// import { payoutActions } from '../actions';

const mapStateToProps = (state) =>{
    const { loading, payouts} = state.payout;
    const { user } = state.users;
    

    const alert  = state.alert;
    
    return {
        alert,
        loading,
        payouts,
        user,
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
    dispatch
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PayoutHistory));