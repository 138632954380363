import { BASE_URL } from '../constants';
import axios from 'axios';

import { alertActions } from '../actions';


const getAdmins = () => {
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}admin/list_admins`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const getUser=()=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}admin`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

 const login=(credentials,dispatch)=>{
  
   return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL}admin/login`,
        data: credentials,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })

}

const viewKey=(type,dispatch)=>{
  
    return  axios({
         method: 'get',
         mode: 'no-cors',
         url : `${BASE_URL+ type}/pluginkey`,
         headers: {
             'Access-Control-Allow-Origin': '*',
             'Authorization':'Bearer '+localStorage.getItem("user"),
             'Content-Type': 'application/json',
         }
     })
 
 }

 const genKey=(type,data,dispatch)=>{
  
    return  axios({
         method: 'put',
         mode: 'no-cors',
         url : `${BASE_URL+ type}/pluginkey/generate`,data,
         headers: {
             'Access-Control-Allow-Origin': '*',
             'Authorization':'Bearer '+localStorage.getItem("user"),
             'Content-Type': 'application/json',
         }
     })
 
 }

const goLive = (type, data, process, dispatch) => {

    console.log("con", process);

    if(process){
        return axios({
            method: 'post',
            mode: 'no-cors',
            url: `${BASE_URL + type}/pluginkey/goLive`, data,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
    }
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/pluginkey/demoMode`, data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })

}

function logout() {
    // remove user from local storage to log user out
    console.log('logging out')
   localStorage.removeItem('user');
   localStorage.removeItem("trust_user_type");
}

const checkVerified = (data) => {
    console.log('checking verified')
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + 'admin/getinfo'}`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const resendEmail = (data, type) => {
    if (type === "buyer")
        type = "customer";
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL + type}/resendEmail`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const register = (data,type)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/register`,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

const getDispatchers = (type)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/deliverymen  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}



const getOtp = (type,token)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL+type}/sendToken/${token}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

const confirmCustomer = (token)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}customer/confirmation/${token}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

const submitOtp = (type,otp,token)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL+type}/confirmation/${token}/${otp}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

const bvnPay = ()=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}seller/bvnpayment`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+localStorage.getItem("user"),
        }
    })
}

const submitBusiness = (data)=> {
    if( data.individual === 1) {
        return  axios({
            method: 'post',
            mode: 'no-cors',
            url : `${BASE_URL}seller/business`,
            data,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization':'Bearer '+localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
    } else {
        let formData = new FormData();
        // formData.append('cac_certificate', data.cac_certificate, data.cac_certificate, data.cac_certificate.name);
        formData.append('memorandum_of_article', data.memorandum_of_article, data.memorandum_of_article, data.memorandum_of_article.name);
        // formData.append('company_profile', data.company_profile, data.company_profile, data.company_profile.name);
        formData.append('is_marketplace', data.is_marketplace);
        formData.append('marketplace_child', data.marketplace_child);
        formData.append('business_name', data.business_name);
        formData.append('business_email', data.business_email);
        formData.append('business_address', data.business_address);
        formData.append('business_address_2', data.business_address_2);
        formData.append('business_city', data.business_city);
        formData.append('business_state', data.business_state);
        formData.append('business_country', data.business_country);
        formData.append('individual', data.individual);
        formData.append('siteURL', data.siteURL);
        formData.append('BVN', data.BVN);
        formData.append('year_of_inc', data.year_of_inc);

        return axios.post(`${BASE_URL}seller/business`, formData, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization':'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
    }
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL}seller/business`,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}


const getDashboard = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/dashboard  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}
const updateProfile = (type,data,history)=>{
    return  axios({
        method: 'put',
        mode: 'no-cors',
        
        url : `${BASE_URL+ type}/account/update`,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const updatePassword = (type,data,history)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        
        url : `${BASE_URL+ type}/changePassword`,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const forgotPassword = (type,data)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        
        url : `${BASE_URL+ type}/forgot`,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

const resetPassword = (type,data)=>{
    if (type === "buyer")
        type = "customer";
    return  axios({
        method: 'post',
        mode: 'no-cors',
        
        url : `${BASE_URL+ type}/forgot/reset`,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}



// function getUser(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }

// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
// }

// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
// }
export const user = {
    login,
    getAdmins,
    register,
    checkVerified,
    resendEmail,
    getUser,
    logout,
    viewKey,
    genKey,
    goLive,
    getOtp,
    submitOtp,
    submitBusiness,
    getDashboard,
    confirmCustomer,
    getDispatchers,
    updateProfile,
    updatePassword,
    forgotPassword,
    resetPassword,
    bvnPay
};


