import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import axios from "axios";
import {
  PAYMENT_METHODS,
  PAYMENT_METHOD_PAYSTACK_ID,
  PAYMENT_METHOD_USSD_ID,
  TRANSACTION_CURRENCIES,
  TRANSACTION_CURRENCY_NGN,
  TRANSACTION_CURRENCY_USD,
  TRANSACTION_REFUND_POLICES,
  TRANSACTION_REFUND_POLICY_REFUND_ID,
  TRANSACTION_SCENARIOS,
  TRANSACTION_SCENARIO_ACCEPTANCE_ID,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_ECOMMERCE_ID,
  TRANSACTION_TYPE_P2P_ID,
} from "../../constants/transaction";
import {
  composeValidators,
  mustBeNumber,
  required,
} from "../../helper/validation";
import {
  ALL_FEE_BEARERS,
  BUYER_FEE_BEARER_ID,
  FEE_BEARERS,
  NO_SELECT_OPTION_ID,
  SELLER_FEE_BEARER_ID,
  SELLER_OR_BOTH_FEE_BEARERS,
  YES_NO_SELECT_OPTIONS,
} from "../../constants/fee-bearer";
import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";
import Button from "../../components/Button";
import { BASE_URL } from "../../../../constants";
import COLORS from "../../theme/colors";
import convertNairaPropsToKoboProps from "../../helper/convertNairaToKobo";
const FormContainer = styled.form``;
const FormSection = styled.section`
  background-color: #f2f0f0;
  margin: 2rem 1rem;
  padding: 1rem;
`;
const TransactionFormSection = styled(FormSection)``;
const FeeBearerFormSection = styled(FormSection)``;
const ProductFormSection = styled(FormSection)``;
const DeliveryTermFormSection = styled(FormSection)``;
const PaymentGatewayFormSection = styled(FormSection)``;
const EscrowFormSection = styled(FormSection)``;
const OthersFormSection = styled(FormSection)``;
const ErrorAlertSection = styled(FormSection)`
  background-color: ${COLORS.errorColor};
  color: #fff;
  padding-bottom: 0.03rem;
`;
const ButtonSection = styled(FormSection)`
  background-color: transparent;
  padding: 0;
`;
const FormSectionTitle = styled.h4``;
const Divider = styled.div`
  background-color: #817b7b;
  height: 0.1rem;
`;
const GridContainer = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1rem;
`;
const GridItem = styled.div``;
const TransactionCalculatorSimulatorInputWizard = ({
  handleSetOutputResult,
}) => {
  const initialValues = {
    txType: TRANSACTION_TYPE_ECOMMERCE_ID,
    txScenario: TRANSACTION_SCENARIO_ACCEPTANCE_ID,
    paymentMethod: PAYMENT_METHOD_USSD_ID,
    escrowFeeBearer: BUYER_FEE_BEARER_ID,
    transferFeeBearer: SELLER_FEE_BEARER_ID,
    productCost: 60519,
    currency: TRANSACTION_CURRENCY_NGN,
    escrowPercent: 1.5,
    minimumEscrowFee: 500,
    logisticsFee: 5,
    maximumCancellationFee: 5000,
    refundOption: TRANSACTION_REFUND_POLICY_REFUND_ID,
    nonSplitAmount: 0,
    transferFee: 40,
    cardFeePercent: 0.75,
    minimumCardFee: 0,
    maximumCardFee: 200,
    additionalFee: 0,
    maximumEscrowFee: 2000,
    partPayment: NO_SELECT_OPTION_ID,
  };
  const [errors, setErrors] = useState([]);
  const handleOnSubmit = async (props) => {
    const propsData = convertNairaPropsToKoboProps(props);
    try {
      // const response = await simulatePayment(props);
      const response = await axios.post(
        `${BASE_URL}admin/simulatePayment`,
        propsData,
        {
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("user"),
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = response;
      const { status, message, data: resultData } = data;
      if (status === "error") {
        const errors = message.split("\\n");
        setErrors(errors);
      } else {
        const { simulatorOutputs, generalCalculatedResult } = resultData;

        handleSetOutputResult({
          ...simulatorOutputs,
          conversionRate: generalCalculatedResult?.conversionRate,
          transaction: generalCalculatedResult?.transaction,
          term: generalCalculatedResult?.term,
        });
      }
    } catch (error) {
      setErrors([
        "Oops! Something went wrong, kindly contact the Atara Support if issue persit",
      ]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      mutators={{
        changePaymentMethod: ([paymentMethod], state, { changeValue }) => {
          const { formState } = state;
          const { values } = formState;
          const { currency } = values;
          if (paymentMethod === PAYMENT_METHOD_PAYSTACK_ID) {
            if (currency === TRANSACTION_CURRENCY_USD) {
              changeValue(state, "cardFeePercent", () => 3.9);
              changeValue(state, "maximumCardFee", () => "");
              changeValue(state, "maximumProductAmount", () => 0);
            } else {
              changeValue(state, "cardFeePercent", () => 1.5);
              changeValue(state, "maximumCardFee", () => 2000);
              changeValue(state, "maximumProductAmount", () => 2500);
            }
            changeValue(state, "transferFee", () => 50);
            changeValue(state, "minimumCardFee", () => 0);

            changeValue(state, "additionalFee", () => 100);
          }
          if (paymentMethod === PAYMENT_METHOD_USSD_ID) {
            changeValue(state, "cardFeePercent", () => 0.75);
            changeValue(state, "transferFee", () => 40);
            changeValue(state, "minimumCardFee", () => 0);
            changeValue(state, "maximumCardFee", () => 200);
            changeValue(state, "additionalFee", () => 0);
            changeValue(state, "maximumProductAmount", () => 0);
          }
        },
        changeTrxType: ([trxType], state, { changeValue }) => {
          if (trxType === TRANSACTION_TYPE_ECOMMERCE_ID) {
            changeValue(state, "maximumEscrowFee", () => 2000);
          }
          if (trxType === TRANSACTION_TYPE_P2P_ID) {
            changeValue(state, "maximumEscrowFee", () => 50000);
          }
        },
        changeCurrency: ([currency], state, { changeValue }) => {
          const { formState } = state;
          const { values } = formState;
          const { paymentMethod } = values;

          if (currency === TRANSACTION_CURRENCY_NGN) {
            if (paymentMethod === PAYMENT_METHOD_PAYSTACK_ID) {
              changeValue(state, "maximumCardFee", () => 2000);
              changeValue(state, "cardFeePercent", () => 1.5);
              changeValue(state, "maximumProductAmount", () => 2500);
            }
          }
          if (currency === TRANSACTION_CURRENCY_USD) {
            if (paymentMethod === PAYMENT_METHOD_PAYSTACK_ID) {
              changeValue(state, "cardFeePercent", () => 3.9);
              changeValue(state, "maximumCardFee", () => "");
              changeValue(state, "maximumProductAmount", () => 0);
            }
          }
        },
      }}
    >
      {({ handleSubmit, pristine, submitting, values, form }) => {
        return (
          <FormContainer onSubmit={handleSubmit}>
            <TransactionFormSection>
              <FormSectionTitle>Transaction</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field name='txType' type='select' validate={required}>
                    {({ input, meta }) => {
                      return (
                        <SelectField
                          label='Transaction Type'
                          items={TRANSACTION_TYPES}
                          input={{
                            ...input,
                            onChange: (e) => {
                              input.onChange(e);
                              form.mutators.changeTrxType(e?.target?.value);
                            },
                          }}
                          meta={meta}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field name='txScenario' type='select' validate={required}>
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='Transaction Scenario'
                          items={TRANSACTION_SCENARIOS}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field name='paymentMethod' type='select' validate={required}>
                    {({ input, meta }) => {
                      return (
                        <SelectField
                          label='Payment Method'
                          items={PAYMENT_METHODS}
                          input={{
                            ...input,
                            onChange: (e) => {
                              input.onChange(e);
                              form.mutators.changePaymentMethod(
                                e?.target?.value
                              );
                            },
                          }}
                          meta={meta}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </TransactionFormSection>
            <FeeBearerFormSection>
              <FormSectionTitle>Fee Bearer</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field
                    name='escrowFeeBearer'
                    type='select'
                    validate={required}
                  >
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='Escrow Fee Bearer'
                          items={ALL_FEE_BEARERS}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field name='shippingFeeBearer' type='select'>
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='Shipping Fee Bearer'
                          items={ALL_FEE_BEARERS}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='transferFeeBearer'
                    type='select'
                    validate={required}
                  >
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='Switch Fee bearer'
                          items={FEE_BEARERS}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field name='spTransferFeeBearer' type='text'>
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='SP Transfer Fee Bearer'
                          items={SELLER_OR_BOTH_FEE_BEARERS}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </FeeBearerFormSection>
            <ProductFormSection>
              <FormSectionTitle>Product</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field
                    name='productCost'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Product Cost'
                          labelDescription='Product cost'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='shippingCost'
                    type='text'
                    validate={mustBeNumber}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Shipping Cost'
                          labelDescription='Shipping cost'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field name='currency' type='select' validate={required}>
                    {({ input, meta }) => {
                      return (
                        <SelectField
                          label='Currency'
                          items={TRANSACTION_CURRENCIES}
                          input={{
                            ...input,
                            onChange: (e) => {
                              input.onChange(e);
                              form.mutators.changeCurrency(e?.target?.value);
                            },
                          }}
                          meta={meta}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='sellerCommision'
                    type='text'
                    validate={mustBeNumber}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Seller Commission'
                          labelDescription='Commission in percentage (e.g 5 NOT 0.05)'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </ProductFormSection>
            <DeliveryTermFormSection>
              <FormSectionTitle>Delivery Terms</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field
                    name='logisticsFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Logistic Fee'
                          labelDescription='Cancellation in percentage (e.g 5 NOT 0.05)'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='maximumCancellationFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Maximum Cancellation Fee'
                          labelDescription='Maximum Cancellation fee'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field name='refundOption' type='select' validate={required}>
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='Refund Policy'
                          items={TRANSACTION_REFUND_POLICES}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='nonSplitAmount'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Non Split Amount'
                          labelDescription='Non Spit Amount'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </DeliveryTermFormSection>
            <PaymentGatewayFormSection>
              <FormSectionTitle>Payment Gateway</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field
                    name='cardFeePercent'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Card Fee'
                          labelDescription='Card Fee in percentage (e.g 5 NOT 0.05)'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='transferFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Transfer Fee'
                          labelDescription='transfer Fee'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='minimumCardFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Minimum Card Fee'
                          labelDescription='Min card fee'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='maximumCardFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Maximum Card Fee'
                          labelDescription='Max card fee'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='additionalFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Additionl Charge'
                          labelDescription='Additional charges'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='maximumProductAmount'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Reference Charge'
                          labelDescription='Paystack reference charge'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </PaymentGatewayFormSection>
            <EscrowFormSection>
              <FormSectionTitle>Escrow</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field
                    name='escrowPercent'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Escrow Percentage'
                          labelDescription='Escrow percentage (e.g 5 NOT 0.05)'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='minimumEscrowFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Minimum'
                          labelDescription='Min Escrow fee'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field
                    name='maximumEscrowFee'
                    type='text'
                    validate={composeValidators(required, mustBeNumber)}
                  >
                    {(fieldProps) => {
                      return (
                        <TextField
                          label='Maximum'
                          labelDescription='Max Escrow fee'
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </EscrowFormSection>
            <OthersFormSection>
              <FormSectionTitle>Others</FormSectionTitle>
              <Divider />
              <GridContainer>
                <GridItem>
                  <Field
                    name='partPayment'
                    type='select'
                    validate={composeValidators(required)}
                  >
                    {(fieldProps) => {
                      return (
                        <SelectField
                          label='Part Payment'
                          items={YES_NO_SELECT_OPTIONS}
                          {...fieldProps}
                        />
                      );
                    }}
                  </Field>
                </GridItem>
                {values.partPayment === YES_NO_SELECT_OPTIONS[1].id && (
                  <GridItem>
                    <Field
                      name='partPayoutPecentage'
                      type='text'
                      validate={composeValidators(required, mustBeNumber)}
                    >
                      {(fieldProps) => {
                        return (
                          <TextField
                            label='% Part Payout'
                            labelDescription='% Part Payout (Eg 5 Not 0.05)'
                            {...fieldProps}
                          />
                        );
                      }}
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field name='sellerPhone' type='text'>
                    {(fieldProps) => {
                      return <TextField label='Seller Phone' {...fieldProps} />;
                    }}
                  </Field>
                </GridItem>
              </GridContainer>
            </OthersFormSection>
            {errors.length > 0 && (
              <ErrorAlertSection>
                <ul>
                  {errors.map((error, index) => {
                    if (!error) return null;
                    return <li key={index}>{error}</li>;
                  })}
                </ul>
              </ErrorAlertSection>
            )}
            <ButtonSection>
              <Button bgColor='#007BFF' type='submit' loading={submitting}>
                Submit
              </Button>
            </ButtonSection>
          </FormContainer>
        );
      }}
    </Form>
  );
};
TransactionCalculatorSimulatorInputWizard.propTypes = {
  handleSetOutputResult: PropTypes.func.isRequired,
};
export default TransactionCalculatorSimulatorInputWizard;
