import { BASE_URL, LOCALSTORAGE_TOKEN_KEY} from '../constants';
import axios from 'axios';
import { alertActions } from '../actions';



 const createSupportMail=(support, type)=>{
    
        let formData = new FormData();        
        formData.append('subject', support.subject);
        formData.append('email', support.email);
        formData.append('description', support.description);
        if(support.attachment){
            formData.append('attachment', support.attachment, support.attachment, support.attachment.name);
        }
        return axios.post(BASE_URL + type + "/support/mail", formData, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)
            }
        })
       
}

//SUPPORT VERIFICATION SERVICE
const verifyBusiness = (token, answer) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL}seller/support/${token}/${answer}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}


// function getUser(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }

// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
// }

// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
// }
export {
    createSupportMail,
    verifyBusiness
};


