import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";
import transaction from "../../helpers/transaction";
import Modal from 'react-responsive-modal';
import transactionHelper from "../../helpers/transaction";
import {ClipLoader} from "react-spinners";

export const toLocaleDisplay = (quantity , curency= 'NGN') => {
    return quantity.toLocaleString(undefined, { style: 'currency', currency: curency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2})
}

const formatToSixDigits = (number) => {
    var output = number + '';
    while (output.length < 6) {
        output = '0' + output;
    }
    return output;
}
const locale = d => d.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

class OrderDetails extends Component {
    state = {
        data: [],
        // dataOriginal: [],

        notCustom: true,

        date_to: null,
        date_from: null,

        page: 0,
        pagesCount: 1,
        pageSize: 5,
        search: '',
        oldSearch: '',
    }

    componentDidMount() {

        this.loadReport();
    }


    loadReport = (page = 0, pageSize = 5, sorted, filtered) => {

        let url = `${BASE_URL}admin/reports/order-details?page=${page + 1}&per_page=${pageSize}`;

        if (this.state.date_from) {
            url = url + `&date_from=${this.state.date_from.format("YYYY-MM-DD")}`
        }
        if (this.state.date_to) {
            url = url + `&date_to=${this.state.date_to.format("YYYY-MM-DD")}`
        }

        if (sorted && sorted.length > 0) {
            const id = sorted[0].id
            const sorting = sorted[0].desc ? 'desc' : 'asc';

            url = url + `&orderby=${id}&sort=${sorting}`
            console.log(page, 'sorted')
        }

        if (this.state.search !== '' && this.state.search !== null) {
            url = url + `&search=${this.state.search}`
        }

        this.setState({loading: true})

        axios({
            method: 'get',
            mode: 'no-cors',
            url,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(({data: {data}}) => {

                this.setState({
                    // dataOriginal,
                    data: data.data.map(d => ({
                        ...d,
                        product_amount: parseFloat(d.product_amount),
                        shipping_amount: parseFloat(d.shipping_amount),
                        additional_service_amount: d.addtional_service_amount || 0,
                        quantity: d.quantity || 0,
                    })),
                    page,
                    pageSize,
                    pagesCount: parseInt(data.last_page),
                    oldSearch: this.state.search,
                })
            })
            .catch(err => {
                console.log(err.response);
            })
            .finally(() => this.setState({loading: false}))

    };

    getOrder = (e, orderId) => {
        e.preventDefault();

        axios({
            method: 'get',
            mode: 'no-cors',
            url: `${BASE_URL}admin/orders/${orderId}`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem("user"),
                'Content-Type': 'application/json',
            }
        })
            .then(({data}) => {
                this.setState({
                    order: data.data,
                    showOrder: true,
                })
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    handleSelect = (e) => {
        const {value} = e.target;
        if (value === "Custom") {
            this.setState({notCustom: false})
        } else {
            this.setState({notCustom: true})
        }

        this.filterPeriod(value);
        // const period = {period: value}
        this.setState({period: value});
        /* console.log(value); */
        /* console.log(this.state.period); */
        /* console.log(this.state.data); */
    }

    filterPeriod = (value) => {
        const today = moment();
        let date_from = null;
        let date_to = null;

        if (value === "This Week") {
            date_from = today.startOf('week');
        }

        if (value === "This Month") {
            date_from = today.startOf('month');
        }

        if (value === "60 Days Ago") {

            date_from = today.subtract({months: 2,});
        }

        if (value === "90 Days Ago") {
            date_from = today.subtract({months: 3,});
        }

        if (value !== "Custom") {
            this.setState({date_from, date_to}, () => {
                this.loadReport();
            })
        }
    }

    filterDates = (data) => {
        var ordersBetweenTheTwoDates = [];

        for (var i = 0; i < data.length; i++) {

            var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
            /* console.log(isBetweenDates); */
            if (isBetweenDates) {
                ordersBetweenTheTwoDates.push(data[i]);
            }
        }

        this.table_rows = ordersBetweenTheTwoDates;
        //this.notCustom = true;
        this.page = 1;
        //this.to_date = null;
        //this.from_date = null;
        /* console.log(this.table_rows); */
        return this.table_rows;
    }
    // componentDidMount= ()=>{
    //     'i got called';
    //     this.props.view("seller",this.props.history);
    // }
    handleDate = (e) => {
        const {name, value} = e.target;
        const v = moment(value)
        this.setState({[name]: v});
    }


    calculateCardFee = (data, role) => {
        let cost = (parseFloat(data.product_cost / 100) * data.quantity) + (parseFloat(transactionHelper.calculateShipping(data, 1, 1)[role]) / 100);
        let fee = ((1.5 / 100) * cost);

        if (cost >= 2500)
            fee = fee + 100;
        if (fee >= 2000)
            fee = 2000;
        return fee + 50;
    }

    render() {

        const columns = [
            {
                Header: props => <span><b>Order ID</b></span>,
                id: "id",
                filterable: false,
                accessor: d => d.total,
                Cell: props => {
                    return <span><a href={"#"}
                                    onClick={e => this.getOrder(e, props.original.id)}>{formatToSixDigits(props.original.id)}</a></span>;
                }
            },
            {
                Header: props => <span><b>Product Amount</b></span>,
                id: "product_amount",
                filterable: false,
                accessor: d => d.product_amount,
                Cell: p => {

                    return <span>₦ {p.original.product_amount ? p.original.product_amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</span>;
                },
            },
            {
                Header: props => <span><b>Shipping Amount</b></span>,
                id: "shipping_amount",
                filterable: false,
                accessor: d => d.shipping_amount,
                Cell: p => {

                    return <span>₦{p.original.shipping_amount || '0.00'}</span>;
                },
            },
            {
                Header: props => <span><b>Escrow Fee Bearer</b></span>,
                id: "escrow_fee_bearer",
                filterable: false,
                accessor: d => d.escrow_fee_bearer,
                Cell: p => {

                    const x = p.original.escrow_fee_bearer;
                    return <span>{x == 'seller' ? 'Seller' : x == 'customer' ? 'Buyer' : 'N/A'}</span>;
                },
            },
            {
                Header: props => <span><b>Card Fee Bearer</b></span>,
                id: "card_fee_bearer",
                filterable: false,
                accessor: d => d.card_fee_bearer,
                Cell: p => {

                    const x = p.original.card_fee_bearer;
                    // console.log(x);
                    return <span>{x == 'Seller' ? 'Seller' : x == 'Customer' ? 'Buyer' : 'N/A'}</span>;
                },
            },

            {
                Header: props => <span><b>Order Status</b></span>,
                id: "status_title",
                filterable: false,
                accessor: d => d.sellers ? d.sellers.marketplaceuser ? (d.sellers.marketplaceuser.phone_number || 'N/A') : 'N/A' : 'N/A',
                Cell: d => {

                    const item = d.original;

                    return (
                        item.status_id === 1 || item.status_id === 11 || item.status_id === 7 || item.status_id === 3 ?
                            <span className="badge badge-warning"> {item.status_title}</span>
                            : item.status_id === 9 || item.status_id === 4 || item.status_id === 10 ?
                            <span className="badge badge-danger"> {item.status_title}</span>
                            : item.status_id === 6 || item.status_id === 8 ?
                                <span className="badge badge-info"> {item.status_title}</span>
                                : item.status_id === 2 || item.status_id === 5 ?
                                    <span className="badge badge-success"> {item.status_title}</span>
                                    :
                                    <span className="badge badge-warning">{item.status_title}</span>

                    );
                },
            },
            {
                Header: props => <span><b>Card Fee</b></span>,
                id: "card_fee",
                filterable: false,
                sortable: false,
                Cell: p => {
                    // console.log(x);
                    const t = p.original;
                    return <span>{t && '₦' + this.calculateCardFee(t, 'customer').toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    // return <span>₦{transaction.calculateCardFee(p.original, (p.original.card_fee_bearer.toLowerCase() == 'buyer' ? 'customer' : 'seller'))}</span>;
                },
            },
            {
                Header: props => <span><b>Escrow Fee</b></span>,
                id: "escrow_fee",
                filterable: false,
                sortable: false,
                Cell: p => {
                    // console.log(x);

                    const t = p.original;

                    return <span>
                        {t && '₦ ' + (transactionHelper.calculateTransactionEscrow(
                           (t.amount_net + (t.shipping_cost * (t && t.shipping_fee_bearer === "both" ? 0.5 :
                              t && t.shipping_fee_bearer === "seller" ? 0 : 1))) / 100) * (t && t.escrow_fee_bearer === "both" ? 0.5 : 1)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ''}
                    </span>

                    // return <span>₦{p.original.escrow_fee}</span>;
                },
            },
            {
                Header: props => <span><b>Service Type</b></span>,
                id: "service_type",
                filterable: false,
                accessor: d => d.service_type,
                Cell: p => {
                    // console.log(x);
                    return <span>{p.original.service_type}</span>;
                },
            },
            {
                Header: props => <span><b>Payment Method</b></span>,
                id: "payment_method",
                filterable: false,
                accessor: d => d.payment_method,
                Cell: p => {
                    // console.log(x);
                    return <span>{p.original.payment_method}</span>;
                },
            },
            {
                Header: props => <span><b>Payment Status</b></span>,
                id: "payment_status",
                filterable: false,
                accessor: d => d.payment_status,
                Cell: p => {
                    // console.log(x);
                    return <span>{p.original.payment_status}</span>;
                },
            },
        ];

        const {loading} = this.state;
        return (
            <div className={'content-wrapper'}>
                <Modal open={this.state.showOrder} onClose={() => this.setState({showOrder: false})}>
                    {
                        this.state.order ?
                            <form>
                                <div className="modal-header">
                                    <h4 className="modal-title"><small></small></h4>
                                </div>

                                <div className="modal-body">
                                    <div className="row"></div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Order Number</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={formatToSixDigits(this.state.order && this.state.order.id)}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Amount</label><br/>
                                                <input type="text" readOnly="readonly"
                                                       value={this.state.order && toLocaleDisplay(parseFloat(this.state.order.amount_payed / 100))}
                                                       className="form-control"/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Order Date</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.created_at}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Order Status</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.status.title}
                                                className="form-control"/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Buyer Name</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Seller Name</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.user && this.state.order.user.firstname + " " + this.state.order.user.lastname}
                                                className="form-control"/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Delivery Location</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.delivery_location || "N/A"}
                                                className="form-control"/></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group"><label>Delivery Date</label> <br/> <input
                                                type="text" readOnly="readonly"
                                                value={this.state.order && this.state.order.delivery_date || "N/A"}
                                                className="form-control"/></div>
                                        </div>
                                    </div>
                                    <br/><br/>
                                    {this.state.order.extra ?
                                        <div className="row">

                                            {this.state.order && Object.keys(this.state.order.extra).map(extra => (
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>{extra.charAt(0).toUpperCase() + extra.slice(1).replace(/_/g, " ")}</label>
                                                        <br/> <input type="text" readOnly="readonly"
                                                                     value={this.state.order.extra[extra]}
                                                                     className="form-control"/></div>
                                                </div>
                                            ))}
                                        </div> : null}
                                    <br/><br/>
                                </div>
                            </form> :
                            <ClipLoader
                                loading={true}
                            />
                    }
                </Modal>

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">

                            <div className="col-sm-12">
                                <h1 className="m-0 text-dark">Order Details Report</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="alert animated bounceIn alert-danger d-none search-alert">
                                    <a aria-label="close" className="close alert-close">×</a>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-sm-2">
                                                <div className="form-group"><label htmlFor="period">Period</label>
                                                    <select className="form-control" name="filter"
                                                            onChange={this.handleSelect}>
                                                        <option value="All">All</option>
                                                        <option value="This Week">This Week</option>
                                                        <option value="This Month">This Month</option>
                                                        <option value="60 Days Ago">60 Days Ago</option>
                                                        <option value="90 Days Ago">90 Days Ago</option>
                                                        <option value="Custom">Custom</option>
                                                    </select></div>
                                            </div>
                                            <div className="col-12 col-sm-2">
                                                <div className="form-group">
                                                    <label htmlFor="period">Search</label>
                                                    <input type="text" className={'form-control'} onChange={(e) => {
                                                        e.preventDefault()



                                                        if(this.timeout){
                                                            clearTimeout(this.timeout)
                                                        }

                                                        if(e.target.value !== this.state.oldSearch){
                                                            this.setState({
                                                                search: e.target.value,
                                                            }, () => {
                                                                this.timeout = setTimeout(this.loadReport, 750);
                                                            })
                                                        }

                                                    }}/>
                                                </div>

                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="from">Date From</label>
                                                    <input
                                                        type="date"
                                                        name="date_from"
                                                        value={this.state.date_from ? this.state.date_from.format('YYYY-MM-DD') : null}
                                                        disabled={this.state.notCustom}
                                                        onChange={this.handleDate}
                                                        className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="from">Date To</label>
                                                    <input
                                                        type="date"
                                                        name="date_to"
                                                        value={this.state.date_to ? this.state.date_to.format('YYYY-MM-DD') : null}
                                                        disabled={this.state.notCustom}
                                                        onChange={this.handleDate}
                                                        className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2">
                                                <div className="form-group"><label>&nbsp;</label>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <button disabled={this.state.notCustom} onClick={e => {
                                                                e.preventDefault();
                                                                this.loadReport();
                                                            }} className="btn btn-info btn-block form-control">
                                                                <center>Search</center>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="table-responsive">
                                            <ReactTable
                                                loading={loading}
                                                pages={this.state.pagesCount}
                                                page={this.state.page}
                                                pageSize={this.state.pageSize}
                                                defaultPageSize={this.state.pageSize}
                                                filterable
                                                data={this.state.data}
                                                columns={columns}
                                                manual
                                                onFetchData={(state, instance) => {
                                                    this.loadReport(state.page, state.pageSize, state.sorted, state.filtered)
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default OrderDetails;