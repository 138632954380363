import { BASE_URL } from '../constants';
import axios from 'axios';
import { userService } from '.';
import { userActions } from '../actions';

const get=(type)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/orders`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}
const getPaymentHistory = (type) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL + type}/payments  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}
const getDelivery=(type)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/deliverymen  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const assignDispatcher=(type,data)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/order/assign `,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}



// const reassignDispatcher=(type)=>{

// }

const getDisputes=(type)=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/order/disputes  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const raiseDispute=(type,data)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/order/dispute/raise `,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const cancelDispute=(type,data)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL+ type}/order/dispute/cancel `,
        data:data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const cancelOrder = (type, response) => {
    return axios({
        method: 'put',
        mode: 'no-cors',
        url: `${BASE_URL}${type}/order/cancel/${response.order_id}`,
        data: response,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const processPayment = (ref) => {
    return axios({
        method: 'get',
        mode: 'no-cors',
        url: `${BASE_URL}external/create/${ref} `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const processPod = (path,data) => {
    console.log(`${BASE_URL}order/pod/${path}`, '-----Path----');
    console.log(`${BASE_URL}order/pod/${path}`);
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}order/pod/${path} `,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

const completeOrder = (data) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}order/pod/complete `,
        data: data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
}

export const order = {
    get,
    getDelivery,
    getPaymentHistory,
    assignDispatcher,
    getDisputes,
    cancelDispute,
    cancelOrder,
    raiseDispute,
    processPayment,
    processPod,
    completeOrder
};


