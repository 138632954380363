import React, { forwardRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import COLORS from "../../theme/colors";
const Wrapper = styled.div`
  width: 20vw;
`;
const StyledLabel = styled.label`
  width: 100%;
  height: 2rem;
  color: ${(props) => (props?.hasError ? COLORS.errorColor : "")};
`;
const StyledSpan = styled.span`
  display: block;
  font-weight: 500;
  font-size: 0.8rem;
`;
const Label = forwardRef(({ children, description, ...props }, ref) => {
  return (
    <Wrapper ref={ref}>
      <StyledLabel {...props}>
        {children}
        {description && <StyledSpan>{description}</StyledSpan>}
      </StyledLabel>
    </Wrapper>
  );
});
Label.displayName = "Label";
Label.propTypes = {
  children: PropTypes.string,
  description: PropTypes.string,
};
export default Label;
