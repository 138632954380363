import * as yup from 'yup';

export const supportSchema = yup.object().shape({
    subject: yup
        .string()
        .required(),
    description: yup
        .string()
        .required(),
    role: yup.string().required("You must select a user"),
    email:yup.string().required(),
    attachment: yup.mixed()
});

export default supportSchema;