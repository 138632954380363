exports.userConstants = require("./user");
exports.alertConstants = require("./alert");
exports.termConstants = require("./terms");
exports.payoutConstants = require("./payout");
exports.supportConstants = require("./support");
exports.settingConstants = require("./settings");
exports.orderConstants = require("./order");
exports.disputeConstants = require("./dispute");
exports.deliveryConstants = require("./deliveryMan");
exports.transactionConstants = require("./transaction");

// staging
// exports.BASE_URL = "http://test-api.atarapay.com/api/";
// exports.BASE_URL_ADDRESS = "http://staging.atarapay.com/pod";

// LIVE
exports.BASE_URL = "https://api.3gisltd.com/api/";
exports.BASE_URL_ADDRESS = "https://app.3gisltd.com/pod";

// LOCAL
// exports.BASE_URL='http://localhost:8000/api/';

exports.LOCALSTORAGE_TOKEN_KEY = "user";
exports.LOCALSTORAGE_ROLE = "trust_user_type";
exports.USER_ROLE_SELLER = "Seller";
exports.USER_ROLE_MARKET = "MarketPlace";
