import React, { Component } from 'react';
import { userActions, alertActions } from '../actions';
import {Link} from 'react-router-dom';
import swal from '@sweetalert/with-react';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput, {formatPhoneNumber} from 'react-phone-number-input';
import BrowserDetection from 'react-browser-detection';
import Modal from 'react-responsive-modal';

import {Terms , Privacy }  from './ModalViews';


const initialState = {
    user:{
        firstname: "",
        lastname: "",
        phone_number: "",   
        email: "",
        password: "",
        password_confirmation: "",
        PIN: "",
        subrole: "",
        PIN_confirmation: "",
        ip_address: "127.0.0.1",
        device_type: "browser",
        device_version: "1.0",
    },
    phone: '',
    proceed: false,
    submitted: false
};

class Register extends Component {
    
    toggleModal = (name) => {
        this.setState (
            {[name]: !(this.state[name])}
        )
    }

    constructor(props) {
        super(props);
        const { email, phone, ref, getstarted } = props.match.params;

        if(!getstarted) {
            if (email && phone) {
                let data = {"phone": phone};
                if(ref)
                    data = { "phone": phone, "ref": ref };
                props.checkVerified(data, this.props.history);
            }
        }

    
        this.state = {
            user:{
                firstname: "",
                lastname: "",
                phone_number: "",
                email: "",
                password: "",
                password_confirmation: "",
                PIN: "" ,
                subrole: '',
                PIN_confirmation: "" ,
                ip_address: "127.0.0.1",
                device_type: 'Browser',
                device_version: "0.1",
            },
            captured:false,
            submitted: false,
            termModal: false,
            privacyModal:false,
            type:this.props.match.params.type
        };

       
    
    }

    browserHandler = {
        default: (browser) => {
            this.setState({
                user: {...this.state.user, device_type: browser}
            });
            return null;
        }
    }
    
        handleChange=(e)=>{
    
            const { name, value } = e.target;
            const { user } = this.state;
            this.setState({
                user: {
                    ...user,
                    [name]: value
                }
            })
        }
        handlePhone=(value, name)=>{
            this.setState({
                user: {
                    ...this.state.user,
                    [name]: value
                }
            })
        }

        handleTabClick=(name)=>{
            const { dispatch } = this.props;
            this.setState(initialState);
            dispatch(alertActions.alert.clear());
            // this.props.history.push(`/register/${name}`);
            this.setState({type:name});
        }

    onOpenModal = (name) => {
        this.setState({ [name]: true });

    };

    onCloseModal = (name) => {
        this.setState({ [name]: false });
    };


        componentWillReceiveProps=(props)=>{

            

            if(props.alert && props.alert.message && props.alert.type === "alert-danger"){
                swal("Error",props.alert.message,"error").then(() => {
                    this.props.dispatch(alertActions.alert.clear());
                  });
            }

            if(props.alert && props.alert.message&&props.alert.type === "alert-success"){
                if (props.alert.message === "customer_updated" || props.alert.message === "customer updated"){
                    swal({ title: "Success", content: <div>Thank you for completing your registration, you can now login to access your account</div>, icon: "success" }).then(() => {
                        this.props.dispatch(alertActions.alert.clear());
                    });
                }else{
                    swal({ title: "Success", content: <div>A verification email has been sent to <b>{props.alert.message}</b>. Please access the email to complete your registration</div>, icon: "success" }).then(() => {
                        this.props.dispatch(alertActions.alert.clear());
                    });
                }
                
                  
                this.setState({user:initialState});
            }
       }

    
       handleSubmit=(e)=> {
        e.preventDefault();
   
        const { user } = this.state;
        let data = { ...user, phone_number: user.phone_number };
        let type = null;
        if (this.props.match.params.email && this.props.match.params.phone && this.props.match.params.ref)
            if (this.props.match.params.ref === "p2p-seller" || this.props.match.params.ref === "p2p-buyer")
                data = { ...user, phone_number: this.props.match.params.phone, email: this.props.match.params.email};
            else
                data = { ...user, phone_number: this.props.match.params.phone, email: this.props.match.params.email, returning: 1, otp: this.props.match.params.ref };
        else if(this.props.match.params.email && this.props.match.params.phone && this.props.match.params.getstarted === "getstarted" )
            data = { ...user, phone_number: this.props.match.params.phone, email: this.props.match.params.email, subrole: this.props.match.params.subrole };
        
        else if(this.props.match.params.email && this.props.match.params.phone)
            data = { ...user, phone_number: this.props.match.params.phone, email: this.props.match.params.email, returning: 1 };

        this.setState({ submitted: true,captured:true });

        type= e.target.dataset.type;

        if(!type){
            type = 'seller';
        }
      
        const { dispatch } = this.props;
        if (user.firstname && user.lastname) {
            dispatch(userActions.user.register(data,type,this.props.history));
        }
        this.onCloseModal("proceed")
    }
        
render () {
    const { registering ,alert } = this.props;
    const { user, submitted} = this.state;
    const {type, email, phone, ref, subrole, getstarted} = this.props.match.params;
return (
    
    <div className="background-gradient-signup" style={{ minHeight: '100vh'}}>
            <nav class="navbar navbar-expand-lg navbar-inverse">
                <a className="navbar-brand" href="https://www.atarapay.com">
                    <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive" />
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>
    <Terms open={this.state.termModal} onClose={()=>{this.toggleModal("termModal")}}/>
    <Privacy open={this.state.privacyModal} onClose={()=>{this.toggleModal("privacyModal")}}/>
        <BrowserDetection>
            {this.browserHandler}
        </BrowserDetection>
    <div class="row row-margin top-margin-60">
        <div class="col-sm-3 col-md-3"></div>
        <div class="col-sm-8 col-md-6 col-12">
            <div class="card" style={{marginTop: "30px"}}>
                    {/*
                <img src="/dist/img/logo-white.png" alt="" className="logo-img img img-responsive" />
                 <ul role="tablist" class="nav nav-tabs nav-justified ">
                    <li class="nav-item" ><a href="#buyer"  onClick={()=>{this.handleTabClick("buyer")}} role="tab" data-toggle="tab" class={`nav-link ${type&&type?(type&&type==`buyer`?`active`:``):`active`}`}>Buyer</a></li>
                    <li class="nav-item"><a href="#seller"  onClick={()=>{this.handleTabClick("seller")}} role="tab" data-toggle="tab" class={`nav-link ${type&&type?(type&&type==`seller`?`active`:``):``}`}>Seller</a></li>
                    <li class="nav-item"><a href="#marketplace"  onClick={()=>{this.handleTabClick("market")}} role="tab" data-toggle="tab" class={`nav-link ${type&&type?(type&&type==`market`?`active`:`disabled`):``}`}>Marketplace
                            Admin</a></li>
                </ul><br /> */}
                <div class="tab-content">
                        <div role="tabpanel" id="buyer" class={`tab-pane ${type && type === `buyer` ? `active` : (type && type === `returning` && ref !== `p2p-seller`) ? `active`: ref && ref === `p2p-buyer` ? `active`:``}`}><br/>
                    <form data-type="customer" onSubmit={this.handleSubmit}>
                        <h4><strong>Sign up to
                                    AtaraPay (Buyer)</strong></h4>
                                <p ><span class="text-danger">*</span>Indicates mandatory fields</p>
                              
                        <div class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="name"><small
                                        >Please Enter Your First Name<span class="text-danger">*</span></small></label> <input
                                          type="text" required="required" name="firstname" value={user.firstName} onChange={this.handleChange} minLength="3" placeholder="First Name"
                                        class="form-control"/></div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" ><small
                                        >Please Enter Your Last Name<span class="text-danger">*</span></small></label> <input
                                          type="text" required="required" name="lastname" value={user.lastname} onChange={this.handleChange}
                                          minLength="3" placeholder="Last Name"
                                        class="form-control"/></div>
                            </div>
                        </div>
                        <div   class="row">
                            <div   class="col-sm-6">
                                        <div class="form-group"><label className="small" for="email"><small>This Email Address will be Username at login<span class="text-danger">*</span></small></label> 
                                { email ?
                                <input
                                type="email" required="required" name="email" defaultValue={email} disabled={getstarted ? false : true } placeholder="yourname@domain.com" class="form-control"/>
                                : <input type="email" required="required" name="email" 
                                value={user.email} 
                                onChange={this.handleChange} 
                                placeholder="yourname@domain.com" class="form-control" />
                                }
                                </div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="phone">
                                            <small>This Phone Number will be Username at login<span class="text-danger">*</span></small></label> 
                                            {phone ?
                                    <PhoneInput country="NG" placeholder="08XX XXX XXXX"
                                        limitMaxLength={true}
                                        value={phone}
                                        onChange={value => this.handlePhone(value && formatPhoneNumber(value, 'International').replace(/ /g, "") || '', 'phone_number')}
                                        disabled={getstarted ? false : true } />
                                        
                                        :
                                    <PhoneInput country="NG" placeholder="08XX XXX XXXX"
                                        limitMaxLength={true}
                                                    value={phone && formatPhoneNumber(phone && phone, 'International')}
                                                    onChange={value => this.handlePhone(value && formatPhoneNumber(value, 'International').replace(/ /g, "") || '', 'phone_number')} />
                                    }
                                   
                            </div>
                            </div>
                        </div>
                        <div   class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="password"><small
                                        >Please Select A Password<span class="text-danger">*</span></small></label> <input
                                          type="password" required="required"  minLength="6" name="password" value={user.password} onChange={this.handleChange} placeholder="**********"
                                        class="form-control"/>
                                </div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="password_confirmation"><small
                                        >Please Confirm Your Password<span class="text-danger">*</span></small></label> <input
                                          type="password" required="required" minLength="6" name="password_confirmation" value={user.password_confirmation} onChange={this.handleChange}  placeholder="************"
                                        class="form-control"/></div>
                            </div>
                        </div>
                       
                        <center><div   class="g-recaptcha-div">
                            <div>
                            <ReCAPTCHA
                                            sitekey="6LcK-ZsUAAAAAE5QMsoKYJHF8ZGtl9uulCaP-DQT"
                           onChange={()=>{this.setState({captured:true})}}
                           />
                        </div> 
                        </div>
                        </center>
                        <br />
                        <input type="submit" name="button" disabled={!this.state.captured} class="btn btn-dark-blue form-control btn-customer-register"
                            value={registering ? "registering..." :"Sign up" }/> <br/><br/>
                       <p   class="text-center"><small  >By registering you agree to
                                AtaraPay's <a   onClick={()=>{this.toggleModal("termModal")}} style={{cursor: 'pointer'}} class="text-primary">Terms of Use</a> and <a onClick={()=>{this.toggleModal("privacyModal")}} style={{cursor: 'pointer'}} class="text-primary" 
                                 >Privacy Policy</a> .</small></p>
                       
                                </form>
                                <hr/>
                                <p   class="text-center">Register as <Link to="/register/seller" class="">Seller</Link> </p>
                        <p   class="text-center">Already have an account? Log in as <Link to="/login/buyer" class="">Buyer</Link> or <Link to="/login/seller" class="">Seller</Link> </p>
                    </div>
                        <div role="tabpanel" id="seller" class={`tab-pane ${type && type ? (type && type === `seller` ? `active` : ref && ref === `p2p-seller` ? `active`:``) : ``}`}><br/>
                            <form data-type="seller" onSubmit={this.handleSubmit}>
                        <h4><strong>Sign up to
                                    AtaraPay (Seller)</strong></h4>
                                    {/* <p  class="text-danger">
                                    Your <b>first name</b>, <b>last name</b> and <b>phone number</b> should be the same as it appears on your Bank Verification Number (BVN) account. 
                                    This is to enable flawless verification of your identity at no cost to you. If you enter wrong details and the verification fails, you will be charged a fee of N100 to reverify your account.
                                </p> */}
                                
                        <div class="row">
                            <div   class="col-sm-6">
                            <p ><span class="text-danger">*</span> Indicates mandatory fields</p>
                            </div>
                            <div   class="col-sm-6">
                            <a href=" https://www.youtube.com/watch?v=3h7RNC5VglM."  target="_blank">Watch: How To Register as a Seller</a>
                            </div>
                        </div>
                        <div class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="name"><small
                                        >Please Enter Your First Name<span class="text-danger">*</span></small></label> <input
                                          type="text" required="required" name="firstname" value={user.firstName} onChange={this.handleChange} minLength="3" placeholder="First Name"
                                        class="form-control"/></div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" ><small
                                        >Please Enter Your Last Name<span class="text-danger">*</span></small></label> <input
                                          type="text" required="required" name="lastname" value={user.lastname} onChange={this.handleChange}
                                          minLength="3" placeholder="Last Name"
                                        class="form-control"/></div>
                            </div>
                        </div>
                        <div   class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="email"><small
                                        >This Email Address will be Username at login<span class="text-danger">*</span></small></label> 
                                        { email ?
                                        <input type="email" required="required" name="email" defaultValue={email} onChange={this.handleChange} disabled={getstarted ? false : true } placeholder="yourname@domain.com" class="form-control"/>
                                        : 
                                        <input type="email" required="required" name="email" value={user.email} onChange={this.handleChange} placeholder="yourname@domain.com" class="form-control"/> }</div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="phone">
                                            <small>Please Enter Your Phone Number<span class="text-danger">*</span></small></label> 
                               {phone ?
                                    <PhoneInput country="NG" placeholder="08XX XXX XXXX"
                                        limitMaxLength={true}
                                        value={phone}
                                        onChange={value => this.handlePhone(value && formatPhoneNumber(value, 'International').replace(/ /g, "") || '', 'phone_number')}
                                        disabled={getstarted ? false : true } />
                                    :
                                    <PhoneInput country="NG" placeholder="08XX XXX XXXX"
                                        limitMaxLength={true}
                                                    value={phone && formatPhoneNumber(phone && phone, 'International')}
                                                    onChange={value => this.handlePhone(value && formatPhoneNumber(value, 'International').replace(/ /g, "") || '', 'phone_number')} />
                                }
                           
                                    </div>
                            </div>
                        </div>
                        <div   class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="password"><small
                                        >Please Select A Password<span class="text-danger">*</span></small></label> <input
                                          type="password" required="required"  minLength="6" name="password" value={user.password} onChange={this.handleChange} placeholder="**********"
                                        class="form-control"/>
                                </div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="password_confirmation"><small
                                        >Please Confirm Your Password<span class="text-danger">*</span></small></label> <input
                                          type="password" required="required" minLength="6" name="password_confirmation" value={user.password_confirmation} onChange={this.handleChange}  placeholder="************"
                                        class="form-control"/></div>
                            </div>
                        </div>
                        <div class="row">
                            <div   class="col-sm-12">
                                <div   class="form-group"><label className="small" for="password"><small
                                        >Please Select a role<span class="text-danger">*</span></small></label> 
                                        { subrole ?
                                        <select required="required"  name="subrole" value={subrole} onChange={this.handleChange} class="form-control">
                                            <option value="" disabled selected> Select a role</option>
                                            <option value="individual" >Individual Seller </option>
                                            <option value="business" >Business Seller </option>
                                            <option value="marketplace" >Marketplace Operator </option>
                                        </select>
                                        :
                                        <select required="required"  name="subrole" value={user.subrole} onChange={this.handleChange} class="form-control">
                                            <option value="" disabled selected> Select a role</option>
                                            <option value="individual" >Individual Seller </option>
                                            <option value="business" >Business Seller </option>
                                            <option value="marketplace" >Marketplace Operator </option>
                                        </select>
                                        }
                                </div>
                                <p class="text-danger small text-center">Business Sellers and Marketplace Operator shall require documentation to verify the business</p>
                            </div>
                        </div>
                        <center><div   class="g-recaptcha-div">
                            <div>
                            <ReCAPTCHA
                            sitekey="6LcK-ZsUAAAAAE5QMsoKYJHF8ZGtl9uulCaP-DQT"
                           onChange={()=>{this.setState({captured:true})}}
                           />
                        </div> 
                        </div></center>
                        <br />
                        <input type="submit" name="button" disabled={!this.state.captured} class="btn btn-dark-blue form-control btn-customer-register"
                            value={registering ? "registering..." :"Sign up" }/> <br/><br/>
                        <p class="text-center"><small>By registering you agree to
                                AtaraPay's <a onClick={()=>{this.toggleModal("termModal")}} style={{cursor: 'pointer'}} class="text-primary">Terms of Use</a> and <a onClick={()=>{this.toggleModal("privacyModal")}} style={{cursor: 'pointer'}} class="text-primary" 
                                 >Privacy Policy</a> .</small></p>
                          <hr/>
                                <p   class="text-center">Register as <Link to="/register/buyer" class="">Buyer</Link> </p>
                        <p   class="text-center">Already have an account? Log in as <Link to="/login/buyer" class="">Buyer</Link> or <Link to="/login/seller" class="">Seller</Link> </p>
                                </form>
                    </div>
                    <div role="tabpanel" id="marketplace" class={`tab-pane ${type&&type?(type&&type===`market`?`active`:``):``}`}><br/>
                        <h4><strong>Sign up to
                                    AtaraPay (Marketplace Admin)</strong></h4>
                            <p ><span class="text-danger">*</span> Indicates mandatory fields</p>
                        <div class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="name"><small
                                    >Please Enter Your First Name<span class="text-danger">*</span></small></label> <input
                                          type="text" required="required" minLength="3" placeholder="First Name"
                                        class="form-control"/></div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" ><small
                                    >Please Enter Your Last Name<span class="text-danger">*</span></small></label> <input
                                          type="text" required="required" minLength="3" placeholder="Last Name"
                                        class="form-control"/></div>
                            </div>
                        </div>
                        <div   class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="email"><small
                                    >This Email Address will be Username at login<span class="text-danger">*</span></small></label> <input
                                          type="email" required="required" placeholder="Please Enter Your Email Address" class="form-control"/></div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="phone"><small
                                    >Please Enter Your Phone Number<span class="text-danger">*</span></small></label>
                                           <PhoneInput country="NG" placeholder="08XX XXX XXXX"
                                                limitMaxLength={true}
                                            value={phone && formatPhoneNumber(phone && phone, 'International')}
                                            onChange={value => this.handlePhone(value && formatPhoneNumber(value, 'International').replace(/ /g, "") || '', 'phone_number')}/>
                                             {/* <input
                                          type="text" required="required" placeholder="08XX XXX XXXX"
                                        maxLength="11" class="form-control"/> */} </div>
                            </div>
                        </div>
                        <div   class="row">
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="password"><small
                                    >Please Select A Password<span class="text-danger">*</span></small></label> <input
                                          type="password" required="required" placeholder="**********"
                                        class="form-control"/>
                                </div>
                            </div>
                            <div   class="col-sm-6">
                                <div   class="form-group"><label className="small" for="password_confirmation"><small
                                    >Please Confirm Your Password<span class="text-danger">*</span></small></label> <input
                                          type="password" required="required" placeholder="************"
                                        class="form-control"/></div>
                            </div>
                        </div>
                        
                        <center>
                        <div   class="g-recaptcha-div">
                            <div>
                            <ReCAPTCHA
                                            sitekey="6LcK-ZsUAAAAAE5QMsoKYJHF8ZGtl9uulCaP-DQT"
                           onChange={()=>{this.setState({captured:true})}}
                           />
                        </div> 
                        </div>
                        </center>
                        <br />
                        <input type="submit" name="button" disabled="disabled" class="btn btn-dark-blue form-control btn-customer-register"
                            value="Sign up" /> <br/><br/>
                        <p   class="text-center"><small  >By registering you agree to
                                AtaraPay's <a   onClick={()=>{this.toggleModal("termModal")}} style={{cursor: 'pointer'}} class="text-primary">Terms of Use</a> and <a onClick={()=>{this.toggleModal("privacyModal")}} style={{cursor: 'pointer'}} class="text-primary" 
                                 >Privacy Policy</a> .</small></p>
                        <hr/>
                        <p   class="text-center"><Link to="/login" class="">Already
                                have an account? Log in</Link></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3 col-md-3"></div>
    </div>
        {/* <Modal open={this.state.proceed} onClose={() => this.onCloseModal("proceed")} center>
            <div class="modal-header">
                 {alert && alert.message && <div class={`alert buyer-alert ${alert.type}`} ><a  
                            aria-label="close" class="close alert-close">×</a> <span   class="alert-message-content">{alert.message}</span></div>} 
                <h4 class="modal-title">Are your BVN details correct?</h4>
            </div>
            <div class="modal-body">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p >Your <b>first name</b>, <b>last name</b> and <b>phone number</b> should be the same as it appears on your Bank Verification Number (BVN) account. This is to enable flawless verification of your identity at no cost to you. If you enter wrong details and the verification fails, you will be charged a fee of &#8358;100 to reverify your account.<br /><br />Do you still wish to proceed?</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer"><button onClick={() => this.onCloseModal("proceed")} type="button" data-dismiss="modal"
                class="btn btn-primary">Review Details</button>
                &nbsp;&nbsp;
    <button onClick={this.handleSubmit} disabled={registering} type="button" class="btn btn-danger">{registering ? 'Registering...' : 'Yes'}</button></div>

        </Modal> */}
</div>

)
}
}

export default Register;